import React, { ReactElement } from 'react';
// STYLES
import styles from './styles.module.scss';

type Data = {
    content: Element | ReactElement<any, any> | string,
    width?: string
}

const Tooltip = ({ content, width }: Data) => {
    return (
        <div className={styles.toolTip} style={{ maxWidth: width }}>
            <div className={styles.tooltipText}>
                {content}
            </div>
        </div>
    );
};

export default React.memo(Tooltip);
