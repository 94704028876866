import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import SchemaIntroTabs from '../../components/schemas/SchemaIntroTabs';
import Schema8View1 from '../../components/schemas/schema8/Schema8View1';
import Schema8View2 from '../../components/schemas/schema8/Schema8View2';
import Schema8View3 from '../../components/schemas/schema8/Schema8View3';
import Schema8View4 from '../../components/schemas/schema8/Schema8View4';
import Schema8View5 from '../../components/schemas/schema8/Schema8View5';
import Schema8View6 from '../../components/schemas/schema8/Schema8View6';
import Schema8View7 from '../../components/schemas/schema8/Schema8View7';
import Schema8View8 from '../../components/schemas/schema8/Schema8View8';
import Schema8View9 from '../../components/schemas/schema8/Schema8View9';
import Schema8View10 from '../../components/schemas/schema8/Schema8View10';
import Schema8View11 from '../../components/schemas/schema8/Schema8View11';
import Schema8View12 from '../../components/schemas/schema8/Schema8View12';
import Schema8View13 from '../../components/schemas/schema8/Schema8View13';
import Schema8View14 from '../../components/schemas/schema8/Schema8View14';
import ArrowNavigation from '../../components/ArrowNavigation';
// IMAGES
import example5 from '../../components/schemas/schema8/images/example5.png';
import example6 from '../../components/schemas/schema8/images/example6.png';
import example7 from '../../components/schemas/schema8/images/example7.png';
import example8 from '../../components/schemas/schema8/images/example8.png';
import Arrows from '../../components/schemas/schema4/Arrows';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema8Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema8.introTitle1' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle1' })}
                            textBlockFull={<div>
                                <p>
                                    {intl.formatMessage({ id: 'schema8.intro1TextBlock1' })}
                                    <ul>
                                        <li>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock2' })}
                                            <b>{intl.formatMessage({ id: 'schema8.intro1TextBlock3' })}</b>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock4' })}
                                        </li>
                                        <li>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock5' })}
                                            <b>{intl.formatMessage({ id: 'schema8.intro1TextBlock6' })}</b>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock7' })}
                                        </li>
                                        <li>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock8' })}
                                            <b>{intl.formatMessage({ id: 'schema8.intro1TextBlock9' })}</b>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock10' })}
                                            <b>{intl.formatMessage({ id: 'schema8.intro1TextBlock11' })}</b>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock12' })}
                                        </li>
                                        <li>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock13' })}
                                            <b>{intl.formatMessage({ id: 'schema8.intro1TextBlock14' })}</b>
                                        </li>
                                        <li>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock15' })}
                                            <b>{intl.formatMessage({ id: 'schema8.intro1TextBlock16' })}</b>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock17' })}
                                        </li>
                                        <li>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock18' })}
                                            <b>{intl.formatMessage({ id: 'schema8.intro1TextBlock19' })}</b>
                                        </li>
                                        <li>
                                            {intl.formatMessage({ id: 'schema8.intro1TextBlock20' })}
                                            <b>{intl.formatMessage({ id: 'schema8.intro1TextBlock21' })}</b>
                                        </li>
                                    </ul>
                                </p>
                            </div>}
                        />
                    }
                    {
                        tab === 1 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema8.introTitle2' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle2' })}
                            section1Heading={intl.formatMessage({ id: 'schema8.intro2Section1Heading' })}
                            textBlock1={<b>{intl.formatMessage({ id: 'schema8.intro2TextBlock1' })}</b>}
                            textBlock2={<ul>
                                <li><b>{intl.formatMessage({ id: 'schema8.intro2TextBlock2_1' })}</b></li>
                                <li><b>{intl.formatMessage({ id: 'schema8.intro2TextBlock2_2' })}</b></li>
                            </ul>}
                            footer={intl.formatMessage({ id: 'schema8.intro2Footer' })}
                            detailsMore={true}
                            links={['Link', 'Link', 'Link']}
                        />
                    }
                    {
                        tab === 2 && <Schema8View1/>
                    }
                    {
                        tab === 3 && <Schema8View2/>
                    }
                    {
                        tab === 4 && <Schema8View3/>
                    }
                    {
                        tab === 5 && <Schema8View4/>
                    }
                    {
                        tab === 6 && <Schema8View5/>
                    }
                    {
                        tab === 7 && <Schema8View6/>
                    }
                    {
                        tab === 8 && <Schema8View7/>
                    }
                    {
                        tab === 9 && <Schema8View8/>
                    }
                    {
                        tab === 10 && <Schema8View9/>
                    }
                    {
                        tab === 11 && <Schema8View10/>
                    }
                    {
                        tab === 12 && <Schema8View11/>
                    }
                    {
                        tab === 13 && <Schema8View12/>
                    }
                    {
                        tab === 14 && <Schema8View13/>
                    }
                    {
                        tab === 15 && <Schema8View14/>
                    }
                    {
                        tab === 16 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema8.introTitle3' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle3' })}
                            section1Heading={intl.formatMessage({ id: 'schema8.intro3Section1Heading' })}
                            textBlock1={<b>{intl.formatMessage({ id: 'schema8.intro3TextBlock1' })}</b>}
                            redNoteTitle={intl.formatMessage({ id: 'schema8.intro3RedNoteTitle' })}
                            redNoteText={intl.formatMessage({ id: 'schema8.intro3RedNoteText' })}
                            blueNoteTitle={intl.formatMessage({ id: 'schema8.intro3BlueNoteTitle' })}
                            blueNoteText={intl.formatMessage({ id: 'schema8.intro3BlueNoteText' })}
                            footer={intl.formatMessage({ id: 'schema8.intro3Footer' })}
                            detailsMore={true}
                            links={['Link', 'Link', 'Link']}
                        />
                    }
                    {
                        tab === 17 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema8.introTitle4' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle4' })}
                            textBlock1={<div>
                                <br/>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema8.intro4TextBlock1' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro4TextBlock2_1' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro4TextBlock2_2' })}</li>
                                </ul>
                            </div>}
                            textBlock2={<div>
                                <br/>
                                <div className={styles.cardsWrapper}>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block1Title' />
                                            </span>
                                        </div>
                                        <Arrows color='blue' additionalClass='arrowDownAnimation' small={true}/>
                                        <Arrows color='blue' additionalClass='arrowRightAnimation' small={true}/>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block2Title' />
                                            </span>
                                        </div>
                                        <Arrows color='blue' additionalClass='arrowDownAnimation' direction='right' small={true}/>
                                        <Arrows color='red' additionalClass='arrowLeftAnimation' small={true}/>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block3Title' />
                                            </span>
                                        </div>
                                        <Arrows color='blue' additionalClass='arrowRightAnimation' small={true}/>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block4Title' />
                                            </span>
                                        </div>
                                        <Arrows color='red' additionalClass='arrowUpAnimation' direction='left' small={true}/>
                                        <Arrows color='red' additionalClass='arrowLeftAnimation' small={true}/>
                                    </div>
                                </div>
                            </div>}
                            detailsTextBlock1={<div>
                                <b>{intl.formatMessage({ id: 'schema8.intro4DetailsTextBlock1_1' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema8.intro4DetailsTextBlock1_2' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro4DetailsTextBlock1_3' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro4DetailsTextBlock1_4' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro4DetailsTextBlock1_5' })}</li>
                                </ul>
                            </div>}
                            detailsTextBlock2={<ul>
                                <li>{intl.formatMessage({ id: 'schema8.intro4DetailsTextBlock2_1' })}</li>
                                <li>{intl.formatMessage({ id: 'schema8.intro4DetailsTextBlock2_2' })}</li>
                                <li>{intl.formatMessage({ id: 'schema8.intro4DetailsTextBlock2_3' })}</li>
                            </ul>}
                            mainTextBig={true}
                        />
                    }
                    {
                        tab === 18 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema8.introTitle5' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle5' })}
                            textBlock1={<div className={styles.marginBottom}>
                                <p>{intl.formatMessage({ id: 'schema8.intro5TextBlock1' })}</p>
                                <p>{intl.formatMessage({ id: 'schema8.intro5TextBlock2' })}</p>
                            </div>}
                            textBlock2={<div>
                                <br/>
                                <div className={styles.cardsWrapper}>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block1Title' />
                                            </span>
                                        </div>
                                        <Arrows color='blue' additionalClass='arrowDownAnimation' small={true}/>
                                        <Arrows color='blue' additionalClass='arrowRightAnimation' small={true}/>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block2Title' />
                                            </span>
                                        </div>
                                        <Arrows color='blue' additionalClass='arrowDownAnimation' direction='right' small={true}/>
                                        <Arrows color='red' additionalClass='arrowLeftAnimation' small={true}/>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block3Title' />
                                            </span>
                                        </div>
                                        <Arrows color='blue' additionalClass='arrowRightAnimation' small={true}/>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block4Title' />
                                            </span>
                                        </div>
                                        <Arrows color='red' additionalClass='arrowUpAnimation' direction='left' small={true}/>
                                        <Arrows color='red' additionalClass='arrowLeftAnimation' small={true}/>
                                    </div>
                                </div>
                            </div>}
                            redNoteTitle={intl.formatMessage({ id: 'schema8.intro5RedNoteTitle' })}
                            redNoteText={intl.formatMessage({ id: 'schema8.intro5RedNoteText' })}
                            blueNoteTitle={intl.formatMessage({ id: 'schema8.intro5BlueNoteTitle' })}
                            blueNoteText={intl.formatMessage({ id: 'schema8.intro5BlueNoteText' })}
                            detailsTextBlock1={<div>
                                <b>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock1_1' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock1_2' })}
                                        <ul>
                                            <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock1_3' })}</li>
                                        </ul>
                                    </li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock1_4' })}
                                        <ul>
                                            <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock1_5' })}</li>
                                            <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock1_6' })}</li>
                                            <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock1_7' })}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>}
                            detailsTextBlock2={<ul>
                                <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock2_1' })}
                                    <ul>
                                        <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock2_2' })}</li>
                                    </ul>
                                </li>
                                <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock2_3' })}
                                    <ul>
                                        <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock2_4' })}</li>
                                    </ul>
                                </li>
                                <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock2_5' })}
                                    <ul>
                                        <li>{intl.formatMessage({ id: 'schema8.intro5DetailsTextBlock2_6' })}</li>
                                    </ul>
                                </li>
                            </ul>}
                            mainTextBig={true}
                        />
                    }
                    {
                        tab === 19 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema8.introTitle6' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle6' })}
                            section1Heading={intl.formatMessage({ id: 'schema8.intro6Section1Header' })}
                            textBlockFull={<ul>
                                <li>{intl.formatMessage({ id: 'schema8.intro6TextBlockFull1' })}</li>
                                <li>{intl.formatMessage({ id: 'schema8.intro6TextBlockFull2' })}</li>
                                <li>{intl.formatMessage({ id: 'schema8.intro6TextBlockFull3' })}</li>
                            </ul>}
                            mainTextBig={true}
                        />
                    }
                    {
                        tab === 20 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema8.introTitle7' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle7' })}
                            textBlockFull={<div>{intl.formatMessage({ id: 'schema8.intro7TextBlockFull' })}<br/><br/></div>}
                            note={intl.formatMessage({ id: 'schema8.intro7Note' })}
                            mainTextBig={true}
                        />
                    }
                    {
                        tab === 21 && <SchemaIntroTabs
                            title={intl.formatMessage({ id: 'schema8.introTitle8' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle8' })}
                            tabs={[
                                {
                                    title: intl.formatMessage({ id: 'schema8.intro8Tab1' }),
                                    section1Heading: <div>
                                        {intl.formatMessage({ id: 'schema8.intro8Tab1Heading1' })}
                                        <b>{intl.formatMessage({ id: 'schema8.intro8Tab1Heading2' })}</b>
                                    </div>,
                                    textBlockFull: <div>
                                        <p><b>{intl.formatMessage({ id: 'schema8.intro8Tab1Heading2Text1' })}</b></p>
                                        <p>{intl.formatMessage({ id: 'schema8.intro8Tab1Heading2Text2' })}</p>
                                    </div>,
                                    examples: [
                                        {
                                            title: <div><b>{intl.formatMessage({ id: 'schema8.intro8Tab1Example1Title' })}</b></div>,
                                            src: example5
                                        },
                                        {
                                            title: <div><b>{intl.formatMessage({ id: 'schema8.intro8Tab1Example2Title' })}</b></div>,
                                            src: example6
                                        }
                                    ]

                                },
                                {
                                    title: intl.formatMessage({ id: 'schema8.intro8Tab2' }),
                                    section1Heading: <div>
                                        {intl.formatMessage({ id: 'schema8.intro8Tab2Heading1' })}
                                        <b>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2' })}</b>
                                    </div>,
                                    textBlockFull: <div>
                                        <p><b>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text1' })}</b></p>
                                        <p>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text2_1' })}</p>
                                        <p>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text2_2' })}</p>
                                        <ul>
                                            <li>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text2_3' })}</li>
                                            <li>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text2_4' })}</li>
                                            <li>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text2_5' })}</li>
                                            <li>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text2_6' })}</li>
                                        </ul>
                                        <p>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text2_7' })}</p>
                                        <p><b>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text3' })}</b></p>
                                        <p>{intl.formatMessage({ id: 'schema8.intro8Tab2Heading2Text4' })}</p>
                                    </div>,
                                    examples: [
                                        {
                                            title: <div><b>{intl.formatMessage({ id: 'schema8.intro8Tab2Example1Title' })}</b></div>,
                                            src: example7
                                        },
                                        {
                                            title: <div>
                                                <b>{intl.formatMessage({ id: 'schema8.intro8Tab2Example2_1Title' })}</b>
                                                <br/>
                                                {intl.formatMessage({ id: 'schema8.intro8Tab2Example2_2Title' })}
                                            </div>,
                                            src: example8
                                        }
                                    ]

                                }
                            ]}
                        />
                    }
                    {
                        tab === 22 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema8.introTitle9' })}
                            subTitle={intl.formatMessage({ id: 'schema8.introSubTitle9' })}
                            textBlock1={<div>
                                <b>{intl.formatMessage({ id: 'schema8.intro9TextBlock1_1' })}</b>
                                <p>{intl.formatMessage({ id: 'schema8.intro9TextBlock1_2' })}</p>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema8.intro9TextBlock1_3' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro9TextBlock1_4' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro9TextBlock1_5' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro9TextBlock1_6' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro9TextBlock1_7' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema8.intro9TextBlock1_8' })}</li>
                                </ul>
                                {intl.formatMessage({ id: 'schema8.intro9TextBlock1_9' })}
                            </div>}
                            textBlock2={<div>
                                <p>{intl.formatMessage({ id: 'schema8.intro9TextBlock2_1' })}</p>
                                <p>{intl.formatMessage({ id: 'schema8.intro9TextBlock2_2' })}</p>
                                <p>{intl.formatMessage({ id: 'schema8.intro9TextBlock2_2' })}</p>
                            </div>}
                            blueNoteTitle={intl.formatMessage({ id: 'schema8.intro9BlueNoteTitle' })}
                            blueNoteText={<ul>
                                <li>{intl.formatMessage({ id: 'schema8.intro9BlueNoteText1' })}</li>
                                <li>{intl.formatMessage({ id: 'schema8.intro9BlueNoteText2' })}</li>
                            </ul>}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema8Page;
