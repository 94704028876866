import React from 'react';
import { useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema11View1 from '../../components/schemas/schema11/Schema11View1';
import Schema11View2 from '../../components/schemas/schema11/Schema11View2';
import Schema11View3 from '../../components/schemas/schema11/Schema11View3';
import Schema11View4 from '../../components/schemas/schema11/Schema11View4';
import Schema11View5 from '../../components/schemas/schema11/Schema11View5';
import Schema11View6 from '../../components/schemas/schema11/Schema11View6';
import Schema11View7 from '../../components/schemas/schema11/Schema11View7';
import Schema11View8 from '../../components/schemas/schema11/Schema11View8';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema11Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema11.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema11.introSubTitle' })}
                            textBlockFull={intl.formatMessage({ id: 'schema11.intro1TextBlock' })}
                            mainTextBig={true}
                        />
                    }
                    {
                        tab === 1 && <Schema11View1/>
                    }
                    {
                        tab === 2 && <Schema11View2/>
                    }
                    {
                        tab === 3 && <Schema11View3/>
                    }
                    {
                        tab === 4 && <Schema11View4/>
                    }
                    {
                        tab === 5 && <Schema11View5/>
                    }
                    {
                        tab === 6 && <Schema11View6/>
                    }
                    {
                        tab === 7 && <Schema11View7/>
                    }
                    {
                        tab === 8 && <Schema11View8/>
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema11Page;
