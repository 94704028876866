import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import InfoBlock1 from '../InfoBlock1';
import InfoBlock2 from '../InfoBlock2';
import InfoBlock3 from '../InfoBlock3';
import NoteType2 from '../../../../common/components/NoteType2';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// IMAGES
import figure7 from '../images/figure7.png';
import { commonIcons } from '../../../../assets/icons/svg';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema6View3 = () => {
    const intl = useIntl();

    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };
    const screenWidth = useWindowSize().width;

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema6.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema6.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 6.3.3 , Figure 7
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoContent}>
                                    <div className={styles.infoTop}>
                                        <InfoBlock1
                                            stepText={intl.formatMessage({ id: 'schema6.step' })}
                                            stepEndText={intl.formatMessage({ id: 'schema6.stepEnd' })}
                                            stepReviewText={intl.formatMessage({ id: 'schema6.stepReview' })}
                                        />
                                        <div className={styles.double}>
                                            <InfoBlock3
                                                button1Text={intl.formatMessage({ id: 'schema6.button1' })}
                                                button2Text={intl.formatMessage({ id: 'schema6.button2' })}
                                                direction='start'
                                                arrowRight='solid'
                                                arrowRightText={intl.formatMessage({ id: 'schema6.text1' })}
                                            />
                                            <div className={styles.dividerDotted}/>
                                            <InfoBlock3
                                                button1Text={intl.formatMessage({ id: 'schema6.button1' })}
                                                button2Text={intl.formatMessage({ id: 'schema6.button2' })}
                                                direction='end'
                                                arrowLeft='solid'
                                                arrowLeftText={intl.formatMessage({ id: 'schema6.text2' })}
                                            />
                                        </div>
                                        <InfoBlock1
                                            stepText={intl.formatMessage({ id: 'schema6.step2' })}
                                            stepEndText={intl.formatMessage({ id: 'schema6.step2End' })}
                                            stepReviewText={intl.formatMessage({ id: 'schema6.step2Review' })}
                                            direction='reversed'
                                        />
                                    </div>
                                    <div className={styles.divider}/>
                                    <div className={styles.infoBottom}>
                                        <InfoBlock2/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <NoteType2 content={<div>
                    <p>
                        <a href=''><FormattedMessage id='note.noteText18' /></a>
                        <span><FormattedMessage id='note.noteText19' /></span>
                        <a href=''><FormattedMessage id='note.noteText20' /></a>
                        <span><FormattedMessage id='note.noteText21' /></span>
                        <a href=''><FormattedMessage id='note.noteText22' /></a>
                        <span><FormattedMessage id='note.noteText23' /></span>
                        <a href=''><FormattedMessage id='note.noteText24' /></a>
                    </p>
                    <p>
                        <a href=''><FormattedMessage id='note.noteText25' /></a>
                        <span><FormattedMessage id='note.noteText26' /></span>
                        <a href=''><FormattedMessage id='note.noteText22' /></a>
                        <span><FormattedMessage id='note.noteText21' /></span>
                        <a href=''><FormattedMessage id='note.noteText20' /></a>
                        <span><FormattedMessage id='note.noteText23' /></span>
                        <a href=''><FormattedMessage id='note.noteText27' /></a>
                    </p>
                </div>} title={<FormattedMessage id='note.noteTitle6' />}/>
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema6.figure7Title' })}
                    label='NF EN ISO 19650-1:2019, 6.3.3'
                    caption={intl.formatMessage({ id: 'schema6.figure7' })}
                    image={figure7}
                    id={116}
                />
            }
        </div>
    );
};

export default Schema6View3;
