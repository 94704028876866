import React, { useCallback, useEffect, useState } from 'react';
// STYLES
import styles from './styles.module.scss';
import { commonIcons } from '../../assets/icons/svg';

type ArrowNavigationProps = {
    onChange: (arg0: 'right' | 'left') => void;
};

type SwipeTypes = {
    x: number;
    y: number;
    swiping?: boolean;
};

const ArrowNavigation = ({ onChange }: ArrowNavigationProps) => {
    const [swiped, setSwiped] = useState<boolean>(false);
    const [swipe, setSwipe] = useState<SwipeTypes>({ x: 0, y: 0, swiping: false });

    const minXDistance = 50;
    const minYDistance = 50;

    const handleTouchStart = useCallback((e: TouchEvent): void => {
        const touch = e.touches[0];
        setSwipe({ x: touch.clientX, y: touch.clientY });
        setSwiped(false);
    }, []);

    const handleTouchMove = useCallback((e: TouchEvent): void => {
        if (e.changedTouches && e.changedTouches.length) {
            setSwipe((swipe) => ({ ...swipe, swiping: true }));
        }
    }, []);

    const handleTouchEnd = useCallback(
        (e: TouchEvent): void => {
            const touch = e.changedTouches[0];
            const absX = touch.clientX - swipe.x;
            const absY = touch.clientY - swipe.y;
            if (absY <= minYDistance && absY >= -minYDistance && !swiped) {
                if (swipe.swiping) {
                    if (absX > minXDistance) {
                        return onChange('left');
                    }
                    if (absX < -minXDistance) {
                        return onChange('right');
                    }
                }
                setSwiped(true);
            }
            setSwipe({ x: 0, y: 0, swiping: false });
        },
        [minXDistance, minYDistance, onChange, swipe.swiping, swipe.x, swipe.y, swiped]
    );

    const handleKeyArrow = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
                if (e.key === 'ArrowRight') return onChange('right');
                if (e.key === 'ArrowLeft') return onChange('left');
            }
        },
        [onChange]
    );

    const handleArrowClick = (direction: 'right' | 'left') => () => {
        onChange(direction);
    };

    useEffect(() => {
        document.body.addEventListener('keydown', handleKeyArrow);
        document.body.addEventListener('touchstart', handleTouchStart);
        document.body.addEventListener('touchmove', handleTouchMove);
        document.body.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.body.removeEventListener('keydown', handleKeyArrow);
            document.body.removeEventListener('touchstart', handleTouchStart);
            document.body.removeEventListener('touchmove', handleTouchMove);
            document.body.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handleKeyArrow, handleTouchEnd, handleTouchMove, handleTouchStart]);

    return (
        <>
            <div className={styles.arrowLeft} onClick={handleArrowClick('left')}>
                <div className={styles.arrowContent}>
                    {commonIcons.pageArrowLeft}
                </div>
            </div>
            <div className={styles.arrowRight} onClick={handleArrowClick('right')}>
                <div className={styles.arrowContent}>
                    {commonIcons.pageArrowRight}
                </div>
            </div>
        </>
    );
};

export default React.memo(ArrowNavigation);
