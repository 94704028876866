import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// ACTIONS
import setAboutPopup from '../../store/application/actions/setAboutPopup';
import setInfoPopup from '../../store/application/actions/setInfoPopup';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { footerIcons } from '../../assets/icons/svg';
// SELECTORS
import { getPageId } from '../../store/application/selectors/selectors';

const Footer = () => {
    const dispatch = useDispatch();
    const pageId = useSelector(getPageId);

    const handleOpenAboutPopup = () => {
        dispatch(setAboutPopup(true));
    };

    const handleOpenInfoPopup = () => {
        dispatch(setInfoPopup(true));
    };

    return (
        <div className={styles.root}>
            <div className={styles.footerWrapper}>
                <div onClick={handleOpenAboutPopup} className={styles.link}>
                    <FormattedMessage id='footer.about' />
                </div>
                <div onClick={handleOpenInfoPopup} className={styles.link}>
                    <FormattedMessage id='footer.conditions' />
                </div>
                <div className={styles.pageId}><FormattedMessage id='footer.pageId' />: {pageId}</div>
                <div className={styles.socials}>
                    <a
                        href='http://www.facebook.com/sharer.php?u=www.plan-bim-2022.fr'
                        rel='nofollow'
                        target='_blank'
                        className={styles.icon}
                    >
                        {footerIcons.facebook}
                    </a>
                    <a
                        href='http://twitter.com/share?text=www.plan-bim-2022.fr&url=www.plan-bim-2022.fr'
                        rel='nofollow'
                        target='_blank'
                        className={styles.icon}
                    >
                        {footerIcons.twitter}
                    </a>
                    <a
                        href='http://www.linkedin.com/shareArticle?mini=true&url=www.plan-bim-2022.fr'
                        rel='nofollow'
                        target='_blank'
                        className={styles.icon}
                    >
                        {footerIcons.linkedin}
                    </a>
                </div>
                <a href='mailto:contact@plan-bim-2022.fr' target='_blank' rel="noopener noreferrer" className={styles.link}>
                    <FormattedMessage id='footer.contact' />
                </a>
            </div>
        </div>
    );
};

export default Footer;
