import React from 'react';
import { useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema2View1 from '../../components/schemas/schema2/Schema2View1';
import Schema2View2 from '../../components/schemas/schema2/Schema2View2';
import Schema2View3 from '../../components/schemas/schema2/Schema2View3';
import Schema2View4 from '../../components/schemas/schema2/Schema2View4';
import Schema2View5 from '../../components/schemas/schema2/Schema2View5';
import Schema2View6 from '../../components/schemas/schema2/Schema2View6';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema2Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange} />
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema2.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema2.introSubTitle' })}
                            textBlockFull={intl.formatMessage({ id: 'schema2.intro1TextBlock1' })}
                            blueNoteTitle={intl.formatMessage({ id: 'schema2.intro1BlueNoteTitle' })}
                            blueNoteText={<ul>
                                <li>{intl.formatMessage({ id: 'schema2.intro1BlueNoteText1' })}</li>
                                <li>{intl.formatMessage({ id: 'schema2.intro1BlueNoteText2' })}</li>
                            </ul>}
                            blueNoteTextGrey={true}
                            detailsMore={true}
                            detailsTextFull={intl.formatMessage({ id: 'schema2.intro1TextBlock2' })}
                        />
                    }
                    {
                        tab === 1 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema2.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema2.introSubTitle' })}
                            textBlockFull={intl.formatMessage({ id: 'schema2.intro1TextBlock1' })}
                            detailsTextFull={intl.formatMessage({ id: 'schema2.intro1TextBlock2' })}
                            blueNoteTitle={intl.formatMessage({ id: 'schema2.intro1BlueNoteTitle' })}
                            blueNoteText={<ul>
                                <li>{intl.formatMessage({ id: 'schema2.intro1BlueNoteText1' })}</li>
                                <li>{intl.formatMessage({ id: 'schema2.intro1BlueNoteText2' })}</li>
                            </ul>}
                            blueNoteTextGrey={true}
                        />
                    }
                    {
                        tab === 2 && <Schema2View1/>
                    }
                    {
                        tab === 3 && <Schema2View2/>
                    }
                    {
                        tab === 4 && <Schema2View3/>
                    }
                    {
                        tab === 5 && <Schema2View4/>
                    }
                    {
                        tab === 6 && <Schema2View5/>
                    }
                    {
                        tab === 7 && <Schema2View6/>
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema2Page;
