import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { commonIcons } from '../../../assets/icons/svg';
// UTILS
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// ACTIONS
import setImagePopup from '../../../store/application/actions/setImagePopup';

type Data = {
    title?: string,
    image?: string,
    caption?: string,
    label?: string,
    id?: string | number
}

const ImagePopup = ({ title, image, caption, id, label }: Data) => {
    const dispatch = useDispatch();
    const popupRef = useRef<any>(null);

    useEffect(() => {
        disableBodyScroll(popupRef.current);

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const handleClosePopup = () => {
        dispatch(setImagePopup(false));
    };

    return (
        <div className={styles.root}>
            <div className={styles.background} onClick={handleClosePopup}/>
            <div className={styles.container} ref={popupRef}>
                <div className={styles.header}>
                    <div className={styles.titleBlock}>
                        <span className={styles.title}>{title}</span>
                        <div className={styles.isoLabel}>
                            {label}
                        </div>
                    </div>
                    <div className={styles.closeIcon} onClick={handleClosePopup}>
                        {commonIcons.close}
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.imageWrapper}>
                        <img className={styles.image} alt={caption} src={image}/>
                    </div>
                    <div className={styles.caption}>{caption}</div>
                </div>
                <div className={styles.id}>
                    {`Page ID: ${id}`}
                </div>
            </div>
        </div>
    );
};

export default ImagePopup;
