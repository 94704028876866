import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema7PageIcons } from '../../../../assets/icons/svg';
import Tooltip from '../Tooltip';

type Data = {
    buttonText: string,
    mainText: string,
    bottomText: string,
    color?: string,
    chart?: boolean,
    chartText1?: string,
    chartText2?: string
};

const InfoBlock3 = ({ buttonText, mainText, bottomText, color, chart, chartText1, chartText2 }: Data) => {
    const intl = useIntl();
    const rootClassNames = classNames(styles.wrapper, {
        [styles.purpleColor]: color === 'purple',
        [styles.blueColor]: color === 'blue'
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.button}>
                {buttonText}
                <Tooltip
                    tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_5' })}
                    tooltipText={<div>
                        <FormattedMessage id='tooltip.text13_7' />
                    </div>}
                    href='partie désignée'
                />
            </div>
            <div className={styles.arrow}>
                {schema7PageIcons.arrowGrey}
            </div>
            <div className={styles.mainText}>{mainText}</div>
            {
                chart &&
                <div className={styles.chartWrapper}>
                    {schema7PageIcons.chart}
                    <div className={styles.chartText1}>{chartText1}</div>
                    <div className={styles.chartText2}>{chartText2}</div>
                </div>
            }
            <div className={styles.bottomText}>{bottomText}</div>
        </div>
    );
};

export default React.memo(InfoBlock3);
