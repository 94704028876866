import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useHistory } from 'react-router-dom';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons, schema13PageIcons } from '../../../../assets/icons/svg';
import figure2 from './../images/figure2.png';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
import setPageIdBack from '../../../../store/application/actions/setPageIdBack';
import setSearchQuery from '../../../../store/application/actions/setSearchQuery';
import setPageId from '../../../../store/application/actions/setPageId';
// SELECTORS
import { getImagePopup, getPageId } from '../../../../store/application/selectors/selectors';
// COMPONENTS
import Note from '../../../../common/components/Note';
import ImagePopup from '../../../../common/components/ImagePopup';
import Tooltip from '../Tooltip';
import TooltipSchema7 from '../../schema7/Tooltip';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';

const LEGEND = [
    {
        label: 'A',
        text: <FormattedMessage id='schema13.text1' />,
        class: 'bold',
        id: 'A'
    },
    {
        label: 'B',
        text: <FormattedMessage id='schema13.text2' />,
        class: 'bold',
        id: 'B'
    },
    {
        label: 'C',
        text: <FormattedMessage id='schema13.text3' />,
        class: 'bold',
        id: 'C'
    },
    {
        label: '...',
        text: <FormattedMessage id='schema13.text4' />,
        class: 'common'
    },
    {
        label: '1',
        text: <FormattedMessage id='schema13.text5' />,
        class: 'greyColor'
    },
    {
        label: '2',
        text: <FormattedMessage id='schema13.text6' />,
        class: 'blueColor'
    },
    {
        label: '3',
        text: <FormattedMessage id='schema13.text7' />,
        class: 'yellowColor'
    },
    {
        label: schema13PageIcons.legendIcon1,
        text: <FormattedMessage id='schema13.text8' />,
        class: 'common'
    },
    {
        label: schema13PageIcons.legendIcon2,
        text: <FormattedMessage id='schema13.text9' />,
        class: 'common'
    }
];

const B = [1, 2, 3, 4];
const C = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const TWO = [1, 2, 3, 4, 5];
const THREE = [1, 2, 3, 4, 5];
const DASHED_SMALL = [1, 2, 3, 4, 5, 6, 7];
const SOLID_SMALL = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Schema13View1 = () => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();
    const pageId = useSelector(getPageId);
    const history = useHistory();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };

    const handleSearch = (query: string) => () => {
        dispatch(setPageIdBack(pageId));
        dispatch(setSearchQuery(query));
        history.push('/page/109');
        dispatch(setPageId(109));
    };

    const screenWidth = useWindowSize().width;
    const infoBlockYellowClassNames = classNames(styles.infoBlock, styles.yellowColored);
    const infoBlockBlueClassNames = classNames(styles.infoBlock, styles.blueColored);
    const mainContentWhiteClassNames = classNames(styles.mainContent, styles.whiteColored);
    const rowClassNames = (item: { class: string }) => classNames(styles.row, styles[item.class]);
    const bTriggerClassNames = (item: number) => classNames(styles.bTrigger, [styles[`bTrigger${item}`]]);
    const cTriggerClassNames = (item: number) => classNames(styles.cTrigger, [styles[`cTrigger${item}`]]);
    const twoTriggerClassNames = (item: number) => classNames(styles.twoTrigger, [styles[`twoTrigger${item}`]]);
    const threeTriggerClassNames = (item: number) => classNames(styles.threeTrigger, [styles[`threeTrigger${item}`]]);
    const dashedSmTriggerClassNames = (item: number) => classNames(styles.dashedSmTrigger, [styles[`dashedSmTrigger${item}`]]);
    const solidSmTriggerClassNames = (item: number) => classNames(styles.solidSmTrigger, [styles[`solidSmTrigger${item}`]]);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema13.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema13.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-2:2019, 0.5, Figure 2
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.illustration}>
                                    <div className={styles.illustrationImage}>
                                        {schema13PageIcons.schema}
                                    </div>
                                    <div className={styles.aTrigger}>
                                        <Tooltip content={intl.formatMessage({ id: 'tooltip.text68' })} theme='blue' width='98px'/>
                                    </div>
                                    {
                                        B.map((item, i) => (
                                            <div className={bTriggerClassNames(item)} key={i}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text69' })} theme='blue' width='98px'/>
                                            </div>
                                        ))
                                    }
                                    {
                                        C.map((item, i) => (
                                            <div className={cTriggerClassNames(item)} key={i}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text69' })} theme='blue' width='98px'/>
                                            </div>
                                        ))
                                    }
                                    <div className={styles.oneTrigger}>
                                        <Tooltip content={intl.formatMessage({ id: 'tooltip.text71' })} theme='grey' width='98px'/>
                                    </div>
                                    {
                                        TWO.map((item, i) => (
                                            <div className={twoTriggerClassNames(item)} key={i}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text72' })} theme='grey' width='98px'/>
                                            </div>
                                        ))
                                    }
                                    {
                                        THREE.map((item, i) => (
                                            <div className={threeTriggerClassNames(item)} key={i}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text73' })} theme='grey' width='98px'/>
                                            </div>
                                        ))
                                    }
                                    {
                                        DASHED_SMALL.map((item, i) => (
                                            <div className={dashedSmTriggerClassNames(item)} key={i}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text75' })} theme='red' width='130px'/>
                                            </div>
                                        ))
                                    }
                                    <div className={styles.dashedBgTrigger}>
                                        <Tooltip content={intl.formatMessage({ id: 'tooltip.text75' })} theme='red' width='130px'/>
                                    </div>
                                    {
                                        SOLID_SMALL.map((item, i) => (
                                            <div className={solidSmTriggerClassNames(item)} key={i}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text74' })} theme='red' width='172px'/>
                                            </div>
                                        ))
                                    }
                                    <div className={styles.solidBgTrigger}>
                                        <Tooltip content={intl.formatMessage({ id: 'tooltip.text74' })} theme='red' width='172px'/>
                                    </div>
                                </div>
                                <div className={styles.infoBlockContainer}>
                                    <div className={styles.topContent}>
                                        <div className={styles.cardsWrapper}>
                                            <div className={infoBlockYellowClassNames}>
                                                <div className={styles.mainContent}>
                                                    <span className={styles.infoBlockText}>
                                                        <FormattedMessage id='schema3.block1Title' />
                                                    </span>
                                                </div>
                                                <div className={mainContentWhiteClassNames}>
                                                    <span className={styles.infoBlockText}>
                                                        <ul>
                                                            <li><FormattedMessage id='schema3.block4Title' /></li>
                                                            <li><FormattedMessage id='schema3.block4Title1' /></li>
                                                        </ul>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={infoBlockYellowClassNames}>
                                                <div className={styles.mainContent}>
                                                    <span className={styles.infoBlockText}>
                                                        <FormattedMessage id='schema3.block2Title' />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={infoBlockBlueClassNames}>
                                                <div className={styles.mainContent}>
                                                    <span className={styles.infoBlockText}>
                                                        <FormattedMessage id='schema3.block3Title' />
                                                    </span>
                                                    <span className={styles.infoBlockText}>
                                                        <FormattedMessage id='schema3.block3Title1' />
                                                    </span>
                                                </div>
                                                <div className={mainContentWhiteClassNames}>
                                                    <span className={styles.infoBlockText}>
                                                        <ul>
                                                            <li>
                                                                <FormattedMessage id='schema3.block5Title' />
                                                                <span onClick={handleSearch('BEP')} className={styles.link}>
                                                                    <FormattedMessage id='schema3.block5Title1' />
                                                                </span>
                                                                <FormattedMessage id='schema3.block5Title2' />
                                                            </li>
                                                            <li>
                                                                <FormattedMessage id='schema3.block5Title6' />
                                                                <span onClick={handleSearch('TIR')} className={styles.link}>
                                                                    <FormattedMessage id='schema3.block5Title3' />
                                                                </span>
                                                            </li>
                                                            <li><FormattedMessage id='schema3.block5Title4' /></li>
                                                        </ul>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.bottomContent}>
                                        <div className={styles.tableTitle}>
                                            <FormattedMessage id='schema13.legend' />
                                        </div>
                                        <div className={styles.table}>
                                            {
                                                LEGEND.map((item, i) => (
                                                    <div className={rowClassNames(item)} key={i}>
                                                        <div className={styles.cell1}>{item.label}</div>
                                                        <div className={styles.cell2}>
                                                            {item.text}
                                                            {
                                                                item.id === 'A' &&
                                                                <TooltipSchema7
                                                                    tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_1' })}
                                                                    tooltipText={<div>
                                                                        <FormattedMessage id='tooltip.text13_2' />
                                                                    </div>}
                                                                    href='partie désignante'
                                                                    start={true}
                                                                />
                                                            }
                                                            {
                                                                item.id === 'B' &&
                                                                <TooltipSchema7
                                                                    tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_5' })}
                                                                    tooltipText={<div>
                                                                        <FormattedMessage id='tooltip.text13_7' />
                                                                    </div>}
                                                                    href='partie désignée'
                                                                    start={true}
                                                                />
                                                            }
                                                            {
                                                                item.id === 'C' &&
                                                                <TooltipSchema7
                                                                    tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_6' })}
                                                                    tooltipText={<div>
                                                                        <FormattedMessage id='tooltip.text13_8' />
                                                                    </div>}
                                                                    href='partie désignée'
                                                                    start={true}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteText55' /></p></div>} />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema13.figure2Title' })}
                    label='NF EN ISO 19650-2:2019, 0.5'
                    caption={intl.formatMessage({ id: 'schema13.figure2' })}
                    image={figure2}
                    id={129}
                />
            }
        </div>
    );
};

export default Schema13View1;
