import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons, schema1PageIcons } from '../../../../assets/icons/svg';
import figure2 from '../images/figure2.png';
// COMPONENTS
import { TOOLTIP_1, TOOLTIP_2, TOOLTIP_3, TOOLTIP_4, TOOLTIP_5, TOOLTIP_6 } from '../tooltipsData';
import Tooltip from '../Tooltip';
import Note from '../../../../common/components/Note';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema1View3 = () => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };

    const screenWidth = useWindowSize().width;
    const deepBlueContainer = classNames(styles.infoBlock, styles.deepBlue);
    const darkBlueContainer = classNames(styles.infoBlock, styles.darkBlue);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema1.title3' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema1.subTitle3' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 5.1, Figure 2
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoBlock}>
                                    <div className={styles.header}>
                                        <div className={styles.headerText}>
                                            <FormattedMessage id='schema1.block1Title' />
                                        </div>
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block1Text1' />
                                            </div>
                                            <Tooltip
                                                texts={<ul>{TOOLTIP_1.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                title={<p>{TOOLTIP_1.title}</p>}
                                                width={TOOLTIP_1.width}
                                            />
                                            <div className={styles.sideLink}>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                                <div className={styles.midBlock}>
                                                    <FormattedMessage id='schema1.midBlockText2' />
                                                </div>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.midBlock}>
                                            <FormattedMessage id='schema1.midBlockText1' />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block1Text2' />
                                            </div>
                                            <Tooltip
                                                texts={<ul>{TOOLTIP_2.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                title={<p>{TOOLTIP_2.title}</p>}
                                                width={TOOLTIP_2.width}
                                            />
                                            <div className={styles.sideLink}>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                                <div className={styles.midBlock}>
                                                    <FormattedMessage id='schema1.midBlockText1' />
                                                </div>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={deepBlueContainer}>
                                    <div className={styles.header}>
                                        <div className={styles.headerText}>
                                            <FormattedMessage id='schema1.block2Title' />
                                        </div>
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block2Text1' />
                                            </div>
                                            <Tooltip
                                                texts={<ul>{TOOLTIP_3.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                title={<p>{TOOLTIP_3.title}</p>}
                                                width={TOOLTIP_3.width}
                                            />
                                            <div className={styles.sideLink}>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                                <div className={styles.midBlock}>
                                                    <FormattedMessage id='schema1.midBlockText3' />
                                                </div>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.midBlock}>
                                            <FormattedMessage id='schema1.midBlockText1' />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block2Text2' />
                                            </div>
                                            <Tooltip
                                                texts={<ul>{TOOLTIP_4.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                title={<p>{TOOLTIP_4.title}</p>}
                                                width={TOOLTIP_4.width}
                                            />
                                            <div className={styles.sideLink}>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                                <div className={styles.midBlock}>
                                                    <FormattedMessage id='schema1.midBlockText3' />
                                                </div>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={darkBlueContainer}>
                                    <div className={styles.header}>
                                        <div className={styles.headerText}>
                                            <FormattedMessage id='schema1.block3Title' />
                                        </div>
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block3Text1' />
                                            </div>
                                            <Tooltip
                                                texts={<ul>{TOOLTIP_5.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                texts2={<p>{TOOLTIP_5.texts2}</p>}
                                                title={<p>{TOOLTIP_5.title}</p>}
                                                width={TOOLTIP_5.width}
                                            />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.midBlock}>
                                            <FormattedMessage id='schema1.midBlockText4' />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block3Text2' />
                                            </div>
                                            <Tooltip
                                                texts={<ul>{TOOLTIP_6.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                title={<p>{TOOLTIP_6.title}</p>}
                                                width={TOOLTIP_6.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div>
                    <p><FormattedMessage id='note.noteTitle3' /></p>
                    <ul>
                        <li><FormattedMessage id='note.noteText4' /></li>
                        <li><FormattedMessage id='note.noteText5' /></li>
                        <li><FormattedMessage id='note.noteText6' /></li>
                    </ul>
                </div>} />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema1.figure2Title' })}
                    label='NF EN ISO 19650-1:2019, 5.1, Figure 2'
                    caption={intl.formatMessage({ id: 'schema1.figure2' })}
                    image={figure2}
                    id={113}
                />
            }
        </div>
    );
};

export default Schema1View3;
