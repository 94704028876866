import React from 'react';
import { useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema9View1 from '../../components/schemas/schema9/Schema9View1';
import Schema9View2 from '../../components/schemas/schema9/Schema9View2';
import Schema9View3 from '../../components/schemas/schema9/Schema9View3';
import Schema9View4 from '../../components/schemas/schema9/Schema9View4';
import Schema9View5 from '../../components/schemas/schema9/Schema9View5';
import Schema9View6 from '../../components/schemas/schema9/Schema9View6';
import ArrowNavigation from '../../components/ArrowNavigation';
// IMAGES
import example1 from '../../components/schemas/schema9/images/example1.png';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema9Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema9.introTitle1' })}
                            textBlock1={intl.formatMessage({ id: 'schema9.intro1TextBlock1' })}
                            textBlock2={intl.formatMessage({ id: 'schema9.intro1TextBlock2' })}
                            detailsMore={true}
                            detailsTextFull={<div>
                                <img src={example1} alt=''/>
                            </div>}
                            detailsNoBorder={true}
                        />
                    }
                    {
                        tab === 1 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema9.introTitle2' })}
                            textBlock1={intl.formatMessage({ id: 'schema9.intro1TextBlock1' })}
                            textBlock2={intl.formatMessage({ id: 'schema9.intro1TextBlock2' })}
                            detailsTextFull={<div>
                                <img src={example1} alt=''/>
                            </div>}
                            detailsNoBorder={true}
                        />
                    }
                    {
                        tab === 2 && <Schema9View1/>
                    }
                    {
                        tab === 3 && <Schema9View2/>
                    }
                    {
                        tab === 4 && <Schema9View3/>
                    }
                    {
                        tab === 5 && <Schema9View4/>
                    }
                    {
                        tab === 6 && <Schema9View5/>
                    }
                    {
                        tab === 7 && <Schema9View6/>
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema9Page;
