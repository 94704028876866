import React, { ReactElement } from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';

type Data = {
    content: Element | ReactElement<any, any> | string,
    theme?: string,
    direction?: string,
    width?: string,
    position?: string
}

const Tooltip = ({ content, theme, direction, width, position }: Data) => {
    const rootClassNames = classNames(styles.toolTip, {
        [styles.themeRed]: theme === 'red',
        [styles.themeBlue]: theme === 'blue',
        [styles.themeGrey]: theme === 'grey',
        [styles.reversed]: direction === 'reversed',
        [styles.cornerPosition]: position === 'corner'
    });

    return (
        <div className={rootClassNames} style={{ maxWidth: width }}>
            <div className={styles.tooltipText}>
                {content}
            </div>
        </div>
    );
};

export default React.memo(Tooltip);
