import React from 'react';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { enPratiquePageIcons } from '../../assets/icons/svg';
// COMPONENTS
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number
};

const EnPratiquePage = ({ handleSetView, forward, back }: Props) => {
    const handleChangePage = (direction:'right'|'left') => {
        if (direction === 'right') {
            handleSetView(forward)();
        } else {
            handleSetView(back)();
        }
    };

    const handleStepSwitch = (index: number) => (event: any) => {
        event.preventDefault();
        handleSetView(index)();
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleChangePage}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    <div className={styles.innerContainer}>
                        <div className={styles.title}>
                            <FormattedMessage id='enPratiquePage.title' />
                        </div>
                        <div className={styles.subTitle}>
                            <FormattedMessage id='enPratiquePage.subTitle' />
                        </div>
                        <div className={styles.buttonsBlock}>
                            <div className={styles.button} onClick={handleStepSwitch(85)}>
                                <FormattedMessage id='enPratiquePage.button1' />
                            </div>
                            <div className={styles.arrowIcon}>
                                {enPratiquePageIcons.arrow1}
                            </div>
                            <div className={styles.button} onClick={handleStepSwitch(86)}>
                                <FormattedMessage id='enPratiquePage.button2' />
                            </div>
                            <div className={styles.arrowIcon}>
                                {enPratiquePageIcons.arrow1}
                            </div>
                            <div className={styles.button} onClick={handleStepSwitch(87)}>
                                <FormattedMessage id='enPratiquePage.button3' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnPratiquePage;
