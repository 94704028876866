/* eslint-disable max-len */
export const TERMS_LIST = {
    data: [
        {
            content: {
                title: 'matrice des responsabilités',
                description: 'organigramme décrivant la participation, par différentes fonctions, à l’exécution des tâches ou à la fourniture de livrables\n\nNote : Une matrice des responsabilités peut indiquer une responsabilité, une consultation et une information, ainsi que l’obligation d’exécuter les tâches ou de fournir les livrables.\n\n[SOURCE : ISO 37500:2014, 3.16, modifiée — le mot «rôles» a été remplacé par «fonctions»; les mots «pour un accord d’externalisation» ont été supprimés; la Note 1 à l’article a été ajoutée.]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.1.1]',
                examples: '',
                full_title: 'matrice des responsabilités'
            },
            id: 5523,
            cid: 1630019911732,
            slug: 'matrice-des-responsabilites'
        },
        {
            content: {
                title: 'espace',
                description: 'étendue tridimensionnelle limitée, définie physiquement ou de manière théorique\n\n[SOURCE : ISO 12006‑2:2015, 3.1.8]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.1.2]',
                examples: '',
                full_title: 'espace'
            },
            id: 4844,
            cid: 1599931104806,
            slug: 'espace'
        },
        {
            content: {
                title: 'acteur',
                description: 'personne, organisation ou unité d’organisation impliquée dans un processus de construction\n\nNote 1 : Les unités d’organisation comprennent, mais sans s’y limiter, les services et les équipes.\nNote 2 : Dans le contexte du présent document, les processus de construction se déroulent au cours de la phase de réalisation (3.2.11) et de la phase d’exploitation (3.2.12).\n\n[ISO 29481‑1:2016, 3.1, modifiée — Les mots «département, équipe, etc.» ont été supprimés; la Note 1 et la Note 2 à l’article ont été ajoutées.]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.1]',
                examples: '',
                full_title: 'acteur'
            },
            id: 5209,
            cid: 1611095742203,
            slug: 'acteur'
        },
        {
            content: {
                title: 'désignation',
                description: 'instruction ayant fait l’objet d’un accord pour la fourniture d’informations (3.3.1) relatives à des ouvrages, des produits ou des services\n\nNote : Ce terme est employé quelle que soit la nature de la désignation entre les parties (formelle ou non).\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.2]',
                examples: '',
                full_title: 'désignation'
            },
            id: 6406,
            cid: 1553697767870,
            slug: 'designation'
        },
        {
            content: {
                title: 'partie désignée',
                description: 'partie fournissant des informations (3.3.1) relatives à des ouvrages, des produits ou des services\n\nNote 1 : Pour chaque équipe de production (3.2.6), il convient d’identifier une partie désignée principale qui peut être la même organisation que celle des équipes de travail (3.2.7).\nNote 2 : Ce terme est employé quelle que soit la nature de la désignation (3.2.2) écrite, formelle ou non, en place.\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.3]',
                examples: '',
                full_title: 'partie désignée'
            },
            id: 6251,
            cid: 1613022734587,
            slug: 'partie-designee'
        },
        {
            content: {
                title: 'partie désignante',
                description: 'récepteur des informations (3.3.1) relatives aux ouvrages, produits ou services fournis par une partie désignée (3.2.3) principale\n\nNote 1 : Dans certains pays, la partie désignante peut être appelée maître d’ouvrage (3.2.5), propriétaire ou employeur, mais la partie désignante ne se limite pas à ces fonctions.\nNote 2 : Ce terme est employé quelle que soit la nature de la désignation (3.2.2) entre les parties (formelle ou non).\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.4]',
                examples: '',
                full_title: 'partie désignante'
            },
            id: 5247,
            cid: 1592225301333,
            slug: 'partie-designante'
        },
        {
            content: {
                title: 'maître d’ouvrage',
                description: 'acteur (3.2.1) responsable du lancement d’un projet et de l’approbation du programme de construction\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.5]',
                examples: '',
                full_title: 'maître d’ouvrage'
            },
            id: 5129,
            cid: 1586423915072,
            slug: 'maitre-douvrage'
        },
        {
            content: {
                title: 'équipe de production',
                description: 'partie désignée (3.2.3) principale et parties désignées associéesLa taille d’une équipe de production peut aller d’une seule personne exécutant toutes les fonctions nécessaires jusqu’à des équipes de travail (3.2.7) muticouches complexes. La taille et la structure de chaque équipe de production sont adaptées à l’échelle et à la complexité des activités de gestion d’actifs ou de réalisation de projet.\n\nNote 1 : Plusieurs équipes de production peuvent être désignées simultanément et/ou séquentiellement en lien avec un seul actif ou projet, en fonction de l’échelle et de la complexité des activités de gestion d’actifs ou de réalisation de projet.\nNote 2 : Une équipe de production peut se composer de plusieurs équipes de travail au sein de l’organisation de la partie désignée principale et de toutes parties désignées.\nNote 3 : Dans certains pays, une équipe de production peut être constituée par la partie désignante (3.2.4), et non par la partie désignée principale.\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.6]',
                examples: '',
                full_title: 'équipe de production'
            },
            id: 6613,
            cid: 1577120824924,
            slug: 'equipe-de-production'
        },
        {
            content: {
                title: 'équipe de travail',
                description: 'personnes rassemblées pour exécuter une tâche spécifique\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.7]',
                examples: '',
                full_title: 'équipe de travail'
            },
            id: 6474,
            cid: 1613592324562,
            slug: 'equipe-de-travail'
        },
        {
            content: {
                title: 'actif',
                description: 'item, chose ou entité qui a une valeur potentielle ou réelle pour un organisme\n\n[ISO 55000:2014, 3.2.1, modifiée — Les Notes 1, 2 et 3 à l’article ont été supprimées.]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.8]',
                examples: '',
                full_title: 'actif'
            },
            id: 5436,
            cid: 1618372988014,
            slug: 'actif'
        },
        {
            content: {
                title: 'informations de projet',
                description: 'informations (3.3.1) produites pour ou utilisées dans un projet particulier\n\n[ISO 6707‑2:2017, 3.2.3]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.9]',
                examples: '',
                full_title: 'informations de projet'
            },
            id: 5324,
            cid: 1629189849505,
            slug: 'informations-de-projet'
        },
        {
            content: {
                title: 'cycle de vie',
                description: 'vie de l’actif (3.2.8) allant de la définition de ses exigences jusqu’au terme de son utilisation, et englobant sa conception, son développement, son exploitation, sa maintenance et son élimination\n\n[ISO/TS 12911:2012, 3.13, modifiée — Les mots «.étapes et activités englobant la vie du système» ont été remplacés par «vie de l’actif»; les NOTES 1 et 2 ont été supprimées.]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.10]',
                examples: '',
                full_title: 'cycle de vie'
            },
            id: 5365,
            cid: 1570977486199,
            slug: 'cycle-de-vie'
        },
        {
            content: {
                title: 'phase de réalisation',
                description: 'partie du cycle de vie (3.2.10) pendant laquelle un actif (3.2.8) est conçu, construit et mis en service\n\nNote : La phase de réalisation reflète normalement une approche en plusieurs étapes d’un projet.\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.11]',
                examples: '',
                full_title: 'phase de réalisation'
            },
            id: 5488,
            cid: 1555494847246,
            slug: 'phase-de-realisation'
        },
        {
            content: {
                title: 'phase d’exploitation',
                description: 'partie du cycle de vie (3.2.10) pendant laquelle l’actif (3.2.8) est utilisé, exploité et entretenu\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.12]',
                examples: '',
                full_title: 'phase d’exploitation'
            },
            id: 5448,
            cid: 1549173754148,
            slug: 'phase-dexploitation'
        },
        {
            content: {
                title: 'événement déclencheur',
                description: 'événement planifié ou non planifié qui change un actif (3.2.8) ou son état pendant son cycle de vie (3.2.10), en entraînant ainsi un échange d’informations (3.3.7)Pendant la phase de réalisation (3.2.11), les événements déclencheurs reflètent normalement la fin des étapes du projet.\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.13]',
                examples: '',
                full_title: 'événement déclencheur'
            },
            id: 6785,
            cid: 1572332405291,
            slug: 'evenement-declencheur'
        },
        {
            content: {
                title: 'principal point de décision',
                description: 'moment au cours du cycle de vie (3.2.10) où est prise une décision cruciale pour la conduite ou la viabilité de l’actif (3.2.8)\n\nNote : Au cours d’un projet, ces points de décision sont généralement alignés sur les étapes du projet.\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.2.14]',
                examples: '',
                full_title: 'principal point de décision'
            },
            id: 6576,
            cid: 1571727913774,
            slug: 'principal-point-de-decision'
        },
        {
            content: {
                title: 'information',
                description: 'représentation des données qui est réinterprétable sous un aspect formalisé adapté à la communication, à l’interprétation ou au traitement\n\nNote : L’information peut être traitée par des moyens humains ou automatiques.\n\n[IEC 82045‑1:2001, 3.1.4, modifiée — Le terme «données» a été remplacé par «information»: dans la définition, le mot «information» a été remplacé par «données».]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.1]',
                examples: '',
                full_title: 'information'
            },
            id: 5901,
            cid: 1643170617395,
            slug: 'information'
        },
        {
            content: {
                title: 'exigence d’informationn',
                description: 'spécification établissant l’information (3.3.1) à produire, l’instant où elle doit l’être, sa méthode de production et son destinataire\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.2]',
                examples: '',
                full_title: 'exigence d’informationn  | EN : Information Requirement (IR)'
            },
            id: 5480,
            cid: 1629889978733,
            slug: 'exigence-dinformationn-en-information-requirement-ir'
        },
        {
            content: {
                title: 'exigences d’information de l’organisation',
                description: 'exigences d’information (3.3.2) en lien avec les objectifs de l’organisation\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.3]',
                examples: '',
                full_title: 'exigences d’information de l’organisation | EN : organizational information requirements OIR)'
            },
            id: 5333,
            cid: 1570729630480,
            slug: 'exigences-dinformation-de-lorganisation-en-organizational-information-requirements-oir'
        },
        {
            content: {
                title: 'exigences d’information d’actif',
                description: 'exigences d’information (3.3.2) en lien avec l’exploitation d’un actif (3.2.8)\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.4]',
                examples: '',
                full_title: 'exigences d’information d’actif | EN : asset information requirements (AIR)'
            },
            id: 6097,
            cid: 1618977953645,
            slug: 'exigences-dinformation-dactif-en-asset-information-requirements-air'
        },
        {
            content: {
                title: 'exigences d’information du projet',
                description: 'exigences d’information (3.3.2) en lien avec la livraison d’un actif (3.2.8)\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.5]',
                examples: '',
                full_title: 'exigences d’information du projet | EN : project information requirements (PIR)'
            },
            id: 5456,
            cid: 1592372143395,
            slug: 'exigences-dinformation-du-projet-en-project-information-requirements-pir'
        },
        {
            content: {
                title: 'exigences d’échange d’informations',
                description: 'exigences d’information (3.3.2) en lien avec une désignation (3.2.2)\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.6]',
                examples: 'Les EIR exposent les aspects administratifs, commerciaux et techniques de la production d’informations sur les projets.',
                full_title: 'exigences d’échange d’informations | EN : exchange information requirements (EIR)'
            },
            id: 6242,
            cid: 1638819208035,
            slug: 'exigences-dechange-dinformations-en-exchange-information-requirements-eir'
        },
        {
            content: {
                title: 'échanger des informations',
                description: 'action consistant à satisfaire une exigence d’information (3.3.2) ou l’une de ses parties\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.7]',
                examples: '',
                full_title: 'échanger des informations'
            },
            id: 6774,
            cid: 1553742950444,
            slug: 'echanger-des-informations'
        },
        {
            content: {
                title: 'modèle d’information',
                description: 'ensemble de conteneurs d’information (3.3.12) structurés et non structurés\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.8]',
                examples: '',
                full_title: 'modèle d’information'
            },
            id: 5341,
            cid: 1597901990824,
            slug: 'modele-dinformation'
        },
        {
            content: {
                title: 'modèle d’information d’actif',
                description: 'modèle d’information (3.3.8) se rapportant à la phase d’exploitation (3.2.12)\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.9]',
                examples: '',
                full_title: 'modèle d’information d’actif | EN : asset information model (AIM)'
            },
            id: 5795,
            cid: 1631590111007,
            slug: 'modele-dinformation-dactif-en-asset-information-model-aim'
        },
        {
            content: {
                title: 'modèle d’information du projet',
                description: 'modèle d’information (3.3.8) se rapportant à la phase de réalisation (3.2.11)\n\nNote : Pendant le projet, le modèle d’information du projet peut être utilisé pour illustrer la conception prévue (quelquefois appelée le modèle de conception prévu) ou la représentation virtuelle de l’actif (3.2.8) à construire (quelquefois appelée le modèle de construction virtuel).\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.10]',
                examples: '',
                full_title: 'modèle d’information du projet | EN : project information model (PIM)'
            },
            id: 5079,
            cid: 1628320913998,
            slug: 'modele-dinformation-du-projet-en-project-information-model-pim'
        },
        {
            content: {
                title: 'fédération',
                description: 'création d’un modèle d’information (3.3.8) composite à partir de conteneurs d’information (3.3.12) séparés\n\nNote : Les conteneurs d’information séparés utilisés pendant la fédération peuvent provenir de différentes équipes de travail (3.2.7).\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.11]',
                examples: '',
                full_title: 'fédération'
            },
            id: 6078,
            cid: 1616400674689,
            slug: 'federation'
        },
        {
            content: {
                title: 'conteneur d’information',
                description: 'ensemble nommé persistant d’informations (3.3.1) récupérables au sein d’une hiérarchie de stockage de fichier, de système ou d’applicationSous-répertoire, fichier d’informations (modèle, document, tableau et calendrier inclus), ou sous-ensemble distinct d’un fichier d’informations tel qu’un chapitre ou une section, une couche ou un symbole.\n\nNote 1 : Les conteneurs d’information structurés incluent les modèles géométriques, les calendriers et les bases de données. Les conteneurs d’information non structurés incluent la documentation, les clips vidéo et les enregistrements sonores.\nNote 2 : Les informations persistantes existent sur une échelle de temps suffisamment longue pour pouvoir les gérer, c’est-à-dire qu’elles excluent les informations transitoires telles que les résultats de recherches sur Internet.\nNote 3 : Il convient que les conteneurs d’information soient conformes à une convention de nommage convenue.\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.12]',
                examples: 'EXEMPLES : Sous-répertoire, fichier d’informations (modèle, document, tableau et calendrier inclus), ou sous-ensemble distinct d’un fichier d’informations tel qu’un chapitre ou une section, une couche ou un symbole.',
                full_title: 'conteneur d’information'
            },
            id: 5519,
            cid: 1620993029883,
            slug: 'conteneur-dinformation'
        },
        {
            content: {
                title: 'code de statut',
                description: 'métadonnée décrivant l’adéquation du contenu d’un conteneur d’information (3.3.12)\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.13]',
                examples: '',
                full_title: 'code de statut'
            },
            id: 5746,
            cid: 1573421627779,
            slug: 'code-de-statut'
        },
        {
            content: {
                title: 'modélisation d’informations de la construction - (EN : building information modelling)',
                description: 'utilisation d’une représentation numérique partagée d’un actif (3.2.8) bâti pour faciliter les processus de conception, de construction et d’exploitation et former une base fiable permettant les prises de décision\n\nNote : Les actifs bâtis comprennent, mais sans s’y limiter, les bâtiments, les ponts, les routes, les usines.\n\n[ISO 29481‑1:2016, 3.2, modifiée — Le mot «objet» a été remplacé par «actif»; les mots «comprenant bâtiments, ponts, routes, usines, etc.» ont été supprimés; la Note 1 à l’article d’origine a été remplacée par une nouvelle.]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.14]',
                examples: 'Utilisation d’une représentation numérique partagée d’un actif bâti pour faciliter les processus de conception, de construction et d’exploitation de manière à constituer une base fiable permettant les prises de décision\nLa définition du BIM est normalisée, il peut y être fait référence dans les pièces de nos Marchés, sans ambiguïté.',
                full_title: 'modélisation d’informations de la construction - (EN : building information modelling)'
            },
            id: 5519,
            cid: 1577058656634,
            slug: 'modelisation-dinformations-de-la-construction-en-building-information-modelling'
        },
        {
            content: {
                title: 'environnement de données commun',
                description: 'source convenue d’information (3.3.1) sur un projet ou un actif (3.2.8) donné, utilisée pour collecter, gérer et diffuser chaque conteneur d’information (3.3.12) par le biais d’un processus géréLe flux de travaux d’un CDE décrit les processus à utiliser et une solution CDE peut offrir la technologie de prise en charge de ces processus.\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.15]',
                examples: '',
                full_title: 'environnement de données commun | EN : common data environment (CDE)'
            },
            id: 5691,
            cid: 1627329513658,
            slug: 'environnement-de-donnees-commun-en-common-data-environment-cde'
        },
        {
            content: {
                title: 'niveau du besoin d’information',
                description: 'cadre qui définit l’étendue et la granularité de l’information (3.3.1)\n\nNote : La définition du niveau du besoin d’information vise notamment à éviter la livraison d’une quantité d’informations trop importante.\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.16]',
                examples: 'Un vrai bouleversement dans nos pratiques.\n La problématique de besoin en géométrie, besoin en information, besoin en documentation, est traité par la norme européenne EN 17412',
                full_title: 'niveau du besoin d’information | EN : Level of information need'
            },
            id: 5040,
            cid: 1549097961272,
            slug: 'niveau-du-besoin-dinformation-en-level-of-information-need'
        },
        {
            content: {
                title: 'aptitude',
                description: 'mesure du potentiel d’exécution et de fonctionnement\n\nNote : Dans le contexte du présent document, ceci concerne la compétence, les connaissances ou l’expertise nécessaires pour gérer l’information (3.3.1).\n\n[ISO 6707‑1:2017, 3.7.1.11, modifiée — La Note 1 à l’article a été ajoutée.]\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.17]',
                examples: '',
                full_title: 'aptitude'
            },
            id: 5917,
            cid: 1575668609616,
            slug: 'aptitude'
        },
        {
            content: {
                title: 'capacité',
                description: 'ressources disponibles pour l’exécution et le fonctionnement\n\nNote : Dans le contexte du présent document, ceci concerne les moyens, ressources et modes opératoires de gestion de l’information (3.3.1).\n\n[SOURCE : NF EN ISO 19650-1 (2018), 3.3.18]',
                examples: '',
                full_title: 'capacité'
            },
            id: 6237,
            cid: 1590548849445,
            slug: 'capacite'
        },
        {
            content: {
                title: 'critères d’acceptation',
                description: 'preuve exigée pour considérer que les exigences ont été satisfaites\n\n[SOURCE : ISO 22263:2008, 2.1]\n\n[SOURCE : NF EN ISO 19650-2 (2018), 3.1.1.1]',
                examples: '',
                full_title: 'critères d’acceptation'
            },
            id: 6679,
            cid: 1554827571221,
            slug: 'criteres-dacceptation'
        },
        {
            content: {
                title: 'équipe du projet',
                description: 'partie désignante et toutes les équipes de production\n\n[SOURCE : NF EN ISO 19650-2 (2018), 3.1.2.1]',
                examples: '',
                full_title: 'équipe du projet'
            },
            id: 6647,
            cid: 1637987297158,
            slug: 'equipe-du-projet'
        },
        {
            content: {
                title: 'plan de travail',
                description: 'document détaillant les principales étapes de conception, de construction et de maintenance d’un projet et identifiant les principales tâches et les personnes concernées\n\nNote : Un plan de travail peut être étendu pour inclure les étapes de démolition et de recyclage d’un projet.\n\n[SOURCE : ISO 6707‑2:2017, 3.2.19, modifiée — Les termes alternatifs «plan d’activation, É-U» et «plan de projet, É-U» ont été supprimés; la Note 1 à l’article a été ajoutée.]\n\n[SOURCE : NF EN ISO 19650-2 (2018), 3.1.2.2]',
                examples: '',
                full_title: 'plan de travail'
            },
            id: 5261,
            cid: 1570083358549,
            slug: 'plan-de-travail'
        },
        {
            content: {
                title: 'plan d’exécution BIM',
                description: 'plan décrivant la manière dont l’équipe de production traitera les aspects de la désignation relatifs à la gestion de l’information\n\nNote : Le pré-plan d’exécution BIM de la désignation est axé sur l’approche proposée par l’équipe de production pour la gestion de l’information et son aptitude et sa capacité à gérer l’information.\n\n[SOURCE : NF EN ISO 19650-2 (2018), 3.1.3.1]',
                examples: 'En réponse aux EIR\nLe Plan d’Exécution BIM (BEP) compte 7 chapitres, il est une pièce portée au Marché.',
                full_title: 'plan d’exécution BIM | EN : BIM execution plan (BEP)'
            },
            id: 6151,
            cid: 1639565059123,
            slug: 'plan-dexecution-bim-en-bim-execution-plan-bep'
        },
        {
            content: {
                title: 'jalon de livraison de l’information',
                description: 'événement planifié pour un échange d’informations prédéfini\n\n[SOURCE : NF EN ISO 19650-2 (2018), 3.1.3.2]',
                examples: 'Un jalon peut être une phase de projet, mais pas seulement, il peut par exemple être un point critique du projet sur un process particulier',
                full_title: 'jalon de livraison de l’information'
            },
            id: 5318,
            cid: 1566709558749,
            slug: 'jalon-de-livraison-de-linformation'
        },
        {
            content: {
                title: 'plan directeur de livraison de l’information',
                description: 'plan incorporant tous les plans de livraison de l’information par tâche (3.1.3.4)\n\n[SOURCE : NF EN ISO 19650-2 (2018), 3.1.3.3]',
                examples: '',
                full_title: 'plan directeur de livraison de l’information | EN : Master Information Delivery Plan (MIDP)'
            },
            id: 5121,
            cid: 1636714001453,
            slug: 'plan-directeur-de-livraison-de-linformation-en-master-information-delivery-plan-midp'
        },
        {
            content: {
                title: 'plan de livraison de l’information par tâche',
                description: 'programme des conteneurs d’information et des dates de livraison, pour une équipe de travail spécifique\n\n[SOURCE : NF EN ISO 19650-2 (2018), 3.1.3.4]',
                examples: '',
                full_title: 'plan de livraison de l’information par tâche | EN : Task Information Delivery Plan (TIDP)'
            },
            id: 5316,
            cid: 1548642597823,
            slug: 'plan-de-livraison-de-linformation-par-tache-en-task-information-delivery-plan-tidp'
        },
        {
            content: {
                title: "norme d'information du projet",
                description: '[SOURCE : NF EN ISO 19650-1 & 2 (2018)]',
                examples: 'Méthodes, modes opératoires du projet pour la production d’informations; Information de référence et ressources partagées du projet\nEnsemble des règles projet régissant le travail collaboratif',
                full_title: "norme d'information du projet"
            },
            id: 6670,
            cid: 1577083653389,
            slug: 'norme-dinformation-du-projet'
        },
        {
            content: {
                title: 'task information requirements (TIR)',
                description: 'Pas de définition officielle\n\n[SOURCE : FD CEN/TR 17439 (2020)]',
                examples: "Les TIR sont des points particuliers d'études ou d'échanges sur projet\nIl est possible pour tous acteurs du projet d’introduire ses propres besoins, en complément de ceux demandés au Marché",
                full_title: 'task information requirements (TIR) | Pas de traduction officielle'
            },
            id: 6510,
            cid: 1584857702166,
            slug: 'task-information-requirements-tir-pas-de-traduction-officielle'
        },
        {
            content: {
                title: "Assistance à Maîtrise d'Ouvrage BIM (AMO BIM)",
                description: 'Pas de définition\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: '',
                full_title: "Assistance à Maîtrise d'Ouvrage BIM (AMO BIM)"
            },
            id: 5205,
            cid: 1571023333229,
            slug: 'assistance-a-maitrise-douvrage-bim-amo-bim'
        },
        {
            content: {
                title: 'Modélisation des informations de la construction (BIM)',
                description: 'méthode de travail basée sur la collaboration autour d’une maquette numérique\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: '',
                full_title: 'Modélisation des informations de la construction (BIM)'
            },
            id: 4844,
            cid: 1628887421943,
            slug: 'modelisation-des-informations-de-la-construction-bim'
        },
        {
            content: {
                title: 'BIM Management',
                description: 'Le BIM Management vise à l’organisation des méthodes et processus permettant notamment l’établissement et le suivi de la maquette numérique\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: 'Pour les projets',
                full_title: 'BIM Management'
            },
            id: 5842,
            cid: 1570996641418,
            slug: 'bim-management'
        },
        {
            content: {
                title: 'Cahier des charges BIM',
                description: "le « cahier des charges BIM » est le document élaboré par le maître d'ouvrage précisant les exigences et les objectifs des intervenants successifs du projet. Il constitue le volet BIM du programme du maître d'ouvrage ;\n\n[SOURCE : Source officielle :\nCCAG MOE & Travaux]",
                examples: '',
                full_title: 'Cahier des charges BIM'
            },
            id: 5182,
            cid: 1615412225766,
            slug: 'cahier-des-charges-bim'
        },
        {
            content: {
                title: 'Charte BIM',
                description: 'Document générique élaboré par le maître d’ouvrage traduisant sa politique en objectifs de qualité et de performances attendues du BIM pour l’ensemble de ses projets\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: '',
                full_title: 'Charte BIM'
            },
            id: 5920,
            cid: 1588943951520,
            slug: 'charte-bim'
        },
        {
            content: {
                title: 'Convention BIM',
                description: "la convention BIM de l'opération est le document décrivant les méthodes organisationnelles, de représentation graphique, la gestion et le transfert des données du projet, ainsi que les processus, les modèles, les utilisations, le rôle de chaque intervenant et l'environnement collaboratif du BIM. A chaque étape du cycle de vie du projet, la convention évolue et s'adapte aux nouveaux acteurs, à des usages nouveaux ou à des nécessités du projet.\n\n[SOURCE : Source officielle :\nCCAG MOE & Travaux]",
                examples: 'En réponse au cahier des charges BIM.\nCette notion de Convention BIM n’est pas reprise telle que dans la série de normes.\n La norme définit explicitement ce que contient le Plan d’Exécution BIM (BEP), ainsi qu’une liste de document à thèmes, à établir , pour la gestion de l’information.\n L’ensemble de ces pièces forme « la Convention BIM »',
                full_title: 'Convention BIM'
            },
            id: 5292,
            cid: 1623959773407,
            slug: 'convention-bim'
        },
        {
            content: {
                title: 'Degrés de maturité du BIM',
                description: 'BIM niveau 1, BIM Niveau 2, BIM niveau 3\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: 'Cette notion de BIM « à niveaux » a disparu et n’est pas reprise dans la série de normes, et ne s’adresse sur aucun nouveau référentiel standardisé.',
                full_title: 'Degrés de maturité du BIM'
            },
            id: 5625,
            cid: 1555809373426,
            slug: 'degres-de-maturite-du-bim'
        },
        {
            content: {
                title: 'Maquette numérique',
                description: 'on appelle maquette numérique ou Building Information Model une représentation numérique tridimensionnelle des caractéristiques fonctionnelles et/ou physiques de l’ouvrage.\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: "La série de normes ne parle pas de maquette numérique, trop strictement associé à la 3D. Les concepts de conteneur d’information et par extension modèle d'information plus large car englobant les schémas, des fiches techniques , des caractéristiques non dimensionnelles, plus conforme aux réalités des projets … ont été préféré",
                full_title: 'Maquette numérique'
            },
            id: 5223,
            cid: 1598042578343,
            slug: 'maquette-numerique'
        },
        {
            content: {
                title: 'Niveau de définition, de détail, de développement',
                description: 'Niveau nécessaire d’informations liées aux objets en termes géométriques, analytiques, alphanumériques et relationnels. Il existe différents niveaux de définition/détail/ développement accordés par exemple suivant les phases de projet de la Loi MOP.\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: '',
                full_title: 'Niveau de définition, de détail, de développement'
            },
            id: 5154,
            cid: 1593354655589,
            slug: 'niveau-de-definition-de-detail-de-developpement'
        },
        {
            content: {
                title: 'Protocole BIM',
                description: 'protocoles d’échanges entre tous les contributeurs du BIM\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: '',
                full_title: 'Protocole BIM'
            },
            id: 5585,
            cid: 1621878031956,
            slug: 'protocole-bim'
        },
        {
            content: {
                title: 'Usage BIM',
                description: 'Explicitation de processus intégrant des pratiques BIM, c’est-à-dire la description d’un processus concret, tel qu’il sera mis en œuvre sur un projet\n\n[SOURCE : Source officielle/Guide MIQCP (2016)]',
                examples: 'La norme NF EN ISO 19650 ne parle pas de cas d’usages.',
                full_title: 'Usage BIM'
            },
            id: 6441,
            cid: 1578072618986,
            slug: 'usage-bim'
        },
        {
            content: {
                title: 'Objectif(s) BIM',
                description: 'Pas de définition\n\n[SOURCE : Pas de source officielle]',
                examples: "- Gérer l'affectation des surfaces utiles\nAnalyser le cycle de vie des équipements\n- Spécifier des attentes acoustiques particulières des locaux\nDéterminer les effectifs d'accueil\n- Gérer la co-activité du chantier\nSpécifications de fabrication",
                full_title: 'Objectif(s) BIM'
            },
            id: 6578,
            cid: 1590964249527,
            slug: 'objectifs-bim'
        },
        {
            content: {
                title: 'Plateforme collaborative',
                description: 'Pas de définition\n\n[SOURCE : Pas de source officielle]',
                examples: '',
                full_title: 'Plateforme collaborative'
            },
            id: 6711,
            cid: 1618458869042,
            slug: 'plateforme-collaborative'
        },
        {
            content: {
                title: 'BIM Coordination',
                description: 'Pas de définition\n\n[SOURCE : Pas de source officielle]',
                examples: '',
                full_title: 'BIM Coordination'
            },
            id: 5000,
            cid: 1606691193382,
            slug: 'bim-coordination'
        },
        {
            content: {
                title: 'RACI (table de)',
                description: 'Pas de définition\n\n[SOURCE : Pas de source officielle]',
                examples: '',
                full_title: 'RACI (table de)'
            },
            id: 5308,
            cid: 1639574251814,
            slug: 'raci-table-de'
        },
        {
            content: {
                title: "Cas d'usage",
                description: 'Pas de définition\n\n[SOURCE : Pas de source officielle]',
                examples: 'La norme NF EN ISO 19650 ne parle pas de cas d’usages.',
                full_title: "Cas d'usage"
            },
            id: 5096,
            cid: 1579556647772,
            slug: 'cas-dusage'
        }
    ]
};

export const TERMS_LIST_PAGES = [
    {
        id: 5205,
        pageId: 130,
        term: {
            content: {
                title: 'Assistance à Maîtrise d\'Ouvrage BIM',
                acronym: 'AMO BIM',
                description: '',
                example: 'Pour les actifs (gestion ultérieure des biens immobiliers)',
                note: '',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Fonctions de gestion de l\'information',
                            acronym: '',
                            flag: false,
                            description: '',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 & 2 (2018)',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 5182,
        pageId: 131,
        term: {
            content: {
                title: 'Cahier des charges BIM',
                acronym: '',
                description: '<div>document précisant pour le projet les exigences et objectifs des intervenants successifs du projet, incluant ceux de la charte BIM du maître d’ouvrage. Il constitue le volet BIM du programme (<b>CCAG Maîtrise d’oeuvre, CCAG travaux)</b></div>',
                example: '',
                note: 'Les EIR exposent les aspects administratifs, commerciaux et techniques de la production d’informations sur les projets.',
                label: {
                    text: 'CCAG MOE & Travaux (2021)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Exigences d’échange d’informations',
                            acronym: 'EIR',
                            flag: false,
                            description: 'Exigences d’information en lien avec une désignation',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 (2018), 3.3.6',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 6578,
        pageId: 132,
        term: {
            content: {
                title: 'Objectif(s) BIM',
                acronym: '',
                description: '',
                example: '<div>Pour l’exploitation<ul><li>Gérer l\'affectation des surfaces utiles</li><li>Analyser le cycle de vie des équipements</li>' +
                    '</ul>Pour le projet<ul><li>Spécifier des attentes acoustiques particulières des locaux</li><li>Déterminer les effectifs d\'accueil</li></ul>' +
                    'Pour les tâches<ul><li>Gérer la co-activité du chantier</li><li>Spécifications de fabrication</li></ul></div>',
                note: 'Pour aller plus loin se référer à la partie “POURQUOI?”',
                label: {
                    text: 'Pas de source officielle',
                    color: '#FFFFFF',
                    backgroundColor: '#F178B6'
                }
            },
            relations: {
                linked_rs: {
                    title: 'Cas d’usgae',
                    acronym: '',
                    flag: true,
                    description: '',
                    example: 'Simuler la mise en place des outils pour assurer la sécurité des personnes sur chantier ',
                    label: {
                        text: 'Pas de source officielle',
                        color: '#FFFFFF',
                        backgroundColor: '#F178B6'
                    }
                },
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Exigences d’information d’actif',
                            acronym: 'AIR',
                            flag: false,
                            description: 'exigences d’information du projet',
                            example: 'Les AIR sont portées par la gestion ultérieure des bien immobiliers',
                            label: {
                                text: 'NF EN ISO 19650-1 (2018), 3.3.5',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        },
                        {
                            title: 'Exigences d’information du projet',
                            acronym: 'PIR',
                            flag: false,
                            description: 'exigences d’information d’actif',
                            example: 'Les PIR sont spécifiques à l\'ouvrage',
                            label: {
                                text: 'NF EN ISO 19650-1 (2018), 3.3.4',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        },
                        {
                            title: 'Exigences d’information par tâche',
                            acronym: 'TIR',
                            flag: false,
                            description: 'information requirement in relation to task team (pas de traduction)',
                            example: 'Les TIR sont des points particuliers d\'études ou d\'échanges sur projet\n' +
                                'Il est possible pour tous acteurs du projet d’introduire ses propres besoins, en complément de ceux demandés au Marché',
                            label: {
                                text: 'CEN/TR 17439 (2020)',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 6441,
        pageId: 133,
        term: {
            content: {
                title: 'Usage BIM ',
                acronym: '',
                description: 'Explicitation de processus intégrant des pratiques BIM, c’est-à-dire la description d’un processus concret, tel qu’il sera mis en œuvre sur un projet',
                example: '',
                note: 'Pas de correspondance, la norme EN ISO 19650 ne parle pas d’usages BIM.',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: {
                    title: 'Cas d’usgae',
                    acronym: '',
                    flag: true,
                    description: '',
                    example: 'Simuler la mise en place des outils pour assurer la sécurité des personnes sur chantier ',
                    label: {
                        text: 'Pas de source officielle',
                        color: '#FFFFFF',
                        backgroundColor: '#F178B6'
                    }
                },
                linked_rs_notes: {
                    list: []
                }
            }
        }
    },
    {
        id: 5842,
        pageId: 134,
        term: {
            content: {
                title: 'BIM Management',
                acronym: '',
                description: 'Le BIM Management vise à l’organisation des méthodes et processus permettant notamment l’établissement et le suivi de la maquette numérique',
                example: 'Pour les actifs (gestion ultérieure des biens immobiliers)',
                note: '',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Fonctions de gestion de l\'information',
                            acronym: '',
                            flag: false,
                            description: '',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 & 2 (2018)',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 5000,
        pageId: 135,
        term: {
            content: {
                title: 'BIM Coordination',
                acronym: '',
                description: '',
                example: 'Pour les actifs (gestion ultérieure des biens immobiliers)',
                note: '',
                label: {
                    text: 'Pas de source officielle',
                    color: '#FFFFFF',
                    backgroundColor: '#F178B6'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Fonctions de gestion de l\'information',
                            acronym: '',
                            flag: false,
                            description: '',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 & 2 (2018)',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 5292,
        pageId: 136,
        term: {
            content: {
                title: 'Convention BIM ',
                acronym: '',
                description: 'la convention BIM de l\'opération est le document décrivant les méthodes organisationnelles, de représentation graphique, la gestion et le transfert des données du projet, ainsi que les processus, les modèles, les utilisations, le rôle de chaque intervenant et l\'environnement collaboratif du BIM. A chaque étape du cycle de vie du projet, la convention évolue et s\'adapte aux nouveaux acteurs, à des usages nouveaux ou à des nécessités du projet.',
                example: '',
                note: 'Cette notion de Convention BIM n’est pas reprise telle que dans la série de normes.\n' +
                    'La norme définit explicitement ce que contient le Plan d’Exécution BIM (BEP), ainsi qu’une liste de document à thèmes, à établir, pour la gestion de l’information.\n' +
                    'L’ensemble de ces pièces forme « la Convention BIM »',
                label: {
                    text: 'CCAG MOE & Travaux (2021)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: []
                }
            }
        }
    },
    {
        id: 5920,
        pageId: 137,
        term: {
            content: {
                title: 'Charte BIM',
                acronym: '',
                description: 'Document générique élaboré par le maître d’ouvrage traduisant sa politique en objectifs de qualité et de performances attendues du BIM pour l’ensemble de ses projets',
                example: '',
                note: 'Les EIR exposent les aspects administratifs, commerciaux et techniques de la production d’informations sur les projets.',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Exigences d’information de l’organisation',
                            acronym: 'OIR',
                            flag: false,
                            description: 'exigences d’information de l’organisation',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 (2018), 3.3.3',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 5154,
        pageId: 138,
        term: {
            content: {
                title: 'Niveau de définition, \n' +
                    'de détail, de développement',
                acronym: '',
                description: 'Niveau nécessaire d’informations liées aux objets en termes géométriques, analytiques, alphanumériques et relationnels. Il existe différents niveaux de définition/détail/ développement accordés par exemple suivant les phases de projet de la Loi MOP.',
                example: '',
                note: 'Un vrai bouleversement dans nos pratiques.\n' +
                    ' La problématique de besoin en géométrie, besoin en information, besoin en documentation, est traité par la norme européenne NF EN 17412-1',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Niveau du besoin d’information',
                            acronym: 'LOIN',
                            flag: false,
                            description: 'cadre qui définit l’étendue et la granularité de l’information (3.3.1)\n' +
                                '\n' +
                                'Note : La définition du niveau du besoin d’information vise notamment à éviter la livraison d’une quantité d’informations trop importante.',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 (2018), 3.3.16',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 5223,
        pageId: 139,
        term: {
            content: {
                title: 'Maquette numérique',
                acronym: '',
                description: 'on appelle maquette numérique ou Building Information Model une représentation numérique tridimensionnelle des caractéristiques fonctionnelles et/ou physiques de l’ouvrage.',
                example: '',
                note: 'Pas de correspondance, la série de normes ne parle pas de maquette numérique, trop strictement associé à la 3D. Le concept de conteneur d’information plus large car englobant les schémas, des fiches techniques , des caractéristiques non dimensionnelles, plus conforme aux réalités des projets … a été préféré',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: []
                }
            }
        }
    },
    {
        id: 5625,
        pageId: 140,
        term: {
            content: {
                title: 'Degrés de maturité du BIM',
                acronym: '',
                description: '',
                example: 'BIM niveau 1, BIM Niveau 2, BIM niveau 3',
                note: 'Pas de correspondance, cette notion de BIM « à niveaux » a disparu et n’est pas reprise dans la série de normes, et ne s’adresse sur aucun nouveau référentiel standardisé.',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: []
                }
            }
        }
    },
    {
        id: 5585,
        pageId: 141,
        term: {
            content: {
                title: 'Protocole BIM ',
                acronym: '',
                description: 'protocoles d’échanges entre tous les contributeurs du BIM',
                example: '',
                note: 'Ensemble des règles projet régissant le travail collaboratif',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Norme d’information du projet ',
                            acronym: '',
                            flag: false,
                            description: 'Méthodes, modes opératoires du projet pour la production d’informations; Information de référence et ressources partagées du projet',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 & 2 (2018)',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 6711,
        pageId: 142,
        term: {
            content: {
                title: 'Plateforme collaborative',
                acronym: '',
                description: '',
                example: '',
                note: '',
                label: {
                    text: 'Pas de source officielle',
                    color: '#FFFFFF',
                    backgroundColor: '#F178B6'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Environnement de données commun',
                            acronym: 'CDE',
                            flag: false,
                            description: 'Source convenue d’information (3.3.1) sur un projet ou un actif (3.2.8) donné, utilisée pour collecter, gérer et diffuser chaque conteneur d’information (3.3.12) par le biais d’un processus géréLe flux de travaux d’un CDE décrit les processus à utiliser et une solution CDE peut offrir la technologie de prise en charge de ces processus.',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 (2018), 3.3.15',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 5308,
        pageId: 143,
        term: {
            content: {
                title: 'RACI',
                acronym: 'table de',
                description: '',
                example: '"Qui fait quoi?"',
                note: '',
                label: {
                    text: 'Pas de source officielle',
                    color: '#FFFFFF',
                    backgroundColor: '#F178B6'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Matrice des reponsabilités',
                            acronym: '',
                            flag: false,
                            description: '"organigramme décrivant la participation, par différentes fonctions, à l’exécution des tâches ou à la fourniture de livrables\n' +
                                '\n' +
                                'Note : Une matrice des responsabilités peut indiquer une responsabilité, une consultation et une information, ainsi que l’obligation d’exécuter les tâches ou de fournir les livrables.\n' +
                                '\n' +
                                '[SOURCE : ISO 37500:2014, 3.16, modifiée — le mot «rôles» a été remplacé par «fonctions»; les mots «pour un accord d’externalisation» ont été supprimés; la Note 1 à l’article a été ajoutée.]"',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 (2018), 3.1.1',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 4844,
        pageId: 144,
        term: {
            content: {
                title: 'Modélisation des informations \n' +
                    'de la construction',
                acronym: 'BIM',
                description: 'méthode de travail basée sur la collaboration autour d’une maquette numérique',
                example: '',
                note: 'La définition du BIM est normalisée, il peut y être fait référence dans les pièces de nos Marchés, sans ambiguïté.',
                label: {
                    text: 'Guide MIQCP (2016)',
                    color: '#FFFFFF',
                    backgroundColor: '#81BE00'
                }
            },
            relations: {
                linked_rs: {
                    title: 'Modélisation des informations de la construction',
                    acronym: 'BIM',
                    flag: true,
                    description: 'le BIM (“Modélisation d’informations de la construction”) est un outil de représentation numérique partagée permettant de faciliter les processus de conception, de construction et d’exploitation et de former une base fiable permettant les prises de décision',
                    example: '',
                    label: {
                        text: 'CCAG MOE & Travaux (2021)',
                        color: '#FFFFFF',
                        backgroundColor: '#81BE00'
                    }
                },
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Modélisation des informations de la construction',
                            acronym: 'BIM',
                            flag: false,
                            description: 'Utilisation d’une représentation numérique partagée d’un actif (3.2.8) bâti pour faciliter les processus de conception, de construction et d’exploitation et former une base fiable permettant les prises de décision\n' +
                                '\n' +
                                'Note : Les actifs bâtis comprennent, mais sans s’y limiter, les bâtiments, les ponts, les routes, les usines.\n' +
                                '\n' +
                                '[ISO 29481‑1:2016, 3.2, modifiée — Le mot «objet» a été remplacé par «actif»; les mots «comprenant bâtiments, ponts, routes, usines, etc.» ont été supprimés; la Note 1 à l’article d’origine a été remplacée par une nouvelle.]"',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-1 (2018), 3.3.14',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    },
    {
        id: 6151,
        pageId: 145,
        term: {
            content: {
                title: 'Plan d’exécution BIM',
                acronym: 'BEP',
                description: '',
                example: '',
                note: 'Le Plan d’Exécution BIM (BEP) compte 7 chapitres, il est une pièce portée au Marché.',
                label: {
                    text: 'Pas de source officielle',
                    color: '#FFFFFF',
                    backgroundColor: '#F178B6'
                }
            },
            relations: {
                linked_rs: null,
                linked_rs_notes: {
                    list: [
                        {
                            title: 'Plan d’exécution BIM',
                            acronym: 'BEP',
                            flag: false,
                            description: 'plan décrivant la manière dont l’équipe de production traitera les aspects de la désignation relatifs à la gestion de l’information, en réponse aux EIR',
                            example: '',
                            label: {
                                text: 'NF EN ISO 19650-2 (2018), 3.1.3.1',
                                color: '#035A90',
                                backgroundColor: '#FFD004'
                            }
                        }
                    ]
                }
            }
        }
    }
];

export const TERMS_LIST_EN_FRANCE = [
    { id: 5205, pageId: 130, title: 'AMO BIM' },
    { id: 5182, pageId: 131, title: 'Cahier des charges BIM' },
    { id: 6578, pageId: 132, title: 'Objectif BIM' },
    { id: 6441, pageId: 133, title: 'Usage BIM' },
    { id: 5842, pageId: 134, title: 'BIM Management' },
    { id: 5000, pageId: 135, title: 'BIM Coordination' },
    { id: 5292, pageId: 136, title: 'Convention BIM' },
    { id: 5920, pageId: 137, title: 'Charte BIM' },
    { id: 5154, pageId: 138, title: 'Niveau de définition, de détail, de développement' },
    { id: 5223, pageId: 139, title: 'Maquette numérique' },
    { id: 5625, pageId: 140, title: 'Degrés de maturité du BIM' },
    { id: 5585, pageId: 141, title: 'Protocole BIM ' },
    { id: 6711, pageId: 142, title: 'Plateforme collaborative' },
    { id: 5308, pageId: 143, title: 'RACI (table de)' },
    { id: 4844, pageId: 144, title: 'Modélisation des informations de la construction (BIM)' },
    { id: 6151, pageId: 145, title: 'Plan d’exécution BIM (BEP)' }
];
