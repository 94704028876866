import React, { SetStateAction } from 'react';
import { useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema12View1 from '../../components/schemas/schema12/Schema12View1';
import Schema12View2 from '../../components/schemas/schema12/Schema12View2';
import Schema12View3 from '../../components/schemas/schema12/Schema12View3';
import Schema12View4 from '../../components/schemas/schema12/Schema12View4';
import Schema12View5 from '../../components/schemas/schema12/Schema12View5';
import Schema12View6 from '../../components/schemas/schema12/Schema12View6';
import Schema12View7 from '../../components/schemas/schema12/Schema12View7';
import Schema12View8 from '../../components/schemas/schema12/Schema12View8';
import Schema12View9 from '../../components/schemas/schema12/Schema12View9';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema12Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    const handleStepClick = (index: number | SetStateAction<number>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        handleSetView(index)();
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema12.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema12.introSubTitle' })}
                            textBlockFull={<div>
                                <p>
                                    {intl.formatMessage({ id: 'schema12.intro1TextBlock1' })}
                                    <b>{intl.formatMessage({ id: 'schema12.intro1TextBlock2' })}</b>
                                    {intl.formatMessage({ id: 'schema12.intro1TextBlock3' })}
                                </p>
                                <p>
                                    {intl.formatMessage({ id: 'schema12.intro1TextBlock4' })}
                                    <b>{intl.formatMessage({ id: 'schema12.intro1TextBlock5' })}</b>
                                    {intl.formatMessage({ id: 'schema12.intro1TextBlock6' })}
                                </p>
                            </div>}
                            mainTextBig={true}
                        />
                    }
                    {
                        tab === 1 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema12.introTitle2' })}
                            subTitle={intl.formatMessage({ id: 'schema12.introSubTitle2' })}
                        />
                    }
                    {
                        tab === 2 && <Schema12View1 handleStepClick={handleStepClick}/>
                    }
                    {
                        tab === 3 && <Schema12View2 handleStepClick={handleStepClick}/>
                    }
                    {
                        tab === 4 && <Schema12View3 handleStepClick={handleStepClick}/>
                    }
                    {
                        tab === 5 && <Schema12View4 handleStepClick={handleStepClick}/>
                    }
                    {
                        tab === 6 && <Schema12View5 handleStepClick={handleStepClick}/>
                    }
                    {
                        tab === 7 && <Schema12View6 handleStepClick={handleStepClick}/>
                    }
                    {
                        tab === 8 && <Schema12View7 handleStepClick={handleStepClick}/>
                    }
                    {
                        tab === 9 && <Schema12View8 handleStepClick={handleStepClick}/>
                    }
                    {
                        tab === 10 && <Schema12View9 handleStepClick={handleStepClick}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema12Page;
