import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { commonIcons, schema1PageIcons } from '../../../../assets/icons/svg';
import figure2 from '../../schema1/images/figure2.png';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';
// COMPONENTS
import ImagePopup from '../../../../common/components/ImagePopup';
import Tooltip from '../Tooltip';
import { TOOLTIP_1, TOOLTIP_2 } from '../tooltipsData';
import Note from '../../../../common/components/Note';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';

const Schema1View1 = () => {
    const intl = useIntl();

    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };

    const screenWidth = useWindowSize().width;

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema1.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema1.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 5.1, Figure 2
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoBlock}>
                                    <div className={styles.header}>
                                        <FormattedMessage id='schema1.block1Title' />
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block1Text1' />
                                            </div>
                                            <Tooltip
                                                texts={<ul>{TOOLTIP_1.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                title={<p>{TOOLTIP_1.title}</p>}
                                                width={TOOLTIP_1.width}
                                            />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.midBlock}>
                                            <FormattedMessage id='schema1.midBlockText1' />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block1Text2' />
                                            </div>
                                            <Tooltip
                                                texts={<ul>{TOOLTIP_2.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                title={<p>{TOOLTIP_2.title}</p>}
                                                width={TOOLTIP_2.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteTitle3' /></p><ul><li><FormattedMessage id='note.noteText3' /></li></ul></div>} />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema1.figure2Title' })}
                    label='NF EN ISO 19650-1:2019, 5.1, Figure 2'
                    caption={intl.formatMessage({ id: 'schema1.figure2' })}
                    image={figure2}
                    id={113}
                />
            }
        </div>
    );
};

export default Schema1View1;
