import React, { ReactElement, useState } from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';

type Data = {
    title: string,
    subTitle?: string,
    tabs: {
        section1Heading?: string | ReactElement<any, any>,
        textBlock1?: string | ReactElement<any, any>,
        textBlock2?: string | ReactElement<any, any>,
        textBlockFull?: string | ReactElement<any, any>,
        textBlockFull2?: string | ReactElement<any, any>,
        textBlocksLine?: boolean,
        examples?: { src: string, title?: string | ReactElement<any, any> }[],
        examplesTitle?: string,
        bottomTitleBlue?: boolean,
        bottomTitleCenter?: boolean,
        mainTextBig?: boolean,
        title: string
    }[]
};

const SchemaIntro = ({
    title,
    subTitle,
    tabs
}: Data) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleSetTab = (index: number) => () => {
        setActiveTab(index);
    };

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.title}>{title}</div>
                    {
                        subTitle && <div className={styles.subTitle}>{subTitle}</div>
                    }
                </div>
                <div className={styles.tabsContainer}>
                    <div className={styles.tabs}>{
                        tabs.map((tab, i) => (
                            <div className={classNames(styles.tab, {
                                [styles.active]: activeTab === i
                            })} key={i} onClick={handleSetTab(i)}>
                                {tab.title}
                            </div>
                        ))
                    }</div>
                </div>
                <div className={styles.tabsContent}>
                    {
                        tabs.map((tab, i) => (
                            <div className={classNames(styles.bottom, {
                                [styles.active]: activeTab === i
                            })} key={i}>
                                {
                                    tab.section1Heading && <div className={classNames(styles.section1Heading, {
                                        [styles.bottomTitleBlue]: tab.bottomTitleBlue,
                                        [styles.bottomTitleCenter]: tab.bottomTitleCenter
                                    })}>{tab.section1Heading}</div>
                                }
                                {
                                    !tab.textBlockFull && (tab.textBlock1 || tab.textBlock2) &&
                                    <div className={classNames(styles.blocks, {
                                        [styles.withBorder]: tab.textBlocksLine
                                    })}>
                                        <div className={classNames(styles.block, {
                                            [styles.mainTextBig]: tab.mainTextBig
                                        })}>
                                            {
                                                tab.textBlock1 && <div className={styles.text}>{tab.textBlock1}</div>
                                            }
                                        </div>
                                        <div className={classNames(styles.block, {
                                            [styles.mainTextBig]: tab.mainTextBig
                                        })}>
                                            {
                                                tab.textBlock2 && <div className={styles.text}>{tab.textBlock2}</div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    tab.textBlockFull &&
                                    <div className={classNames({
                                        [styles.mainTextBig]: tab.mainTextBig
                                    })}>
                                        <div className={styles.blockFull}>{tab.textBlockFull}</div>
                                        {
                                            tab.textBlockFull2 &&
                                            <div className={classNames(styles.blockFull, styles.blockFullMarginTop)}>{tab.textBlockFull2}</div>
                                        }
                                    </div>
                                }
                                {
                                    (tab.examples?.length || tab.examplesTitle) &&
                                    <div className={styles.examples} >
                                        {
                                            tab.examplesTitle && <div className={styles.examplesTitle}>{tab.examplesTitle}</div>
                                        }
                                        {
                                            tab.examples?.length && tab.examples?.length === 1 &&
                                            <div className={styles.examplesSingle}>
                                                <div className={styles.example}>
                                                    <img src={tab.examples[0].src} alt='' className={styles.exampleImage}/>
                                                </div>
                                            </div>
                                        }
                                        {
                                            tab.examples?.length && tab.examples?.length > 1 &&
                                            <div className={styles.examplesMultiple}>
                                                <div>
                                                    {
                                                        tab.examples?.map((item, i) => (
                                                            <div key={i} className={styles.exampleBlock}>
                                                                {item.title
                                                                    ? <div className={styles.exampleTitle}>{item.title}</div>
                                                                    : <div className={styles.marker}>{i + 1}.</div>
                                                                }
                                                                <div className={styles.example}>
                                                                    <img src={item.src} alt='' className={styles.exampleImage}/>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default SchemaIntro;
