import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { commonIcons } from '../../assets/icons/svg';
// UTILS
import { getComparator } from './utils/sorting';
// ACTIONS
import setTermPopup from '../../store/application/actions/setTermPopup';
// COMPONENTS
import Tooltip from './Tooltip';

type DATA = {
    terms: {
        content: {
            title: string,
            full_title: string,
            description: string,
            examples: string
        },
        id: number,
        pageId: number | undefined
    }[]
};

const TermsContainer = ({ terms }: DATA) => {
    const popupRef = useRef<any>(null);
    const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = React.useState('col1');
    const dispatch = useDispatch();

    const handleRequestSort = (property: string) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleOpenTermPopup = (id: number | null) => () => {
        if (id) {
            dispatch(setTermPopup(id));
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <div className={styles.root} ref={popupRef}>
            <div className={styles.header}>
                <div className={styles.mobileSort}>
                    <FormattedMessage id='terms.sortBy' />
                </div>
                <div className={styles.col1} onClick={handleRequestSort('col1')}>
                    <FormattedMessage id='terms.col1' />
                    <div className={styles.sortingIcon}>{commonIcons.sort}</div>
                </div>
                <div className={styles.col2}><FormattedMessage id='terms.col2' /></div>
                <div className={styles.col3}><FormattedMessage id='terms.col3' /></div>
                <div className={styles.col4} onClick={handleRequestSort('col4')}>
                    <FormattedMessage id='terms.col4' />
                    <div className={styles.sortingIcon}>{commonIcons.sort}</div>
                </div>
            </div>
            <div className={styles.contentWrapper}>
                {
                    terms.slice().sort(getComparator(order, orderBy)).map((item, i) => (
                        <div key={i} className={styles.termContainer}>
                            <div className={styles.term}>
                                <div className={styles.col1}>
                                    <div className={styles.colTitleMobile}><FormattedMessage id='terms.col1' />:</div>
                                    {item.content.full_title}
                                </div>
                                <div className={styles.col2}>
                                    <div className={styles.colTitleMobile}><FormattedMessage id='terms.col2' />:</div>
                                    {item.content.description}
                                </div>
                                <div className={styles.col3}>
                                    <div className={styles.colTitleMobile}><FormattedMessage id='terms.col3' />:</div>
                                    {item.content.examples}
                                </div>
                                <div className={styles.col4}>
                                    <div className={styles.colTitleMobile}><FormattedMessage id='terms.col4' />:</div>
                                    {
                                        !!item.pageId &&
                                <div className={styles.pageLink} onClick={handleOpenTermPopup(item.pageId)}>{item.pageId}</div>
                                    }
                                </div>
                            </div>
                            {
                                (i + 1) % 5 === 0 && <div className={styles.topButton}>
                                    <div className={styles.button} onClick={handleScrollToTop}>
                                        <FormattedMessage id='tooltip.text90' />
                                        <div className={styles.iconTop}>
                                            {commonIcons.arrowTop}
                                            <Tooltip content={<div><FormattedMessage id='tooltip.text90' /></div>}/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default TermsContainer;
