/* eslint-disable max-len */

export default {
    'header.link1': 'Plan',
    'header.link2': 'Pourquoi ?',
    'header.link3': 'Comment ?',
    'header.link4': 'Quoi ?',
    'header.link5': 'Index',
    'header.link6': 'En Pratique',
    'header.link7': 'En France',
    'header.searchPlaceholder': 'Tapez ici pour chercher',
    'footer.about': 'À propos',
    'footer.conditions': 'Conditions d\'utilisation',
    'footer.contact': 'contact',
    'footer.pageId': 'Page ID',
    'aboutPopup.title': 'À propos',
    'aboutPopup.text1': 'Cet outil a été réalisé et vous est proposé par le ',
    'aboutPopup.text2': 'plan BIM 2022',
    'aboutPopup.text3': 'L\'application est sous licence GPLv3.',
    'aboutPopup.text4': 'Le contenu de l’application est sous licence CC BY-NC-SA 4.0.',
    'aboutPopup.text5': 'Pour toutes demandes, merci de contacter le ',
    'aboutPopup.text6': 'Pourquoi ce projet ?',
    'aboutPopup.text7': 'Les normes BIM ont été développées dans le cadre des instances normatives par les acteurs de notre secteur, ce projet a pour objectif de les diffuser largement et d\'aider leurs appropriations par les utilisateurs finaux.',

    'infoPopup.attention': 'Attention',
    'infoPopup.attentionText1': 'Les informations contenues dans cette application : ',
    'infoPopup.attentionText2': 'ne se substituent pas aux normes auxquelles le parcours se réfère, et; ',
    'infoPopup.attentionText3': 'sont issues d\'un travail collaboratif inter-organisations professionnelles, sous l\'égide du plan BIM 2022.',
    'infoPopup.title': 'Conditions d\'utilisation',
    'infoPopup.text1': 'Définition du BIM [NF EN ISO 19650-1 (2018), 3.3.14] :',
    'infoPopup.text2': 'Utilisation d’une représentation numérique partagée d’un actif bâti pour faciliter les processus de conception, de construction et d’exploitation et former une base fiable permettant les prises de décision.',
    'infoPopup.text3': 'Pourquoi la 19650 ? A quoi sert-elle ? ',
    'infoPopup.text4': 'La série de normes NF EN ISO 19650 répond aux grandes questions « Pourquoi ? Comment (qui, quand, outils-méthodes) ?, Quoi, quelles informations ?», dans la gestion de l’information pour les projets de construction.',
    'infoPopup.text5': 'Le BIM au sens de l’ISO 19650 est une nouvelle approche de l’information échangée autour de nos ouvrages.',
    'infoPopup.text6': 'Cette norme : ',
    'infoPopup.text7': 'propose un processus pour délivrer les informations du projet en réponse aux exigences d\'échanges d\'information demandées',
    'infoPopup.text8': 'explore toutes les facettes de la gestion de l’information, à toutes les étapes du cycle de vie d’un projet. ',
    'infoPopup.text9': 'et fournit des recommandations pour définir un cadre de gestion de l’information, à destination de tous les acteurs.',
    'infoPopup.text10': 'La série de normes NF EN ISO 19650 est une série de textes flexibles et propose des clés pour la gestion de l’information projet avec le BIM. Cette série de normes accompagne la gestion et le management de projet. Elle ne se substitue pas au management de projet de nos opérations.',
    'navigationPopup.title': 'Navigation',
    'navigationPopup.blueLink1': 'POURQUOI ?',
    'navigationPopup.blueLink2': 'Exigences\n' +
        'et Besoins',
    'navigationPopup.blueLink3': 'Les types \n' +
        'd’exigences',
    'navigationPopup.yellowLink1': 'COMMENT ?',
    'navigationPopup.yellowLink2': 'Environnement de données commun (CDE)',
    'navigationPopup.yellowLink3': 'Qui?',
    'navigationPopup.yellowLink4': 'Les acteurs',
    'navigationPopup.yellowLink5': 'Les fonctions de gestions de l’information',
    'navigationPopup.yellowLink6': 'Organisation et processus',
    'navigationPopup.yellowLink7': 'Quand?',
    'navigationPopup.yellowLink8': 'Le déroulé',
    'navigationPopup.yellowLink9': 'Le cycle de vie de l’information',
    'navigationPopup.yellowLink10': 'Outils et \n' +
        'méthodes',
    'navigationPopup.yellowLink11': 'Plan d’exécution\n' +
        'BIM (BEP)',
    'navigationPopup.yellowLink12': 'Matrice des responsabilité, MIDP, TIDP',
    'navigationPopup.yellowLink13': 'Stratégie de \n' +
        'fédération',
    'navigationPopup.yellowLink14': 'Niveau du besoin d’information',
    'navigationPopup.yellowLink15': 'Conteneurs \n' +
        'd’information',
    'navigationPopup.yellowLink16': 'Les dictionnaires de propriétés',
    'navigationPopup.orangeLink1': 'QUOI ?',
    'navigationPopup.orangeLink2': 'Les informations à livrer  :  processus général',
    'navigationPopup.orangeLink3': 'Spécification et planification de la livraison',
    'navigationPopup.orangeLink4': 'Modèle d’information du projet (PIM) et de l’actif (AIM)',
    'navigationPopup.greenLink1': 'EN PRATIQUE',
    'navigationPopup.greenLink2': 'En opérationnel que dois-je faire ? (NF EN ISO 19650-2)',
    'navigationPopup.greenLink3': 'Gestion de l’information pendant la phase de réalisation des actifs',
    'navigationPopup.greenLink4': 'Les processus successifs de gestion de l’information',
    'navigationPopup.purpleLink1': 'EN FRANCE',
    'navigationPopup.purpleLink2': 'Les usages\n' +
        'français',
    'navigationPopup.purpleLink3': 'Qui suis-je?',
    'navigationPopup.purpleLink4': 'Contractuel',
    'navigationPopup.purpleLink5': 'Table de \n' +
        'correspondance',
    'navigationPopup.home': 'ACCUEIL',
    'mainPage.text1': 'Un corpus de normes accompagne le développement du BIM dans les opérations qui nous sont confiés. L\'application propose un parcours afin d\'éclairer et d\'expliquer ces normes.',
    'mainPage.text2': 'L\'application a pour objectif de simplifier l\'appropriation de ces normes BIM dans nos opérations, et en particluier des deux premières parties des normes ',
    'mainPage.text3': 'NF EN ISO 19650 ',
    'mainPage.text4': 'au travers d\'un parcours qui s\'articule autour de 5 grande thématiques représenté ci-dessous',
    'mainPage.link': 'A lire avant de parcourir l’application',
    'mainPage.title1': 'Je veux gérer l\'information dans le cadre d\'un processus BIM',
    'mainPage.subTitle1': '...et je veux appliquer la norme :',
    'mainPage.title2': 'Comment utiliser l\'application ?',
    'mainPage.subTitle2': 'Cette application permet trois utilisations différentes :',
    'mainPage.button1': 'POURQUOI ?',
    'mainPage.button2': 'COMMENT ?',
    'mainPage.button2_1': 'CONVENTION BIM',
    'mainPage.button3': 'QUOI ?',
    'mainPage.button4': 'EN PRATIQUE',
    'mainPage.button5': 'EN FRANCE',
    'mainPage.block1': 'Le suivi du parcours complet dans l\'application, pas à pas, en suivant la navigation proposée par les flèches disposées à droite et à gauche des pages.',
    'mainPage.block2': 'Se situer dans l’application ou accéder directement aux différentes parties ou sous-parties indépendemment du parcours, via la carte "plan de métro" accessible via le bouton “Plan”.',
    'mainPage.block3': 'La fonction recherche permet de trouver un mot clef ou un concept',
    'mainPage.plan': 'Plan',
    'mainPage.tooltip1_1': 'Pourquoi ? ',
    'mainPage.tooltip1_2': 'Les besoins \net exigences.',
    'mainPage.tooltip2_1': 'Comment ? ',
    'mainPage.tooltip2_2': 'la boîte à \noutils à disposition.',
    'mainPage.tooltip3_1': 'Quoi ? ',
    'mainPage.tooltip3_2': 'Quelles \nfinalitées ?',
    'mainPage.tooltip4_1': 'En pratique ',
    'mainPage.tooltip4_2': ': NF EN ISO \n19650-2, la mise en \noeuvre sur les \nopérations en phases \nde conception et \nréalisation.',
    'mainPage.tooltip5_1': 'En France ',
    'mainPage.tooltip5_2': ': Quelles \ncorrepondances avec \nles pratiques \nfrançaises en vigueur ?',
    'pourquoiPage.title': 'POURQUOI ?',
    'pourquoiPage.subTitle1': 'Décrire les ',
    'pourquoiPage.subTitle2': 'exigences',
    'pourquoiPage.subTitle3': ', les ',
    'pourquoiPage.subTitle4': 'contraintes',
    'pourquoiPage.subTitle5': 'et/ou les ',
    'pourquoiPage.subTitle6': 'besoins',
    'pourquoiPage.subTitle7': ' pour les informations à produire',
    'pourquoiPage.button1': 'Exigences & Besoins',
    'pourquoiPage.button2': "Types D'exigences",
    'pourquoiPage.button2_1': 'Cahier des charges BIM',
    'pourquoiPage.button3': 'Exemples EIR',
    'commentPage.title': 'COMMENT ?',
    'commentPage.subTitle': 'La boîte à outils',
    'commentPage.button1': 'COMMENT',
    'commentPage.button2': 'Environnement de données commun',
    'commentPage.button3': 'Organisation & processus',
    'commentPage.button3_1': 'Qui ?',
    'commentPage.button3_1_1': 'Les acteurs',
    'commentPage.button3_1_2': 'Les fonctions de gestions de l’information',
    'commentPage.button3_2': 'Quand ?',
    'commentPage.button3_2_1': 'Le déroulé',
    'commentPage.button3_2_2': 'Le cycle de vie de l’information',
    'commentPage.button4': 'Outils et méthodes',
    'commentPage.button4_1': 'Plan d’exécution BIM (BEP)',
    'commentPage.button4_2': 'Matrice des\n' +
        'responsabilité, MIDP, TIDP',
    'commentPage.button4_3': 'Stratégie de fédération',
    'commentPage.button4_4': 'Conteneurs d’information',
    'commentPage.button4_5': 'Les dictionnaires de propriétés',
    'commentPage.button4_6': 'Niveau du besoin d’information',
    'quoiPage.title': 'QUOI ?',
    'quoiPage.subTitle': 'Quelles finalitées : les informations à livrer',
    'quoiPage.button1': 'processus général',
    'quoiPage.button2': 'spécification et planification de la livraison',
    'quoiPage.button3': 'Modèle d’information du projet (PIM) et de l’actif (AIM)',
    'enPratiquePage.title': 'EN PRATIQUE',
    'enPratiquePage.subTitle': 'NF EN ISO 19650-2, la mise en oeuvre sur les opérations en phases de conception et réalisation',
    'enPratiquePage.button1': 'En opérationnel \n' +
        'que dois-je faire ? \n' +
        '(NF EN ISO 19650-2)',
    'enPratiquePage.button2': 'Gestion de l’information \n' +
        'pendant la phase \n' +
        'de réalisation des actifs',
    'enPratiquePage.button3': 'Les processus successifs \n' +
        'de gestion de l’information',
    'enFrancePage.title': 'EN FRANCE',
    'enFrancePage.button1': 'Les usages Français',
    'enFrancePage.button2': 'Qui suis‑je?',
    'enFrancePage.button3': 'Contractuel',
    'enFrancePage.button4': 'Table de correspondance',
    'schema1.introTitle': 'Décrire les exigences / contraintes et les besoins',
    'schema1.introSubTitle': 'Pour les informations à produire...',
    'schema1.intro1Section1Heading': 'Le concept d’exigence est fondamental.',
    'schema1.intro1TextBlock1_1': 'Celui qui veut faire intervenir un tiers sur ou pour un ouvrage doit commencer par écrire et décrire ses souhaits et ce qu’il veut absolument : en un mot ce qu’il exige, d’où le mot d’exigence.',
    'schema1.intro1TextBlock1_2': 'Par exemple : une règlementation technique, des quotas et des répartitions de surfaces, ou une forme de contrat (comme un Partenariat Public Privé) ou autre.',
    'schema1.intro1TextBlock2_1': 'Il faut définir l’ambition : le but du BIM',
    'schema1.intro1TextBlock2_2': 'Le texte de la norme parle d’exigences depuis les macro-définitions des politiques d’entreprises/organisations jusqu’aux détails les plus fins comme la couleur d’un équipement',
    'schema1.intro1RedNoteTitle': 'Attention',
    'schema1.intro1RedNoteText': 'Décrire ce que l’on veut est un préalable incontournable.',
    'schema1.intro1BlueNoteTitle': 'C’est quoi avec le langage courant:',
    'schema1.intro1BlueNoteText1': 'Ce que le client demande,',
    'schema1.intro1BlueNoteText2': 'ce que le Marché stipule,',
    'schema1.intro1BlueNoteText3': 'Ce que la règlementation exige,',
    'schema1.intro1BlueNoteText4': 'Ce qu’un Maitre d’œuvre prescrit …,',
    'schema1.intro1BlueNoteText5': 'Et à quoi chacun doit apporter réponses',
    'schema1.introTitle2': 'Les types d’exigences',
    'schema1.intro2Section1Heading': 'La norme considère quatres typologies d\'exigences :',
    'schema1.intro2TextBlock1_1': '1. les exigences d’information en lien avec les objectifs de l’organisation : ',
    'schema1.intro2TextBlock1_2': 'exigences d’information de l’organisation ',
    'schema1.intro2TextBlock1_3': '(OIR). L\'organisation peut-être par exemple une maîtrise d\'ouvrage.',
    'schema1.intro2TextBlock1_4': '2. exigences d’information en lien avec l’exploitation d’un actif : ',
    'schema1.intro2TextBlock1_5': 'exigences d’information d’actif ',
    'schema1.intro2TextBlock1_6': '(AIR). L\'exploitation d\'un actif couvre les champs de l\'exploitation maintenance attachée au projet.',
    'schema1.intro2TextBlock2_1': '3.exigences d’information en lien avec la livraison d’un actif : ',
    'schema1.intro2TextBlock2_2': 'exigences d’information du projet ',
    'schema1.intro2TextBlock2_3': '(PIR). Cette exigence d\'information porte sur les phases de conception et de réalisation. (Exemples : livraison du DCE, livraison du DOE et de l\'ouvrage bâti)',
    'schema1.intro2TextBlock2_4': '4. exigences d’information en lien avec instruction ayant fait l’objet d’un accord pour la fourniture d’informations relatives à des ouvrages, des produits ou des services : ',
    'schema1.intro2TextBlock2_5': 'exigences d’échange d’informations ',
    'schema1.intro2TextBlock2_6': '(EIR). Qu\'est ce que je veux qu\'on me livre, comment je veux qu\'on me le livre et tout le processus de production de l\'information permettant d\'atteindre la livraison.',
    'schema1.intro2RedNoteTitle': 'Attention',
    'schema1.intro2RedNoteText': 'Décrire ce que l’on veut est un préalable incontournable.',
    'schema1.intro2BlueNoteTitle': 'C’est quoi avec le langage courant:',
    'schema1.intro2BlueNoteText1': 'Ce que le client demande,',
    'schema1.intro2BlueNoteText2': 'ce que le Marché stipule,',
    'schema1.intro2BlueNoteText3': 'Ce que la règlementation exige,',
    'schema1.intro2BlueNoteText4': 'Ce qu’un Maitre d’œuvre prescrit …,',
    'schema1.intro2BlueNoteText5': 'Et à quoi chacun doit apporter réponses',
    'schema1.intro2Footer1': 'Definition: ',
    'schema1.intro2Footer2': 'Une exigence est, dans le domaine de l\'ingénierie, un besoin, une nécessité, une attente auquel un produit ou un service doit répondre ou une contrainte qu\'il doit satisfaire. Les exigences sont l\'expression d\'un besoin documenté sur ce qu\'un produit devrait être. (Ref wikipedia)',
    'schema1.introTitle3': 'Exemple d’exigences d’échange d’information (EIR)',
    'schema1.intro3TextBlockFull1': 'Extraits : ',
    'schema1.intro3TextBlockFull2': 'Atelier BIM Virtuel + (Plan de transition numérique du bâtiment)',
    'schema1.intro3TextBlockFull3': 'Une arborescence spatiale Une expression d’exigences techniques',
    'schema1.intro3Example1Title': '1. Atelier BIM Virtuel +',
    'schema1.intro3Example2Title': '2. Une arborescence spatiale Une expression d’exigences techniques',
    'schema1.intro3Footer': 'Footer: Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema1.introTitle4': 'QUOI?',
    'schema1.introSubTitle4': 'Les informations à livrer : AIM/PIM',
    'schema1.intro4TextBlock1': 'Le modèle ',
    'schema1.intro4TextBlock2': 'PIM',
    'schema1.intro4TextBlock3': ' vient à l\'appui de la réalisation du projet et contribue à l\'',
    'schema1.intro4TextBlock4': 'AIM',
    'schema1.intro4TextBlock5': ' afin de faciliter les activités de gestion des actifs.',
    'schema1.intro4TextBlock6': 'Il convient également de stocker le PIM afin d\'assurer un archivage à long terme du projet et à des fins d\'audit.',
    'schema1.intro4TextBlock7': 'Par exemple, le PIM peut contenir les détails géométriques du projet, l\'emplacement des équipements, les exigences de performance lors de la conception du projet, la planification, l\'évaluation des coûts et les détails des systèmes, composants et équipements installés, y compris les exigences de maintenance, pendant la construction du projet.',
    'schema1.introId1': 'Element ID',
    'schema1.introId2': 'Element ID',
    'schema1.introId3': 'Element ID',
    'schema1.introId4': 'Element ID',
    'schema1.title': 'Les types d’exigences',
    'schema1.subTitle': 'Exigence d’information de l’organisation (OIR) et Exigence d’information du projet (PIR)',
    'schema1.title2': 'Les types d’exigences',
    'schema1.subTitle2': "Exigence d’information sur l'actif (AIR) et Exigences d'échange d'informations (EIR)",
    'schema1.title3': 'Les types d’exigences',
    'schema1.subTitle3': "Exigence d’information sur l'actif (AIR) et Exigences d'échange d'informations (EIR)",
    'schema1.title4': 'Quoi ? : Quelles informations ?',
    'schema1.subTitle4': 'Informations à livrer',
    'schema1.title5': 'En France : CONTRACTUEL',
    'schema1.subTitle5': 'Subheading under schema title',
    'schema1.block1Title': "Exigences d'information des parties intéressées",
    'schema1.block1Text1': 'Exigence d’information de l’organisation\n(OIR)',
    'schema1.block1Text2': 'Exigence d’information\ndu projet\n(PIR)',
    'schema1.block2Title': "Exigences d'information de la désignation",
    'schema1.block2Text1': "Exigence d’information sur l'actif\n(AIR)",
    'schema1.block2Text2': "Exigences d'échange d'informations\n(EIR)",
    'schema1.block3Title': 'Informations\nà livrer',
    'schema1.block3Text1': "Modèle d'informations de l'actif\n(AIM)",
    'schema1.block3Text2': 'Modèle d\'informations du projet\n(PIM)',
    'schema1.midBlockText1': 'contribue à',
    'schema1.midBlockText2': 'contient',
    'schema1.midBlockText3': 'spécifie',
    'schema1.midBlockText4': 'contribue à',
    'schema1.figure2Title': 'Basé sur',
    'schema1.figure2': 'Figure 2 — Hiérarchie des exigences d\'information',
    'schema2.introTitle': 'Environnement de données commun',
    'schema2.introSubTitle': 'Plateforme collaboratif, CDE, EDC',
    'schema2.intro1TextBlock1': 'Au sens de la norme le CDE adresse à la fois le processus de gestion des informations et la solution technologique pour y satisfaire, qu’elle soit constituée par un ou plusieurs outils.\n' +
        'Il faut mettre en place la plateforme collaborative du projet. Elle supporte le processus de gestion des informations du projet. Elle est partagée par les acteurs du projet.\n' +
        'Un acteur sait à tout instant où trouver la bonne information dont il a besoin\n' +
        'Il sait où livrer le résultat de son travail pour le mettre à la disposition des autres acteurs\n' +
        'Il accède à tout moment à l’historique des modifications des informations du projet',
    'schema2.intro1TextBlock2': 'L’environnement commun de données, est la source des informations des projets ou actifs \nCet espace de travail permet la gestion collaborative et la traçabilité des informations de tous types \nIl supporte les processus de création, fédération, partage, contrôle et publication des informations créés \nIl gère des états, des révisions, et des statuts \nDes processus d’approbation et d’autorisation \npar l’attribution de métadonnées Il gère les droits d’accès \nAu sens de la norme le CDE adresse à la fois le processus de gestion des informations et la solution technologique \npour y satisfaire, qu’elle soit constituée par un ou plusieurs outils.',
    'schema2.intro1BlueNoteTitle': 'C\'est quoi, avec des mots simples',
    'schema2.intro1BlueNoteText1': 'Un outil de partage et d\'échange',
    'schema2.intro1BlueNoteText2': ' une traçabilité de l\'information',
    'schema2.introTitle2': 'Environnement de données commun',
    'schema2.introId1': 'Element ID',
    'schema2.introId2': 'Element ID',
    'schema2.title': 'Environnement de données commun',
    'schema2.subTitle': 'Plateforme collaborative, CDE, EDC',
    'schema2.title2': 'Environnement de données commun',
    'schema2.subTitle2': 'Plateforme collaborative, CDE, EDC',
    'schema2.title3': 'Environnement de données commun',
    'schema2.subTitle3': 'Plateforme collaborative, CDE, EDC',
    'schema2.title4': 'Environnement de données commun',
    'schema2.subTitle4': 'Plateforme collaborative, CDE, EDC',
    'schema2.title5': 'Environnement de données commun',
    'schema2.subTitle5': 'Plateforme collaborative, CDE, EDC',
    'schema2.title6': 'Les informations à livrer : processus général',
    'schema2.subTitle6': 'Environnement de données commun (EDC, CDE, plateforme collaborative)',
    'schema2.block1Title': 'PARTAGÉ',
    'schema2.block2Title': 'TRAVAIL EN COURS',
    'schema2.block3Title': 'PUBLIÉ',
    'schema2.block4Title': 'ARCHIVÉ',
    'schema2.cardInfo1':
        "Information approuvée pour être partagée avec la partie désignante ou avec d'autres groupes de travail.",
    'schema2.cardInfo2': 'Groupe de travail',
    'schema2.cardInfo3':
        "Information approuvée pour être partagée avec la partie désignante ou avec d'autres groupes de travail.",
    'schema2.cardInfo4':
        "Information dont l'usage est autorisé dans une étude plus détaillée, pour la construction ou la gestion d'actifs.",
    'schema2.cardInfo5':
        "Information ayant été remplacée, retirée ou rejetée pendant le processus de gestion de l'information.",
    'schema2.step1Header': 'ETAT/ETAPE 1 :',
    'schema2.step1Text': 'un espace de travail privé pour les travaux en cours',
    'schema2.step2Header': 'ETAT/ETAPE 2 :',
    'schema2.step2Text': 'les travaux sont visibles',
    'schema2.sideHint1': 'CONTRÔLE, REVUE & APPROBATION',
    'schema2.step3Header': 'ETAT/ETAPE 3 :',
    'schema2.step3Text': 'l’utilisation des travaux est autorisée',
    'schema2.sideHint2': 'REVUE & AUTORISATION',
    'schema2.step4Header': 'Journal des activités',
    'schema2.sideText1': 'Ce qu’une équipe doit produire',
    'schema2.sideText2': 'Information partagé pour un usage particulier',
    'schema2.sideText3': 'Information publiée',
    'schema2.sideText4': 'Information attendue pour le contrôle',
    'schema2.sideText5': 'Information attendue pour le contrôle',
    'schema2.figure10': 'Figure 10 — Concept de CDE',
    'schema2.figure10Title': 'Basé sur',
    'note.noteTitle1': 'Notes under schema: ',
    'note.noteTitle2': 'L’environnement commun de donnée est défini précédemment (lien vers CDE du COMMENT)',
    'note.noteTitle3': 'Cette figure décrit les différents types d’exigences dans leurs liens/relations',
    'note.noteTitle4': 'Les informations à livrer participent à la constitution des Modèles d’informations :',
    'note.noteTitle5': 'Les concepts normatifs :',
    'note.noteTitle6': 'Au jalon de projet',
    'note.noteTitle7': 'Note',
    'note.noteText1':
        'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    'note.noteText2': 'Il organise la production, les échanges et les livraisons des conteneurs d’informations',
    'note.noteText3': 'Qu’est ce qui est demandé : OIR, PIR',
    'note.noteText4': 'Qu’est ce qui est demandé : OIR, AIR, PIR',
    'note.noteText5': 'Et comment c’est demandé : EIR',
    'note.noteText6': 'Les relations avec le schéma entier',
    'note.noteText7': 'En tout ou parties du Modèle d’informations du projet',
    'note.noteText8': 'En tout ou parties du Modèle d’informations de l’actif',
    'note.noteText9': 'Les informations délivrées au PIM contribuent à l’élaboration de l’AIM',
    'note.noteText10': 'PIM',
    'note.noteText11': 'AIM',
    'note.noteText12': 'exgiences d’information de l’actif (AIR)',
    'note.noteText13': 'exigecens d’information du projet (PIR)',
    'note.noteText14': 'exigences d’échanges d’infomation (EIR)',
    'note.noteText15': 'équivalent à l’usage français ',
    'note.noteText16': 'cahier des charges BIM',
    'note.noteText17': ', est abordé à la page 10. Il est également abordé dans la rubrique en France.',
    'note.noteText17_1': ', est ',
    'note.noteText17_2': 'abordé ici',
    'note.noteText17_3': 'Il est également abordé dans la rubrique en France.',
    'note.noteText18': 'Fourniture d’informations',
    'note.noteText19': ' de la ',
    'note.noteText20': 'partie désignante',
    'note.noteText21': ' à la ',
    'note.noteText22': 'partie désignée principale',
    'note.noteText23': ' qui les contrôle : ',
    'note.noteText24': 'revue en début d’ étape n+1',
    'note.noteText25': 'Livraison d’informations',
    'note.noteText26': ' par la ',
    'note.noteText27': 'revue en fin d’étape n',
    'note.noteText28': 'Implicitement dans ce schéma et conformément au schéma 7 original de l’ISO 19650, les informations à livrer sont résultats des exigences du début de l’étape n et les informations sont à livrer en fin d’étape n+1.',
    'note.noteText29': 'Il convient que chaque conteneur d\'information géré dans le CDE contienne des métadonnées incluant :',
    'note.noteText29_1': 'le code de révision (Exemple : indice "A" ou "1" de document)',
    'note.noteText29_2': 'le code de statut, indiquant l\'utilisation ou les utilisations autorisées des informations (Pour information, pour approbation, pour synthèse...etc)',
    'note.noteText29_3': 'l\'état (travail en cours, partagé, publié)',
    'note.noteText30': 'Deux exemples :',
    'note.noteText30_1': 'Un conteneur d\'information : "fonds de plan de structure", code de révision : première diffusion "A", code statut : "pour information", état : "travail en cours"',
    'note.noteText30_2': 'Un conteneur d\'information : "Une maquettre architecturale", code de révision :  2e diffusion "2", code statut : "pour validation", état : "partagé"',
    'note.noteText31': 'CONTRÔLE, REVUE & APPROBATION : ',
    'note.noteText31_1': 'Revue de conformité aux process',
    'note.noteText32': 'REVUE & AUTORISATION : ',
    'note.noteText32_1': 'Revue de pertinence',
    'note.noteText33': 'Les deux parties sont liées par la désignation (exemple : un contrat)',
    'note.noteText34': 'Les actions de chacune des parties sont décrites',
    'note.noteText35': 'L\'équipe de production est rattaché à la partie désignée principale.',
    'note.noteText36': 'Une partie désignée principale pilote une ou plusieurs partie(s) désignée(s).',
    'note.noteText37': 'L\'équipe de travail est rattachée à la partie désignée.',
    'note.noteText38': 'L\'équipe de travail est rattachée à la partie désignée, et est pilotée par l\'équipe de production.',
    'note.noteText39': 'Le schéma représente un exemple de décision clé à prendre par la partie désignante. Cette décision est prise à un point de décision principal (losange) où un ensemble d\'exigences d\'information est défini et transmis en cascade à l\'équipe de production (partie désignée principale et parties désignées le cas échéant). Les informations sont fournies par échange d\'informations (cercle plein).',
    'note.noteText40': 'Le déroulé s\'organise autour des jalons de projet.',
    'note.noteText41': 'Le déclencheur est l\'échange d\'information.',
    'note.noteText42': 'Deux natures de contrôles se mettent en place : contrôle de fin d\'étape (3) et contrôle d\'étape suivante (7).',
    'note.noteText43': 'A chacun des jalons se met en place un déroulé de gestion de l\'information entre les parties et les contrôles associés (voir les deux schémas présentés précédemment). Les jalons se succèdent ainsi suivant le cycle de vie du projet.\n',
    'note.noteText44': 'L\'objectif de la gestion d\'information est de livrer un PIM (modèle d\'information du projet) aux jalons de projets. On ne parle ici des AIM (modèle d\'information de l\'actif), car on ne traite pas la norme 19650-3 dans ces travaux)',
    'note.noteText45': 'A considérer : le niveau du besoin d\'information est lié à la stratégie de fédération.',
    'note.noteText46': 'A noter : J’adapte mes demandes en fonction des désignations, de l’échelle et de la complexité de mes ouvrages',
    'note.noteText47': ' : évaluation et besoin',
    'note.noteText48': ' : appel d’offres',
    'note.noteText49': ' : soumission',
    'note.noteText50': ' : désignation',
    'note.noteText51': ' : mobilisation',
    'note.noteText52': ' : production collaborative d’informations',
    'note.noteText53': ' : livraison du modèle d’information',
    'note.noteText54': ' : clôture du projet (fin de la phase de réalisation)',
    'note.noteText55': 'La norme propose un schéma neutre des configuration des jeux d\'acteurs et des typologies de marchés. Un travail d\'identification et d\'appropriation est à réaliser en fonction de vos contextes.',
    'note.noteText56': 'Ce schéma est la resprésentation hiérarchique du schéma de la norme.',
    'note.noteText57': 'La norme propose un schéma neutre des configuration des jeux d\'acteurs et des typologies de marchés. Un travail d\'identification et d\'appropriation est à réaliser en fonction de vos contextes. Différentes configurations sont développées en exemple (à sélectionner dans le menu déroulant).',
    'note.noteText58': 'La boîte à outil décrit et rassemble des processus, concepts et moyens à développer et considérer pour la msie en oeuvre du BIM (gestion de l\'information associée à une opération). ',
    'note.noteText59': 'Cette partie s\'interesse à la production et livraison de l\'information',
    'tooltip.title1': 'Par exemple :',
    'tooltip.title2': 'Liste et contenu des livrables par jalons de projet, par exemple :',
    'tooltip.text1_1': 'Objectif de réduction de l’empreinte carbone de XX%,',
    'tooltip.text1_2': 'Zéro artificialisation X% de logements sociaux sur nos ensembles bâtis à l’horizon xxxx,',
    'tooltip.text1_3': 'Suppression de xx% des passages à niveaux',
    'tooltip.text2_1': 'Références géographiques du site,',
    'tooltip.text2_2': 'Spécifications détaillées des pièces,',
    'tooltip.text2_3': 'Déclinaisons détaillées des contraintes acoustiques du site',
    'tooltip.text3_1': 'Localisation des équipements,',
    'tooltip.text3_2': 'Spécifications détaillées des luminaires,',
    'tooltip.text3_3': 'Dénomination des pièces du site (dénominations générale et usuelle sur site),',
    'tooltip.text3_4': 'Visualisation des  zones occupées et inoccupées des bâtiments,',
    'tooltip.text3_5': 'Référencement général des ouvrages ou de chaque ouvrage',
    'tooltip.text4_1': 'Références géographiques du site,',
    'tooltip.text4_2': 'Etablissement et fourniture de description des éléments du programme à une date déterminée',
    'tooltip.text4_3': 'Description détaillée des informations à délivrer en hors d’eau hors d’air',
    'tooltip.text5_1': 'Descriptif de tous les ventilateurs des bâtiment',
    'tooltip.text5_2': 'Base de données des interventions de maintenance',
    'tooltip.text5_3': 'Surfaces utiles des locaux',
    'tooltip.text5_4': '(ne pas confondre avec le Dossier des Ouvrages Exécutés)',
    'tooltip.text6_1': 'Schéma électrique des armoires',
    'tooltip.text6_2': 'Note acoustique',
    'tooltip.text6_3': 'Maquette structures',
    'tooltip.text6_4': 'Tableau de définition des portes',
    'tooltip.text6_5': '...',
    'tooltip.text7_1': 'Cahier des charges BIM',
    'tooltip.text7_2': 'Charte BIM',
    'tooltip.text8':
        "Exemple de désignation : Une commande spécifique de livraison d'information dans le cadre d'un Marché de travaux ou de maîtrise d'oeuvre",
    'tooltip.text9': 'Se mettre d’accord',
    'tooltip.text10': 'no content',
    'tooltip.text11': 'Savoir ce que je dois produire',
    'tooltip.text12': 'Savoir contrôler ce qui est produit / reçu',
    'tooltip.text13_1': 'Partie désignante',
    'tooltip.text13_2': 'Partie désignante Popup text - to be provided later or connected directly to the BIM Dictionary',
    'tooltip.text13_3': 'Duis facilisis proin vitae',
    'tooltip.text13_4': ' ut porta. Faucibus mauris eget pellentesque cras bibendum. ',
    'tooltip.text13_5': 'Partie désignée principale',
    'tooltip.text13_6': 'Partie(s) désignée(s)',
    'tooltip.text13_7': 'Partie désignée principale popup text  - to be provided later or connected directly to the BIM Dictionary',
    'tooltip.text13_8': 'Partie(s) désignée(s) - to be provided later or connected directly to the BIM Dictionary',
    'tooltip.text14': 'évaluation et besoin',
    'tooltip.text15': 'Appel d’offres',
    'tooltip.text16': 'Soumission',
    'tooltip.text17': 'Désignation',
    'tooltip.text18': 'Mobilisation',
    'tooltip.text19': "Production collaborative d'informations",
    'tooltip.text20': "Livraison du modèle d'information",
    'tooltip.text21': 'Clôture du projet (fin de la phase de réalisation)',
    'tooltip.text22': "modèle d'information enrichi par les équipes de production suivantes pour chaque désignation",
    'tooltip.text23': 'activités réalisées dans le cadre d’un projet',
    'tooltip.text24': 'activités réalisées dans le cadre d’une désignation',
    'tooltip.text25': 'activités réalisées pendant la phase de passation de marché (de chaque désignation)',
    'tooltip.text26': 'activités réalisées pendant la phase de planification de l’information (de chaque désignation)',
    'tooltip.text27': 'activités réalisées pendant la phase de production de l’information (de chaque désignation)',
    'tooltip.text28': "Désigner les personnes en charge de la fonction de gestion de l'information",
    'tooltip.text29': "Définir les exigences d'information du projet",
    'tooltip.text30': "Définir les jalons de livraison de l'Information du projet",
    'tooltip.text31': "Définir la norme d'information du projet",
    'tooltip.text32': "Définir les méthodes et modes opératoires du projet pour la production d'informations",
    'tooltip.text33': "Définir l'information de référence et les ressources partagées du projet",
    'tooltip.text34': "Définir l'environnement de données commun du projet",
    'tooltip.text35': "Définir le protocole d'échange d'informations du projet",
    'tooltip.text36': "Définir les exigences de la partie désignante en matière d'échange d'informations",
    'tooltip.text37': "Regrouper l'information de référence et les ressources partagées",
    'tooltip.text38': "Définir les exigences de soumission et les critères d'évaluation",
    'tooltip.text39': "Compiler les informations relatives à l'appel d'offres",
    'tooltip.text40': 'Désigner les personnes en charge de la fonction de gestion de l’information',
    'tooltip.text41': 'Élaborer le plan d’exécution BIM (pré-désignation) de réquipe de production',
    'tooltip.text42': 'Évaluer les aptitudes et les aptitudes et capacités des équipes de travail',
    'tooltip.text43': "Déterminer les aptitudes et les capacités de l'équipe de production",
    'tooltip.text44': 'Élaborer le plan de mobilisation proposé par l équipe de production',
    'tooltip.text45': "Établir le registre des risques de l'équipe de production",
    'tooltip.text46': 'Compiler la soumission de l équipe de production',
    'tooltip.text47': "Confirmer le piar d'exécution BIM de féqulpe de production",
    'tooltip.text48': "Déterminer la matrice détaillée des responsabilités de l'équipe de production",
    'tooltip.text49': "Définir les exigences de la partie désignée principale en matière d'échange d'informations",
    'tooltip.text50': "Élaborer le{s) plan{s) de livraison de l'information par tâche",
    'tooltip.text51': 'Élaborer le plan directeur de livraison de Nnformation',
    'tooltip.text52': 'Renseigner les documents de désignation de la partie désignée principale',
    'tooltip.text53': 'Renseigner les documents de désignation de la partie désignée',
    'tooltip.text54': 'Mobiliser les ressource',
    'tooltip.text55': 'Mobiliser les technologies de [information',
    'tooltip.text56': "Contrôler les méthodes et modes opératoires du projet pour la production d'informations",
    'tooltip.text57': "Vérifier la disponibilité de l'information de référence et des ressources partagées",
    'tooltip.text58': 'Produire de l’information',
    'tooltip.text59': "Effectuer un contrôle d'assurance de la qualité",
    'tooltip.text60': "Revoir et approuver le partage de l'information",
    'tooltip.text61': 'Revoir le modèle d’information',
    'tooltip.text62': 'Soumettre le modèle d:information pour autorisation de la partie désignée principale',
    'tooltip.text63': "Revoir et autoriser le modèle d'information",
    'tooltip.text64': "Soumettre à la partie désignante le modèle d'information pour acceptation",
    'tooltip.text65': "Revoir et accepter le modèle d'information",
    'tooltip.text66': 'Archivage du modèle déformation du projet',
    'tooltip.text67': 'Enregistrer les enseignements tirés pour les projets futurs',
    'tooltip.text68': 'Partie désignante',
    'tooltip.text69': 'partie désignée principale',
    'tooltip.text70': 'partie désignée',
    'tooltip.text71': 'Équipe de projet',
    'tooltip.text72': 'Illustrations d’équipes de production',
    'tooltip.text73': 'Équipe(s) de travail',
    'tooltip.text74': 'Exigences d’information et échange d’informations',
    'tooltip.text75': 'Coordination des informations',
    'tooltip.text85_1': 'Je suis la partie désignante alors je suis concernée par 16 activités sur 5 séquences : ',
    'tooltip.text85_2': 'séquence 1',
    'tooltip.text85_3': ' : l’évaluation et besoin (8 activités)',
    'tooltip.text85_4': 'séquence 2',
    'tooltip.text85_5': ' : l’appel d’offres (4 activités)',
    'tooltip.text85_6': 'séquence 3',
    'tooltip.text85_7': ' : la soumission (1 activité)',
    'tooltip.text85_8': 'séquence 7',
    'tooltip.text85_9': ' : la livraison du modèle d’information (1 activité)',
    'tooltip.text85_10': 'séquence 8',
    'tooltip.text85_11': ' : la clôture du projet (2 activités)',
    'tooltip.text86_1': 'Je suis partie désignée principale alors je suis concernée par des activités sur 5 séquences : ',
    'tooltip.text86_2': 'séquence 3',
    'tooltip.text86_3': ' : la soumission',
    'tooltip.text86_4': 'séquence 4',
    'tooltip.text86_5': ' : la désignation',
    'tooltip.text86_6': 'séquence 5',
    'tooltip.text86_7': ' : la mobilisation',
    'tooltip.text86_8': 'séquence 6',
    'tooltip.text86_9': ' : la production collaborative d’informations',
    'tooltip.text86_10': 'séquence 7',
    'tooltip.text86_11': ' : la livraison du modèle d’information',
    'tooltip.text87_1': 'Je suis partie désignée(s) alors je suis concernée par des activités sur 5 séquences :',
    'tooltip.text87_2': 'séquence 3',
    'tooltip.text87_3': ' : la soumission',
    'tooltip.text87_4': 'séquence 4',
    'tooltip.text87_5': ' : la désignation',
    'tooltip.text87_6': 'séquence 5',
    'tooltip.text87_7': ' : la mobilisation',
    'tooltip.text87_8': 'séquence 6',
    'tooltip.text87_9': ' : la production collaborative d’informations',
    'tooltip.text87_10': 'séquence 7',
    'tooltip.text87_11': ' : la livraison du modèle d’information',
    'tooltip.text88': 'effacer',
    'tooltip.text89': 'retourner',
    'tooltip.text90': 'retourner en haut',
    'schema3.introTitle': 'Organisation & processus',
    'schema3.introSubTitle': 'Qui ?',
    'schema3.intro1Section1Heading': 'Concernant les acteurs, la norme fait état de 5 positions d\'acteurs possibles:',
    'schema3.intro1TextBlock1': 'En premier lieu',
    'schema3.intro1TextBlock2': ', un acteur se détermine soit comme partie désignante soit comme partie désignée/partie désignée principale.',
    'schema3.intro1TextBlock3': 'Les parties désignée se répartissent en équipes (De production ou de travail).',
    'schema3.intro1TextBlock4': 'Ensuite',
    'schema3.intro1TextBlock5': ', trois fonctions de attachées à la gestion de l\'information sont à couvrir : ',
    'schema3.intro1TextBlock6': 'celle associée à l\'ouvrage dans son cycle de vie',
    'schema3.intro1TextBlock7': 'celle associée à l\'ouvrage au moment du projet, tant sur les phases conception que réalisation',
    'schema3.intro1TextBlock8': 'celle associée à une spécialité d\'une tâche',
    'schema3.introTitle2': 'Organisation et processus : Qui ?',
    'schema3.introSubTitle2': 'Les acteurs',
    'schema3.intro2Section1Heading': 'Heading',
    'schema3.intro2TextBlock1': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.',
    'schema3.intro2TextBlock2': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
    'schema3.intro2RedNoteTitle': 'Attention',
    'schema3.intro2RedNoteText': 'Décrire ce que l’on veut est un préalable incontournable.',
    'schema3.intro2BlueNoteTitle': 'Exemple',
    'schema3.intro2BlueNoteText': 'Lorum Ipsum dolor sit ...',
    'schema3.intro2Footer': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema3.introId1': 'Element ID',
    'schema3.introId2': 'Element ID',
    'schema3.title': 'Les acteurs',
    'schema3.subTitle': 'Les parties',
    'schema3.block1Title': 'Partie désignante',
    'schema3.block2Title': 'Désignation 1 à  n',
    'schema3.block3Title': 'Partie désignée principale',
    'schema3.block3Title1': 'Parties désignées',
    'schema3.block4Title': 'Définit les exigences EIR (les EIR contiennent l\'ensemble des exigences requises dans les OIR, AIR et PIR)',
    'schema3.block4Title1': 'Désigne',
    'schema3.block5Title': 'Rédige en réponse aux EIR le ',
    'schema3.block5Title1': 'BEP',
    'schema3.block5Title2': ' et autres documents de gestion de l\'information ',
    'schema3.block5Title6': 'Complète si existant ',
    'schema3.block5Title3': 'TIR',
    'schema3.block5Title4': ' Est désignée',
    'schema4.introTitle': 'Organisation et processus : Qui ?',
    'schema4.introSubTitle': 'Les acteurs',
    'schema4.intro1Section1Heading': 'Au sein des parties désignées, le travail de production et de livraison de l’information s’organise en équipe(s) de production constituée(s) d’équipe(s) de travail.',
    'schema4.introTitle2': 'Organisation et processus : Qui ?',
    'schema4.introSubTitle2': 'Les fonction de gestion de l’information',
    'schema4.intro2Section1Heading': '3 fonctions de gestion de l’information sont définies dans la norme ',
    'schema4.intro2TextBlock1_1': 'Fonctions de gestion de l’information des actifs',
    'schema4.intro2TextBlock1_2': 'Le long terme de la gestion des actifs nécessite de s’assurer que ces fonctions sont assurées tout au long du cycle de vie duprojet.',
    'schema4.intro2TextBlock1_3': 'Fonctions de gestion de l’information du projet',
    'schema4.intro2TextBlock1_4': 'La gestion de l’information appliquée au projet s’applique au temps de la conception et de la réalisation de l’ouvrage',
    'schema4.intro2TextBlock1_5': 'Fonctions de gestion de l’information des tâches',
    'schema4.intro2TextBlock1_6': 'Cette gestion de l’information porte sur l’exécution des tâches',
    'schema4.intro2TextBlock2_1': 'Pour assurer une gestion efficace des informations, il faut clarifier les fonctions et les responsabilités, ainsi que les périmètres de toute tâche.',
    'schema4.intro2TextBlock2_2': 'Chaque fonction porte sur un périmètre d’action différent du plus général au plus spécifique.',
    'schema4.intro2RedNoteTitle': 'Attention',
    'schema4.intro2RedNoteText': 'les fonctions pour la gestion de l’information ne sont pas à confondre avec les fonctions et responsabilités des équipes de conception, réalisation, maintenance, impliquées sur le projet par ailleurs.',
    'schema4.intro2Footer': 'Footer: Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema4.introTitle3': 'Organisation et processus : Qui ?',
    'schema4.introSubTitle3': 'Les fonction de gestion de l’information',
    'schema4.intro3Section1Heading': '3 fonctions de gestion de l’information sont définies dans la norme ',
    'schema4.intro3TextBlock1_1': 'Fonctions de gestion de l’information des actifs',
    'schema4.intro3TextBlock1_2': 'Le long terme de la gestion des actifs nécessite de s’assurer que ces fonctions sont assurées tout au long du cycle de vie duprojet.',
    'schema4.intro3TextBlock1_3': 'Fonctions de gestion de l’information du projet',
    'schema4.intro3TextBlock1_4': 'La gestion de l’information appliquée au projet s’applique au temps de la conception et de la réalisation de l’ouvrage',
    'schema4.intro3TextBlock1_5': 'Fonctions de gestion de l’information des tâches',
    'schema4.intro3TextBlock1_6': 'Cette gestion de l’information porte sur l’exécution des tâches',
    'schema4.intro3TextBlock2_1': 'Pour assurer une gestion efficace des informations, il faut clarifier les fonctions et les responsabilités, ainsi que les périmètres de toute tâche.',
    'schema4.intro3TextBlock2_2': 'Chaque fonction porte sur un périmètre d’action différent du plus général au plus spécifique.',
    'schema4.intro3RedNoteTitle': 'Attention',
    'schema4.intro3RedNoteText': 'les fonctions pour la gestion de l’information ne sont pas à confondre avec les fonctions et responsabilités des équipes de conception, réalisation, maintenance, impliquées sur le projet par ailleurs.',
    'schema4.intro3DetailsTextBlock1_1': 'Fonctions de gestion de l’information du projet',
    'schema4.intro3DetailsTextBlock1_2': 'Les fonctions de gestion de l’information du projet établissent la norme d’information du projet, les méthodes et modes opératoires de production, dans le cadre de l’environnement de données commun du projet.',
    'schema4.intro3DetailsTextBlock2_1': 'Fonctions de gestion de l’information des tâches',
    'schema4.intro3DetailsTextBlock2_2': 'Les tâches des différentes équipes de travail sont coordonnées entre elles.',
    'schema4.intro3Footer': 'Footer: Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema4.introId1': 'Element ID',
    'schema4.introId2': 'Element ID',
    'schema4.introId3': 'Element ID',
    'schema4.title': 'Les acteurs',
    'schema4.subTitle': 'Les équipes',
    'schema4.block1Title': 'Partie désignée principale',
    'schema4.block2Title': 'Équipe de production',
    'schema4.block3Title': 'Partie désignée',
    'schema4.block4Title': 'Équipe de travail',
    'schema5.introTitle': 'Organisation & processus :Quand ?',
    'schema5.introSubTitle': 'Le cycle de vie de l\'information',
    'schema5.intro1TextBlock1': 'La matière de la ',
    'schema5.intro1TextBlock2': 'NF EN ISO 19650-1 ',
    'schema5.intro1TextBlock3': 'est la gestion de l\'information sur le cycle de vie de nos ouvrages :',
    'schema5.intro1TextBlock4': '[Reprendre la grande figure dans l\'intro, à insérer] (ref : NF EN ISO 19650-1, Figure 11  Vue d\'ensemble et illustration du processus de gestion de l\'information)',
    'schema5.intro1TextBlock5': '(Le déroulé et le contrôle)',
    'schema5.intro1TextBlock6': 'Les informations attachées aux ouvrages sont gérées par un processus décrit dans la norme et illustrées par les figures NF EN ISO 19650-1, Figure 6 et NF EN ISO 19650-1, Figure 7 :',
    'schema5.intro1TextBlock7': 'elles répondent à des exigences relatives au projet (OIR AIR PIR EIR)',
    'schema5.intro1TextBlock8': 'elles sont produites au sein des équipes de production',
    'schema5.intro1TextBlock9': 'elles sont livrées suivant les jalons déterminés et successifs',
    'schema5.intro1TextBlock10': 'elles sont contrôlées',
    'schema5.intro1TextBlock11': 'La norme NF EN ISO 19650-2 porte sur la livraison des modèles d\'information du projet (PIM) durant les phases conception et construction.',
    'schema5.intro1TextBlock12': 'La norme NF EN ISO 19650-3 porte sur la livraison des modèles d\'information de l\'actif (AIM): ',
    'schema5.intro1TextBlock13': '(ref : NF EN ISO 19650-1, Figure 9 Exemple de livraison par échange d\'informations venant à l\'appui des décisions clés des parties désignantes',
    'schema5.introTitle2': 'Organisation et processus : Quand ?',
    'schema5.introSubTitle2': 'Le déroulé et planification de la livraison',
    'schema5.intro2Section1Heading': 'Heading',
    'schema5.intro2TextBlock1': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.',
    'schema5.intro2TextBlock2': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
    'schema5.intro2RedNoteTitle': 'Attention',
    'schema5.intro2RedNoteText': 'Décrire ce que l’on veut est un préalable incontournable.',
    'schema5.intro2BlueNoteTitle': 'Exemple',
    'schema5.intro2BlueNoteText': 'Lorum Ipsum dolor sit ...',
    'schema5.intro2Footer': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema5.introTitle3': 'Organisation & processus :Quand ?',
    'schema5.introSubTitle3': 'Les contrôles',
    'schema5.intro3Section1Heading': 'Il est important de contrôler l\'information au moment de son échange après la fin d\'une étape n (ex1: étape de production des gabarits de projet, ex2: clos et couverts du projet) et au début de l\'étape suivante n+1.\n' +
        'Les contrôles permettent de vérifier et de valider l\'information échangée.',
    'schema5.introId1': 'Element ID',
    'schema5.introId2': 'Element ID',
    'schema5.introId3': 'Element ID',
    'schema5.title': 'Le déroulé',
    'schema5.subTitle': 'La cascade des exigences, les livraisons d’information',
    'schema5.subTitle2': 'La cascade des exigences',
    'schema5.subTitle3': 'Les livraisons d’information',
    'schema5.infoText1':
        '1-L’exigence d’information est transmise aux acteurs du projet par la Désignation. Les flèches descendantes illustrent cette transmission en cascade',
    'schema5.infoText2':
        '2-En réponse les parties désignées livrent l’information Les flèches remontantes illustrent cette transmission',
    'schema5.button1': 'Partie désignante',
    'schema5.button2': 'Partie désignée principale',
    'schema5.button3': 'Partie(s) désignée(s)',
    'schema5.text1': 'Exigence d’information du projet ou de l’organisation',
    'schema5.text2': 'Information à livrer',
    'schema5.text3': 'Exigences',
    'schema5.text4': 'Point principal de décision à l’étape n',
    'schema5.text5': 'Echange d’informations',
    'schema5.text6': 'Ces transactions sont corrélées aux jalons du projet',
    'schema5.text7': 'Chaque échange d’information fait l’objet d’une livraison -acceptation',
    'schema5.legend1': 'Ces échanges d\'informations sont corrélées aux jalons du projet',
    'schema5.legend2': 'Ces échanges d\'informations sont corrélées aux jalons du projet',
    'schema5.figure6': 'Figure 6 - Relations entre un point de décision et l’information à livrer par une partie désignée principale',
    'schema5.figure6Title': 'Basé sur',
    'schema6.introTitle': 'Organisation et processus : Quand ?',
    'schema6.introSubTitle': 'Les jalons successifs',
    'schema6.intro1TextBlock': 'A chacun des jalons se met en place un déroulé de gestion de l\'information entre les parties et les contrôles associés (voir les deux schémas présentés précédemment). Les jalons se succèdent ainsi suivant le cycle de vie du projet.\n' +
        '\n' +
        'Les jalons de projet sont à déterminer par la partie désignante (en général)',
    'schema6.intro1BlueNoteTitle': 'Exemple de jalons',
    'schema6.intro1BlueNoteText1': 'Validation de documents,',
    'schema6.intro1BlueNoteText2': 'réception provisoire ouvrages ou travaux,',
    'schema6.intro1BlueNoteText3': 'mise hors d’eau/hors d’air, etc.,',
    'schema6.intro1BlueNoteText4': 'fin phase LOI MOP.',
    'schema6.intro1BlueNoteText5': ' livraison clos et couverts du projet',
    'schema6.introTitle2': 'Organisation et processus : Quand ?',
    'schema6.introSubTitle2': 'Le cycle de vie de l’information',
    'schema6.introId': 'Element ID',
    'schema6.introId2': 'Element ID',
    'schema6.title': 'Les contrôles',
    'schema6.subTitle': 'Contrôle de fin d\'étape',
    'schema6.subTitle2': 'Contrôle d\'entrée à l\'étape suivante',
    'schema6.subTitle3': 'Les deux contrôles successifs',
    'schema6.legend1Title': 'QUAND?',
    'schema6.legend1Text': 'Jalons de projet à déterminer par la partie désignante',
    'schema6.legend1Example': 'Exemple',
    'schema6.legend1ExampleText':
        'Ex: validation de documents, réception provisoire ouvrages ou travaux, mise hors d’eau/hors d’air, etc.), fin phase LOI MOP.',
    'schema6.legend2Title': 'AU MOYEN DE :',
    'schema6.legend2Text': 'Echange d’informations',
    'schema6.step1Text': 'Les exigences sont \n' +
        'définies et transmises',
    'schema6.step2Text': 'Echange entre les parties',
    'schema6.step3Text': 'Contrôle',
    'schema6.step4Text': 'Livraison',
    'schema6.step5Text': 'la fourniture d\'informations : les exigences \n' +
        'et les informations livrées de l’étape n sont transmises.',
    'schema6.step6Text': 'Echange entre les parties\n',
    'schema6.step7Text': 'Contrôle\n',
    'schema6.step': 'Etape n',
    'schema6.stepEnd': 'Fin de l’étape n',
    'schema6.stepExchange': 'Echange d’informations',
    'schema6.stepDebut': 'Exigences\n' +
        '(du début étape n)',
    'schema6.stepReview': 'Revue en fin d’étape n',
    'schema6.step2': 'Etape n + 1',
    'schema6.step2End': 'Fin de l’étape n',
    'schema6.step2Debut': 'Début de l’étape n+1',
    'schema6.step2Review': 'Revue en fin d’étape n +1',
    'schema6.button1': 'Partie désignante',
    'schema6.button2': 'Partie désignée principale',
    'schema6.text1': 'Information à livrer',
    'schema6.text2': 'Fournitures d’informations',
    'schema6.text3': 'Exigences (du début étape n)',
    'schema6.text4': 'Information à livrer (fin d’étape n+1)',
    'schema6.text5': 'la fourniture d\'informations ',
    'schema6.figure7': 'Figure 7 - Contrôle d’informations pendant un échange d’informations',
    'schema6.figure7Title': 'Basé sur',
    'schema7.title': 'Les jalons successifs',
    'schema7.subTitle': 'Subheading under schema title',
    'schema7.button1': 'Partie désignante',
    'schema7.button2': 'Exigences (PIR) pour chaque principale point de décision',
    'schema7.button3': 'Exigences pour l’exploitation',
    'schema7.button4': 'Phase de réalisation',
    'schema7.button5': 'Phase d’exploitation',
    'schema7.buttonX': 'Partie désignée\n principale X',
    'schema7.buttonY': 'Partie désignée\n principale Y',
    'schema7.buttonZ': 'Partie désignée\n principale Z',
    'schema7.pim': 'PIM',
    'schema7.aim': 'AIM',
    'schema7.step1': 'Etape 1',
    'schema7.step1ReviewEnd': 'Revue en fin d’étape 1',
    'schema7.step2': 'Etape 2',
    'schema7.step2ReviewStart': 'Revue en début d’étape 2',
    'schema7.step2ReviewEnd': 'Revue en fin d’étape 2',
    'schema7.step3': 'Etape 3',
    'schema7.step3ReviewStart': 'Revue en début d’étape 3',
    'schema7.step': 'Etape(s)... ',
    'schema7.stepLink': '(événements déclancheurs)',
    'schema7.stepReviewStart': 'Revue en début d’étape ...',
    'schema7.stepReviewEnd': 'Revue en fin d’étape ...',
    'schema7.textX1': 'Organisation X de l’équipe de production et/ou équipes de travail',
    'schema7.textY1': 'Organisation Y de l’équipe de production et/ou équipes de travail',
    'schema7.textZ1': 'Organisation Z de l’équipe de production et/ou équipes de travail',
    'schema7.textX2': 'Equipe de production X',
    'schema7.textY2': 'Equipe de production Y',
    'schema7.textZ2': 'Equipe de production Z',
    'schema7.textX3': 'Echange d’information au sein de l’équipe de production',
    'schema7.textX4': 'Sous-désignations (selon le type de marché)',
    'schema7.figure9': 'Figure 9  Exemple de livraison par échange d\'informations venant à l\'appui des décisions clés des parties désignantes ',
    'schema7.figure9Title': 'Basé sur',
    'schema8.introTitle1': 'Outils & Méthodes',
    'schema8.introSubTitle1': 'Introduction',
    'schema8.introId1': 'Element ID',
    'schema8.intro1Section1Heading': 'Section 1 Heading',
    'schema8.intro1TextBlock1': 'Pour accompagner et supporter la gestion de l\'information, la norme décrit des outils et méthodes à disposition :',
    'schema8.intro1TextBlock2': 'Le ',
    'schema8.intro1TextBlock3': 'plan d\'excution BIM',
    'schema8.intro1TextBlock4': ' (BEP)',
    'schema8.intro1TextBlock5': 'La ',
    'schema8.intro1TextBlock6': 'matrice des responsabilités',
    'schema8.intro1TextBlock7': ' (Table de RACI),',
    'schema8.intro1TextBlock8': 'Le ',
    'schema8.intro1TextBlock9': 'plan directeur de livraison de l’information',
    'schema8.intro1TextBlock10': ' (MIDP) et le ',
    'schema8.intro1TextBlock11': 'plan de livraison de l’information par tâche ',
    'schema8.intro1TextBlock12': '(TIDP)',
    'schema8.intro1TextBlock13': 'La ',
    'schema8.intro1TextBlock14': 'stratégie de fédération',
    'schema8.intro1TextBlock15': 'Le ',
    'schema8.intro1TextBlock16': 'niveau du besoin d\'information',
    'schema8.intro1TextBlock17': ' (LOIN)',
    'schema8.intro1TextBlock18': 'Les ',
    'schema8.intro1TextBlock19': 'conteneurs d\'information',
    'schema8.intro1TextBlock20': 'Les ',
    'schema8.intro1TextBlock21': 'dictionnaires de propriétés',
    'schema8.intro1RedNoteTitle': 'Attention',
    'schema8.intro1RedNoteText': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
    'schema8.intro1BlueNoteTitle': 'Exemple',
    'schema8.intro1BlueNoteText': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
    'schema8.intro1DetailsText': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
    'schema8.intro1Note': 'Lorem',
    'schema8.intro1Footer': 'Footer: Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema8.introTitle2': 'Outils & Méthodes',
    'schema8.introSubTitle2': 'Plan d’exécution BIM (BEP)',
    'schema8.introId2': 'Element ID',
    'schema8.intro2Section1Heading': 'Section 1 Heading',
    'schema8.intro2TextBlock1': 'Le Plan d’Exécution BIM (BEP) est la réponse des Parties désignées aux demandes exprimées dans la Désignation par les Exigences d’Echanges d’Informations (EIR).',
    'schema8.intro2TextBlock2_1': 'Il s’exprime en deux temps : pendant la consultation, après la désignation',
    'schema8.intro2TextBlock2_2': 'Il décrit notamment les moyens et méthodes affectés à la production des informations sur le projet.',
    'schema8.intro2DetailsText': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
    'schema8.intro2Footer': 'Footer: Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema8.introTitle3': 'Outils & Méthodes',
    'schema8.introSubTitle3': 'Matrice des responsabilité, MIDP, TIDP',
    'schema8.introId3': 'Element ID',
    'schema8.intro3Section1Heading': 'Section 1 Heading',
    'schema8.intro3TextBlock1': 'Après la désignation, le BEP est complété par les autres documents de gestion de l’information comme la matrice des responsabilité ou la planification générale des tâches',
    'schema8.intro3RedNoteTitle': 'Table de RACI',
    'schema8.intro3RedNoteText': '“Chemin de croix”',
    'schema8.intro3BlueNoteTitle': 'la planification générale des tâches …',
    'schema8.intro3BlueNoteText': 'Qui fait quoi quand?',
    'schema8.intro3DetailsText': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
    'schema8.intro3Footer': 'Footer: Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema8.introTitle4': 'Outils & Méthodes',
    'schema8.introSubTitle4': 'Plan d’exécution BIM (BEP)',
    'schema8.introId4': 'Element ID',
    'schema8.intro4TextBlock1': 'Le Plan d’Exécution BIM (BEP) est la réponse des Parties désignées aux demandes exprimées dans la Désignation par les Exigences d’Echanges d’Informations (EIR).',
    'schema8.intro4TextBlock2_1': 'Il s’exprime en deux temps : pendant la consultation, après la désignation',
    'schema8.intro4TextBlock2_2': 'Il décrit notamment les moyens et méthodes affectés à la production des informations sur le projet.',
    'schema8.intro4DetailsTextBlock1_1': 'Pendant la consultation le BEP couvre 7 chapitres',
    'schema8.intro4DetailsTextBlock1_2': 'L’affectation des fonctions de gestion de l’information',
    'schema8.intro4DetailsTextBlock1_3': 'La stratégie de livraison de l’information de l’équipe de production',
    'schema8.intro4DetailsTextBlock1_4': 'La stratégie de fédération',
    'schema8.intro4DetailsTextBlock1_5': 'La matrice de responsabilité de l’équipe de production',
    'schema8.intro4DetailsTextBlock2_1': 'Proposition ajout ou modification des méthodes et modes opératoires pour la production d’information, acquisition, revue, approbation, autorisation, sécurité, diffusion',
    'schema8.intro4DetailsTextBlock2_2': ' Proposition ajout ou modification des normes d’information du projet pour faciliter les échanges',
    'schema8.intro4DetailsTextBlock2_3': 'Solutions logiciels proposées par l’équipe de production',
    'schema8.introTitle5': 'Outils & Méthodes',
    'schema8.introSubTitle5': 'Matrice des responsabilité, MIDP, TIDP',
    'schema8.introId5': 'Element ID',
    'schema8.intro5TextBlock1': 'Après la désignation, le BEP est complété par les autres documents de gestion de l’information comme la matrice des responsabilité ou la planification générale des tâches …',
    'schema8.intro5TextBlock2': 'Les équipes de travail forment leurs plans de travail : le plan de livraison de l’information par tâche (TIDP), réponse aux questions quoi, quand, comment?\n' +
        'L\'ensemble coordonné des plans de travail (TIDPs), par l\'équipe de production, forme le plan directeur de livraison de l’information (MIDP)',
    'schema8.intro5RedNoteTitle': 'Table de RACI',
    'schema8.intro5RedNoteText': '“Chemin de croix”',
    'schema8.intro5BlueNoteTitle': 'la planification générale des tâches …',
    'schema8.intro5BlueNoteText': 'Qui fait quoi quand?',
    'schema8.intro5DetailsTextBlock1_1': 'Après la désignation le BEP est précisé par :',
    'schema8.intro5DetailsTextBlock1_2': 'La Matrice des responsabilités détaillée',
    'schema8.intro5DetailsTextBlock1_3': 'Autres appellations courantes : table de RACI, chemin de croix',
    'schema8.intro5DetailsTextBlock1_4': 'La définition précisée des besoins en matière d’échanges d’informations',
    'schema8.intro5DetailsTextBlock1_5': 'Reprise des exigences de la partie désignante(EIR)',
    'schema8.intro5DetailsTextBlock1_6': 'Complément par les besoins des parties désignées (TIR)',
    'schema8.intro5DetailsTextBlock1_7': 'Établissement du niveau de besoin d’information pour satisfaire aux exigences',
    'schema8.intro5DetailsTextBlock2_1': 'Etablissement des critères d’acceptation',
    'schema8.intro5DetailsTextBlock2_2': '...',
    'schema8.intro5DetailsTextBlock2_3': 'Les plans de livraison des informations par tâches TIDP',
    'schema8.intro5DetailsTextBlock2_4': 'Contenu et planification de livraison des conteneurs d’information par équipes de travail',
    'schema8.intro5DetailsTextBlock2_5': 'Le plan directeur de livraison de l’information MIDP',
    'schema8.intro5DetailsTextBlock2_6': 'Coordination de l’ensemble des conteneurs d’informations délivrés par les équipes de travail',
    'schema8.introTitle6': 'Outils & Méthodes',
    'schema8.introSubTitle6': 'Stratégie de fédération',
    'schema8.introId6': 'Element ID',
    'schema8.intro6Section1Header': '3 fonctions de gestion de l’information sont définies dans la norme',
    'schema8.intro6TextBlockFull1': 'La stratégie de fédération distribue les tâches des équipes de travail et de production.',
    'schema8.intro6TextBlockFull2': 'Elle dépend des objectifs (synthèse, détection des conflits, …)',
    'schema8.intro6TextBlockFull3': 'Elle s’appuie sur des méthodes (décomposition par vue métier, systèmes, zones géographiques ou fonctionnelles, lots, phasages …. et aspects technologiques)',
    'schema8.intro6ExamplesTitle': 'Exemples  :',
    'schema8.intro6Footer': 'Footer: Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema8.introTitle7': 'Outils & Méthodes',
    'schema8.introSubTitle7': 'Niveau du besoin d’information',
    'schema8.introId7': 'Element ID',
    'schema8.intro7TextBlockFull': 'Le "niveau du besoin d\'information" est introduit dans la série NF EN ISO 19650 et fait l\'objet d\'une norme spécifique : NF EN 17412-1. \n' +
        'La norme NF EN 17412-1 vient du besoin de décrire sans ambiguité ce qui est attendu pour les échanges (la livraison des informations).\n' +
        'Cette norme est utilisable dans le cadre d’échanges contractuels ou plus informels. Elle permet à l’acteur demandeur (désignant) et à l’acteur sollicité (désigné) de décrire l’information, respectivement attendue et à produire, de la même manière. Ainsi, le besoin et la réponse au besoin sont cohérents. C’est une méthode de description de l’information qui facilite la communication et la suivi de l’information produite. Contrairement à des documents existant traitant des « niveaux de détail, de développement » ou autres, cette norme ne normalise pas le contenu à échanger.',
    'schema8.intro7DetailsText': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
    'schema8.intro7Note': '(Février 2022) La norme NF EN 17412-1 sera complété par d\'autres parties dont une traitant de l\'application la première partie.\n' +
        'La norme NF EN 17412-1 est suceptible de subir des modifications lors de son passage en norme ISO 7817-1.',
    'schema8.introTitle8': 'Outils & Méthodes',
    'schema8.introSubTitle8': 'Niveau du besoin d’information : exemples',
    'schema8.introId8': 'Element ID',
    'schema8.intro8Tab1': 'Exemple 1',
    'schema8.intro8Tab1Heading1': 'Exemple 1: ',
    'schema8.intro8Tab1Heading2': 'Projet EUROVIA',
    'schema8.intro8Tab1Heading2Text1': 'Description du projet',
    'schema8.intro8Tab1Heading2Text2': 'Le projet consiste en un réaménagement de rond-point par la création d’un passage inférieur sous la chaussée. Ce passage sert à la circulation des piétons et au rétablissement de la continuité hydraulique et faunistique du milieu naturel. Pour cela, des murs de soutènement doivent être posés. La méthode retenue et qui doit être validée consiste en la mise en place d’éléments béton préfabriqués par une grue automotrice.\n',
    'schema8.intro8Tab1Example1Title': 'Prérequis au “niveau du besoin d’information”',
    'schema8.intro8Tab1Example2Title': 'Comment décrire l’information attendue ?',
    'schema8.intro8Tab2': 'Exemple 2',
    'schema8.intro8Tab2Heading1': 'Exemple 2: ',
    'schema8.intro8Tab2Heading2': 'Projet OLYMPI',
    'schema8.intro8Tab2Heading2Text1': 'Description du projet',
    'schema8.intro8Tab2Heading2Text2_1': 'Le projet « Démonstrateur Olympi », est un projet du Plan BIM 2022 et suivi par de nombreux contributeurs, partenaires et observateurs.',
    'schema8.intro8Tab2Heading2Text2_2': 'Il vise, sur une opération courante de logements collectifs traitée en lots séparés avec des acteurs TPE-PME, à traiter la phase « Appel d’Offres en BIM » jalon au carrefour de la phase conception et réalisation qui mobilise la MOA, MOE et les entreprises, et plus particulièrement : ',
    'schema8.intro8Tab2Heading2Text2_3': 'L’identification des besoins et attentes des entreprises d’un « DCE numérique BIM cible », ',
    'schema8.intro8Tab2Heading2Text2_4': 'La production en phase conception par la MOE du « DCE Numérique BIM » issu d’un processus BIM collaboratif coordonné, ',
    'schema8.intro8Tab2Heading2Text2_5': 'L’exploitation de celui-ci par les entreprises TPE-PME pour la réalisation des études de prix, et la réponse en ligne à l’appel d’offres,',
    'schema8.intro8Tab2Heading2Text2_6': 'L’analyse des offres, la négociation et la passation dématérialisée des marchés. ',
    'schema8.intro8Tab2Heading2Text2_7': 'Il a comme objectif de démontrer concrètement par l’exemple, la pertinence et l’efficience de l’approche numérique et BIM en phase Appel d’Offres pour l’ensemble des acteurs Entreprises, MOE et MOA, et d’identifier les bénéfices et les gains, mais aussi les freins, difficultés et points de vigilance, en s’attachant mettre en œuvre une approche pragmatique et reproductible.',
    'schema8.intro8Tab2Heading2Text3': 'Description du niveau du besoin d’information',
    'schema8.intro8Tab2Heading2Text4': 'Ce niveau de besoin d\'information consiste à fournir au sein des modèles d\'informations livrés pour l\'appel d\'offres, les informations alphanumériques renseignées dans 1 à 6 propriétés d\'objets dédiés (parmi d\'autres propriétés structurant les modèles livrés non étudiés dans cet exemple) le ciblage (faire le lien dans une seule façon) les spécifications techniques incluses dans le dossier d\'appel d\'offres et situées dans 20 documents de spécifications techniques distincts.',
    'schema8.intro8Tab2Example1Title': 'Prérequis au “niveau du besoin d’information”',
    'schema8.intro8Tab2Example2_1Title': 'Comment décrire l’information attendue ?',
    'schema8.intro8Tab2Example2_2Title': 'exemple pour une pièce (IfcSpace)',
    'schema8.intro8Footer': 'Footer: Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema8.introTitle9': 'Outils & Méthodes',
    'schema8.introSubTitle9': 'Conteneurs d’information',
    'schema8.introId9': 'Element ID',
    'schema8.intro9BlueNoteTitle': 'Exemple',
    'schema8.intro9BlueNoteText1': 'Notes de calculs de structure + Maquette de structure géométrique et ses avis Techniques',
    'schema8.intro9BlueNoteText2': 'Maquette numérique du projet + informations de l’environnement existant + plans de DICT',
    'schema8.intro9TextBlock1_1': 'Les informations nécessaires à la conception, la réalisation puis à la gestion des ouvrages sont de plusieurs natures',
    'schema8.intro9TextBlock1_2': 'Elles peuvent être :',
    'schema8.intro9TextBlock1_3': 'Géométriques : si elles s’expriment en 3D, par exemple l’expression d’un volume, alors on parle couramment de maquette numérique',
    'schema8.intro9TextBlock1_4': 'Schématiques en 2D,',
    'schema8.intro9TextBlock1_5': 'Sous forme d’avis techniques,',
    'schema8.intro9TextBlock1_6': 'Documentées en base de données,',
    'schema8.intro9TextBlock1_7': 'Liées à des dictionnaires de propriétés,',
    'schema8.intro9TextBlock1_8': '...etc.',
    'schema8.intro9TextBlock1_9': 'Elles peuvent inclure des notices d’entretien, ... etc.',
    'schema8.intro9TextBlock2_1': 'Pour englober ces ensembles hétérogènes d’information à produire et livrer, on parle alors de conteneur d’information',
    'schema8.intro9TextBlock2_2': 'Les conteneurs d’informations incluent les ensembles d’informations «persistantes» à délivrer aux contrats',
    'schema8.intro9TextBlock2_3': 'La livraison et les échanges des informations s’appuie sur les conteneurs d’informations',
    'schema8.intro9Footer': 'Footer: Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema8.title': 'Niveau du besoin d\'information ',
    'schema8.subTitle': 'Méthodologie',
    'schema8.block1Title': 'Prérequis au “niveau du besoin d’information”',
    'schema8.block2Title': 'Comment décrire l’information attendue ?',
    'schema8.block1Block1Title': 'POURQUOI ?',
    'schema8.block1Block1Description': '“Pourquoi cette information est nécessaire ?”',
    'schema8.block1Block2Title': 'QUAND ?',
    'schema8.block1Block2Description': 'jalon de livrasion de l’information (voir ',
    'schema8.block1Block2Description2': 'MIDP',
    'schema8.block1Block2Description3': ')',
    'schema8.block1Block3Title': 'QUI ?',
    'schema8.block1Block3Description': '“Qui demande et qui livre l’information?”',
    'schema8.block1Block4Title': 'QUOI ?',
    'schema8.block1Block4Description': '“Qu’est-ce qui est échangé ?”',
    'schema8.block2Block1Title': 'Niveau d’Information Géométrique',
    'schema8.block2Block2Title': 'Niveau d’Information Alphanumérique',
    'schema8.block2Block3Title': 'Niveau de Documentation',
    'schema8.block2Block1Block1Title': 'détail',
    'schema8.block2Block1Block1Description': 'de simplifié à détaillé',
    'schema8.block2Block1Block2Title': 'dimensionnalité',
    'schema8.block2Block1Block2Description': '0D, 1D, 2D, 3D',
    'schema8.block2Block1Block3Title': 'emplacement',
    'schema8.block2Block1Block3Description': 'absolu ou relatif',
    'schema8.block2Block1Block4Title': 'apparence',
    'schema8.block2Block1Block4Description': 'de symbolique à réaliste',
    'schema8.block2Block1Block5Title': 'comportement paramétrique',
    'schema8.block2Block1Block5Description': 'complet, partiel ou pas du tout',
    'schema8.block2Block2Block1Title': 'identification',
    'schema8.block2Block2Block2Title': 'contenu de l’information',
    'schema8.block2Block3Block1Title': 'ensemble de documents',
    'schema8.figure8': 'Figure 8  Schéma de relations pour le niveau du besoin d\'information',
    'schema8.figure8Title': 'Basé sur',
    'schema9.introTitle1': 'Les dictionnaires de propriétés',
    'schema9.intro1TextBlock1': 'Les dictionnaires sont le lieu de stockage des définitions des objets et des propriétés qui peuvent y être attachées. C’est sur ces dictionnaires que vont s’appuyer les exigences et les besoins ainsi que les catalogues et bibliothèques.',
    'schema9.intro1TextBlock2': 'Tous les dictionnaires utilisés dans un projet doivent être structurés selon une même norme afin de permettre leur interconnexion. Un dictionnaire ne contient que les définitions des objets et leurs propriétés et en aucun cas des valeurs qui y sont associées.',
    'schema9.introTitle2': 'Les dictionnaires de propriétés',
    'schema9.intro2Section1Heading': 'Section 1 Heading',
    'schema9.intro2TextBlock1': 'Les dictionnaires sont le lieu de stockage des définitions des objets et des propriétés qui peuvent y être attachées. C’est sur ces dictionnaires que vont s’appuyer les exigences et les besoins ainsi que les catalogues et bibliothèques.',
    'schema9.intro2TextBlock2': 'Tous les dictionnaires utilisés dans un projet doivent être structurés selon une même norme afin de permettre leur interconnexion. Un dictionnaire ne contient que les définitions des objets et leurs propriétés et en aucun cas des valeurs qui y sont associées.',
    'schema9.introId1': 'Element ID',
    'schema9.introId2': 'Element ID',
    'schema9.title': 'Les dictionnaires de propriétés',
    'schema9.block1Title': 'Exigence',
    'schema9.block1Text1': 'Les blocs de béton cellulaire',
    'schema9.block1Text2': 'Une classe de résistance à la compression',
    'schema9.block1Text3': 'Doivent avoir',
    'schema9.block1Text4': 'égale à 4 MPa',
    'schema9.block2Title': 'Catalogue / Bibliothèque générique',
    'schema9.block2Text1': 'Rc= 4 MPa',
    'schema9.block3Title': 'Dictionnaire',
    'schema9.block3Text1': 'Objet: BLOC DE BETON CELLULAIRE',
    'schema9.block3Text2': 'Propriété: Classe de résistance à la compression (perpendiculaire)',
    'schema9.block3Text3': 'Document de référence: EN 772-1 / NF EN 771-4',
    'schema11.introTitle': 'Les informations à livrer ',
    'schema11.introSubTitle': 'spécification et planification de la livraison',
    'schema11.intro1TextBlock': 'Le schéma (l\'organigramme) suivant décrit le processus de production de l\'information, depuis la définition des exigences (besoins) jusqu\'à la livraison de l\'information.',
    'schema11.introId': 'Element ID',
    'schema11.title': 'Spécification et planification \n de la livraison de l\'information',
    'schema11.subTitle': 'Spécification et planification de la livraison',
    'schema11.block1Title': "Exigences d'information (formuler les besoins)",
    'schema11.block2Title': "Calendrier de livraison de l'information (planifier comment et quand la livrer)",
    'schema11.block3Title': 'Livraison de l’information (la livrer)',
    'schema11.block4Title': "Approbation de l'information (la valider)",
    'schema11.block5Title': 'Spécification générique et planification de la livraison de l’information',
    'schema11.block6Title': 'Boucle de rétroaction',
    'schema11.ok': 'OK ?',
    'schema11.yes': 'Oui',
    'schema11.no': 'Non',
    'schema11.figure4': 'Figure 4  Spécification générique et planification de la livraison de l\'information',
    'schema11.figure4Title': 'Basé sur',
    'schema12.introTitle': 'En opérationnel que dois-je faire ?',
    'schema12.introSubTitle': 'NF EN ISO 19650-2',
    'schema12.intro1TextBlock1': 'La ',
    'schema12.intro1TextBlock2': 'NF EN ISO 19650-2 ',
    'schema12.intro1TextBlock3': 'propose la en oeuvre des principes et concepts abordés dans la NF EN ISO 19650-1, sur les opérations en phases de conception et réalisation.',
    'schema12.intro1TextBlock4': 'La ',
    'schema12.intro1TextBlock5': 'NF EN ISO 19650-2 ',
    'schema12.intro1TextBlock6': 'propose cette mise en oeuvre selon une suite de 8 groupes d\'activités (séquences) relative à la gestion d\'information du projet. Chacune des séquences intègre des activités à considérer.',
    'schema12.introTitle2': 'En opérationnel que dois-je faire ?',
    'schema12.introSubTitle2': 'NF EN ISO 19650-2',
    'schema12.introId1': 'Element ID',
    'schema12.introId2': 'Element ID',
    'schema12.title1': 'Huit (8) groupes d\'activités ',
    'schema12.subTitle1': "pour la gestion de l'information pendant la phase \nde conception et de réalisation des actifs",
    'schema12.title2': 'Evaluation et besoin',
    'schema12.subTitle2': 'Huit (8) activités',
    'schema12.title3': 'Appel d’offres',
    'schema12.subTitle3': 'Quatre (4) activités',
    'schema12.title4': 'Soumission',
    'schema12.subTitle4': 'Sept (7) activités',
    'schema12.title5': 'Désignation',
    'schema12.subTitle5': 'Sept (7) activités',
    'schema12.title6': 'Mobilisation',
    'schema12.subTitle6': 'Trois (3) activités',
    'schema12.title7': 'Production collaborative de l\'information',
    'schema12.subTitle7': 'Cinq (5) activités',
    'schema12.title8': 'Livraison du modèle d\'information',
    'schema12.subTitle8': 'Quatre (4) activités',
    'schema12.title9': 'Clôture du projet',
    'schema12.subTitle9': 'Deux (2) activités',
    'schema12.iso': 'Extrait de 19650-2 Figure 3',
    'schema12.topContentTitle1': 'Les activités 1, 2, 3, 7 et 8 concernent  :',
    'schema12.topContentTitle2': 'Les activités 3, 4, 5, 6 et 7 concernent  :',
    'schema12.topContentTitle3': 'Les activités du groupe 1 concernent :',
    'schema12.topContentTitle4': 'Vous y trouverez des références à :',
    'schema12.topContentTitle5': 'Les activités du groupe 2 concernent :',
    'schema12.topContentTitle6': 'Les activités du groupe 3 concernent :',
    'schema12.topContentTitle7': 'Les activités du groupe 4 concernent :',
    'schema12.topContentTitle8': 'Les activités du groupe 5 concernent :',
    'schema12.topContentTitle9': 'Les activités du groupe 6 concernent :',
    'schema12.topContentTitle10': 'Les activités du groupe 8 concernent :',
    'schema12.labelText1': 'Partie désignante',
    'schema12.labelText2': 'Partie désignée principale',
    'schema12.labelText3': 'Partie désignée',
    'schema12.labelText4': 'Exigence d’échanges d’information (EIR)',
    'schema12.labelText5': 'Plan d’exécution BIM (BEP)',
    'schema12.labelText6': 'Matrice des responsabilités (RACI)',
    'schema12.labelText7': 'A qui fait quoi et quand (MIDP, TIDP)',
    'schema12.labelText8': 'Outils & méthodes',
    'schema12.labelText9': 'Plateforme collaborative du projet (Envionnement de données commun (CDE)) ',
    'schema12.labelText10': 'Contrôle de pertinence',
    'schema12.textStart': 'commencer',
    'schema12.textEnd': 'finir',
    'schema12.figure3': 'Figure 3  Processus de gestion de l\'information pendant la phase de réalisation des actifs',
    'schema12.figure3Title': 'Basé sur',
    'schema12.figure4': 'Figure 4  Processus de gestion de l\'information - Évaluation et besoin',
    'schema12.figure4Title': 'Basé sur',
    'schema12.figure5': 'Figure 5  Processus de gestion de l\'information - Appel d\'offres',
    'schema12.figure5Title': 'Basé sur',
    'schema12.figure6': 'Figure 6  Processus de gestion de l\'information - Soumission',
    'schema12.figure6Title': 'Basé sur',
    'schema12.figure7': 'Figure 7  Processus de gestion de l\'information - Désignation',
    'schema12.figure7Title': 'Basé sur',
    'schema12.figure8': 'Figure 8  Processus de gestion de l\'information - Mobilisation',
    'schema12.figure8Title': 'Basé sur',
    'schema12.figure9': 'Figure 9  Processus de gestion de l\'information - Production collaborative de l\'information',
    'schema12.figure9Title': 'Basé sur',
    'schema12.figure10': 'Figure 10  Processus de gestion de l\'information - Livraison du modèle d\'information',
    'schema12.figure10Title': 'Basé sur',
    'schema12.figure11': 'Figure 11  Processus de gestion de l\'information - Clôture du projet',
    'schema12.figure11Title': 'Basé sur',
    'schema13.introTitle': 'En France : nos pratiques',
    'schema13.introSubTitle': 'Correspondances avec le corpus normatif BIM',
    'schema13.intro1TextBlock1': 'Les principes et concepts abordé dans la norme ne sont pas si éloignées de nos pratiques françaises. Il faut toutefois mettre en correspondance notre terminologie et nos usages avec les propositions de la norme.\n' +
        'La norme intègre des concepts et une terminologie, son intérêt est celui d\'un référentiel né d\'un consensus normatif national et international, publié.',
    'schema13.introTitle2': 'En France',
    'schema13.introSubTitle2': 'QUI SUIS-JE?',
    'schema13.intro2Section1Heading': 'Heading',
    'schema13.intro2TextBlock1': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.',
    'schema13.intro2TextBlock2': 'Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
    'schema13.intro2RedNoteTitle': 'Attention',
    'schema13.intro2RedNoteText': 'Décrire ce que l’on veut est un préalable incontournable.',
    'schema13.intro2BlueNoteTitle': 'Exemple',
    'schema13.intro2BlueNoteText': 'Lorum Ipsum dolor sit ...',
    'schema13.intro2Footer': 'Footer: Lorum Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    'schema13.introId1': 'Element ID',
    'schema13.introId2': 'Element ID',
    'schema13.introTitle3': 'Table de correspondance',
    'schema13.intro3Text': 'La table de correspondance est la photographie d\'un état actuel du BIM dans nos marché en ce qui concerne les termes nationaux. Cette table est sujette à mises à jour.',
    'schema13.title': 'Qui suis-je ?',
    'schema13.exampleTitle1': 'Exemple 1',
    'schema13.exampleTitle2': 'Exemple 2A',
    'schema13.exampleTitle3': 'Exemple 2B',
    'schema13.exampleTitle4': 'Exemple 3A',
    'schema13.exampleTitle5': 'Exemple 3B',
    'schema13.exampleTitle6': 'Exemple 4A',
    'schema13.exampleTitle7': 'Exemple 4B',
    'schema13.subTitle': 'Que dit la norme ?',
    'schema13.iso': 'NF EN ISO 19650-2 : Fig. 2  ',
    'schema13.iso2': 'Figure 2  (NF EN ISO 19650-2)',
    'schema13.legend': 'Légende :',
    'schema13.text1': 'Partie désignante',
    'schema13.text2': 'partie désignée principale',
    'schema13.text3': 'partie désignée',
    'schema13.text4': 'Quantité variable',
    'schema13.text5': 'Équipe de projet',
    'schema13.text6': 'Illustrations d’équipes de production',
    'schema13.text7': 'Équipe(s) de travail',
    'schema13.text8': 'Exigences d’information et échange d’informations',
    'schema13.text9': 'Coordination des informations',
    'schema13.buttonText1': 'Sélectionner un exemple',
    'schema13.buttonText2': 'Réinitialiser',
    'schema13.buttonText3': 'Exemple 1',
    'schema13.buttonText4': 'Exemple 2A',
    'schema13.buttonText5': 'Exemple 2B',
    'schema13.buttonText6': 'Exemple 3A',
    'schema13.buttonText7': 'Exemple 3B',
    'schema13.buttonText8': 'Exemple 4A',
    'schema13.buttonText9': 'Exemple 4B',
    'schema13.block1Title': 'Partie désignée\nprincipale',
    'schema13.block1SubTitle': 'illustration d’une équipe de production',
    'schema13.block1ContentSubTitle': 'équipe(s) de travail',
    'schema13.block1Content1': 'Partie\ndésignée',
    'schema13.block1Content2': 'Partie\ndésignée',
    'schema13.block1Content3': '+ autres parties\ndésignées (par\nexemple)',
    'schema13.block2Title': 'Maîtrise d’oeuvre',
    'schema13.block3Title': 'Entreprise\n' +
        'générale',
    'schema13.block4Title': 'Entreprise de facility\n management',
    'schema13.block5Title': 'BET A',
    'schema13.block6Title': 'BET B',
    'schema13.block7Title': 'BET C',
    'schema13.block8Title': 'Entreprise\n' +
        'sous-traitante',
    'schema13.block9Title': 'Mandataire \ngroupement',
    'schema13.block10Title': 'Entreprise A',
    'schema13.block11Title': 'Entreprise B',
    'schema13.block12Title': 'Entreprise C',
    'schema13.block2Content1': 'Architecte',
    'schema13.block2Content2': 'BET',
    'schema13.block2Content3': '+ 4 autres BET',
    'schema13.block2Content4': 'Entreprise\n' +
        'générale',
    'schema13.block2Content5': 'Entreprises',
    'schema13.block3Content1': 'BET B',
    'schema13.block3Content2': 'BET C',
    'schema13.block3Content3': '+ 4 autres BET',
    'schema13.block4Content1': 'Enterprise\n' +
        'sous-traitante',
    'schema13.block4Content2': 'Enterprise\n' +
        'sous-traitante',
    'schema13.block4Content3': '+ 4 autres \n' +
        'Enterprise\n' +
        'sous-traitante',
    'schema13.block5Content1': 'Entreprise A\n' +
        'cotraitante',
    'schema13.block5Content2': 'Entreprise B\n' +
        'cotraitante',
    'schema13.block5Content3': '+ 4 autres \n' +
        'Enterprises \ncotraitante',
    'schema13.block6Content1': 'Entreprise \nA',
    'schema13.block6Content2': 'Entreprise \nB',
    'schema13.infoTitle1': 'Partie désignante',
    'schema13.infoTitle2': 'Maître d’ouvrage',
    'schema13.infoTitle3': 'Entreprise générale',
    'schema13.topLabel1': 'équipe du projet',
    'schema13.topLabelDetails1': '(L’illustration omet volontairement les autres parties potentielles)',
    'schema13.stepBlockText1': 'conception',
    'schema13.stepBlockText2': 'exécution',
    'schema13.stepBlockText3': 'exploitation maintenance',
    'schema13.figure2': 'Figure 2  Interfaces entre les parties et les équipes aux fins de la gestion de l\'information',
    'schema13.figure2Title': 'Basé sur',
    'intro.moreDetails': 'Aller Plus Loin ?',
    'intro.lessDetails': 'Moins de détails ?',
    'intro.note': 'Remarque',
    'intro.seeAlso': 'Voir aussi',
    'terms.col1': 'terme (abr)',
    'terms.col2': 'Définition & Note & Source',
    'terms.col3': 'Remarques/ exemples',
    'terms.col4': 'Page ID',
    'terms.sortBy': 'Sort by:',
    'termPopup.subTitle': 'Voir dans le tableau de correspondance',
    'termPopup.definition': 'Définition',
    'termPopup.noDefinition': 'Pas de définition ',
    'termPopup.example': 'Exemple',
    'termPopup.noExample': 'Pas d’exemple',
    'termPopup.linkedUsage': 'Usage français associé',
    'termPopup.linkedNormativeTerm': 'Terme normatif associé',
    'termPopup.note': 'Remarque',
    'termPopup.noNote': 'Pas d’exemple',
    'termPopup.noteGeneral': 'Remarque générale'
};
