import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons } from '../../../../assets/icons/svg';
// SELECTORS
import { getPageId } from '../../../../store/application/selectors/selectors';
// ACTIONS
import setPageIdBack from '../../../../store/application/actions/setPageIdBack';
import setSearchQuery from '../../../../store/application/actions/setSearchQuery';
import setPageId from '../../../../store/application/actions/setPageId';

type Data = {
    href: string,
    maxTooltipWidth?: string,
    tooltipTitle?: string,
    tooltipText?: ReactElement<any, any> | string,
    start?: boolean
};

const Tooltip = ({ tooltipTitle, tooltipText, maxTooltipWidth, href, start }: Data) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pageId = useSelector(getPageId);

    const handleSearch = (query: string) => () => {
        if (href) {
            dispatch(setPageIdBack(pageId));
            dispatch(setSearchQuery(query));
            history.push('/page/109');
            dispatch(setPageId(109));
        }
    };

    const rootClassNames = classNames(styles.toolTip, {
        [styles.start]: start
    });

    return (
        <div className={rootClassNames} style={{ maxWidth: `${maxTooltipWidth}px` }}>
            <div className={styles.backArea}/>
            <div className={styles.tooltipContent}>
                { tooltipTitle &&
                <div className={styles.tooltipTitle}>
                    {tooltipTitle}
                    <div className={styles.linkIcon} onClick={handleSearch(href)}>
                        {commonIcons.link}
                    </div>
                </div>
                }
                <div className={styles.tooltipText}>
                    {tooltipText}
                </div>
                <div className={styles.tooltipTail}>
                    {commonIcons.tooltipTriangle}
                </div>
            </div>
        </div>
    );
};

export default Tooltip;
