import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema6PageIcons } from '../../../../assets/icons/svg';
// COMPONENTS
import Tooltip from '../../schema7/Tooltip';

type Data = {
    button1Text: string,
    button2Text: string,
    arrowLeft?: string,
    arrowLeftText?: string,
    arrowLeftFlip?: boolean,
    arrowRight?: string,
    arrowRightText?: string,
    arrowRightFlip?: boolean,
    direction: string
};

const InfoBlock3 = ({ button1Text, button2Text, arrowLeft, arrowLeftText, arrowLeftFlip, arrowRight, arrowRightText, arrowRightFlip, direction }: Data) => {
    const intl = useIntl();
    const rootClassNames = classNames(styles.wrapper, {
        [styles.directionStart]: direction === 'start',
        [styles.directionEnd]: direction === 'end'
    });
    const arrowRightClassNames = classNames(styles.arrowRight, {
        [styles.arrowRightFlip]: arrowRightFlip
    });
    const arrowLeftClassNames = classNames(styles.arrowLeft, {
        [styles.arrowLeftFlip]: arrowLeftFlip
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.button}>
                {button1Text}
                <Tooltip
                    tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_1' })}
                    tooltipText={<div>
                        <FormattedMessage id='tooltip.text13_2' />
                    </div>}
                    href='partie désignante'
                />
            </div>
            <div className={styles.arrowsContainer}>
                {
                    arrowLeft && <div className={arrowLeftClassNames}>
                        <div className={styles.arrowText}>{arrowLeftText}</div>
                        <div className={styles.arrowIcon}>
                            {
                                arrowLeft === 'solid'
                                    ? schema6PageIcons.arrowSolidBottom
                                    : schema6PageIcons.arrowHollowBottom
                            }
                        </div>
                    </div>
                }
                {
                    arrowRight && <div className={arrowRightClassNames}>
                        <div className={styles.arrowIcon}>
                            {
                                arrowRight === 'solid'
                                    ? schema6PageIcons.arrowSolidTop
                                    : schema6PageIcons.arrowHollowTop
                            }
                        </div>
                        <div className={styles.arrowText}>{arrowRightText}</div>
                    </div>
                }
            </div>
            <div className={styles.button}>
                {button2Text}
                <Tooltip
                    tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_5' })}
                    tooltipText={<div>
                        <FormattedMessage id='tooltip.text13_7' />
                    </div>}
                    href='partie désignée'
                />
            </div>
        </div>
    );
};

export default React.memo(InfoBlock3);
