import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// STORE
import getStore from './store/getStore';
// COMPONENTS
import { App } from './App';

const store = getStore();

export const AppProvider = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    );
};
