import React, { ReactElement } from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';

type Data = {
    texts?: ReactElement<any, any> | string | undefined,
    title: ReactElement<any, any> | string,
    width?: string | undefined,
    texts2?: ReactElement<any, any> | string | undefined,
    moveTop?: boolean,
    moveTopLess?: boolean,
    black?: boolean
};

const Tooltip = ({ title, texts, texts2, width, moveTop, moveTopLess, black }: Data) => {
    const containerClassNames = classNames(styles.toolTip, {
        [styles.moveTop]: moveTop,
        [styles.moveTopLess]: moveTopLess,
        [styles.black]: black
    });

    return (
        <div className={containerClassNames} style={{ maxWidth: width }}>
            <div className={styles.tooltipText}>
                {title}
                {texts && texts}
                {texts2 && texts2}
            </div>
        </div>
    );
};

export default Tooltip;
