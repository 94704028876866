import React, { SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema13View1 from '../../components/schemas/schema13/Schema13View1';
import Schema13View2 from '../../components/schemas/schema13/Schema13View2';
import Schema13View3 from '../../components/schemas/schema13/Schema13View3';
import Schema13View4 from '../../components/schemas/schema13/Schema13View4';
import Schema13View5 from '../../components/schemas/schema13/Schema13View5';
import Schema13View6 from '../../components/schemas/schema13/Schema13View6';
import Schema13View7 from '../../components/schemas/schema13/Schema13View7';
import Schema13View8 from '../../components/schemas/schema13/Schema13View8';
import Schema13View9 from '../../components/schemas/schema13/Schema13View9';
import Schema13View10 from '../../components/schemas/schema13/Schema13View10';
import ArrowNavigation from '../../components/ArrowNavigation';
// CONSTANTS
import { TERMS_LIST_EN_FRANCE } from '../IndexPage/data/vulgapp_terms';
// IMAGES
import { commonIcons } from '../../assets/icons/svg';
// ACTIONS
import setTermPopup from '../../store/application/actions/setTermPopup';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema13Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    const handleStepClick = (index: number | SetStateAction<number>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        handleSetView(index)();
    };

    const handleOpenTermPopup = (id: number | null) => () => {
        if (id) {
            dispatch(setTermPopup(id));
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema13.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema13.introSubTitle' })}
                            textBlockFull={<div>
                                {intl.formatMessage({ id: 'schema13.intro1TextBlock1' })}
                                <div className={styles.links}>
                                    {
                                        TERMS_LIST_EN_FRANCE.map((item, i) => (
                                            <div className={styles.popupLink} key={i} onClick={handleOpenTermPopup(item.pageId)}>
                                                <span className={styles.link}>{item.title}</span>
                                                <span className={styles.linkIcon}>{commonIcons.link2}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>}
                            mainTextBig={true}
                        />
                    }
                    {
                        tab === 1 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema13.introTitle2' })}
                            subTitle={intl.formatMessage({ id: 'schema13.introSubTitle2' })}
                            section1Heading={intl.formatMessage({ id: 'schema13.intro2Section1Heading' })}
                            textBlock1={intl.formatMessage({ id: 'schema13.intro2TextBlock1' })}
                            textBlock2={intl.formatMessage({ id: 'schema13.intro2TextBlock2' })}
                            redNoteTitle={intl.formatMessage({ id: 'schema13.intro2RedNoteTitle' })}
                            redNoteText={intl.formatMessage({ id: 'schema13.intro2RedNoteText' })}
                            blueNoteTitle={intl.formatMessage({ id: 'schema13.intro2BlueNoteTitle' })}
                            blueNoteText={intl.formatMessage({ id: 'schema13.intro2BlueNoteText' })}
                            footer={intl.formatMessage({ id: 'schema13.intro2Footer' })}
                            links={['Link', 'Link', 'Link']}
                        />
                    }
                    {
                        tab === 2 && <Schema13View1/>
                    }
                    {
                        tab === 3 && <Schema13View2/>
                    }
                    {
                        tab === 4 && <Schema13View3 setTab={handleStepClick}/>
                    }
                    {
                        tab === 5 && <Schema13View4 setTab={handleStepClick}/>
                    }
                    {
                        tab === 6 && <Schema13View5 setTab={handleStepClick}/>
                    }
                    {
                        tab === 7 && <Schema13View6 setTab={handleStepClick}/>
                    }
                    {
                        tab === 8 && <Schema13View7 setTab={handleStepClick}/>
                    }
                    {
                        tab === 9 && <Schema13View8 setTab={handleStepClick}/>
                    }
                    {
                        tab === 10 && <Schema13View9 setTab={handleStepClick}/>
                    }
                    {
                        tab === 11 && <Schema13View10 setTab={handleStepClick}/>
                    }
                    {
                        tab === 12 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema13.introTitle3' })}
                            textBlockFull={<div>
                                {/* eslint-disable-next-line max-len */}
                                <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ_l77HQF4dIX6UipWpieUJARRQ3_W6qj8In9x1l2XOrGYOF5xRsWx5pq8WON8uVS6-RNYdB3jIbTKk/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                                <div>{intl.formatMessage({ id: 'schema13.intro3Text' })}</div>
                            </div>}
                            mainTextBig={true}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema13Page;
