import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons, schema13PageIcons } from '../../../../assets/icons/svg';
import figure2 from '../images/figure2.png';
// COMPONENTS
import Note from '../../../../common/components/Note';
import InfoBlock from '../InfoBlock';
import Tooltip from '../Tooltip';
import TooltipSchema7 from '../../schema7/Tooltip';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';

const LEGEND_1 = [
    {
        label: 'A',
        text: <FormattedMessage id='schema13.text1' />,
        class: 'bold',
        id: 'A'
    },
    {
        label: 'B',
        text: <FormattedMessage id='schema13.text2' />,
        class: 'bold',
        id: 'B'
    },
    {
        label: 'C',
        text: <FormattedMessage id='schema13.text3' />,
        class: 'bold',
        id: 'C'
    },
    {
        label: '...',
        text: <FormattedMessage id='schema13.text4' />,
        class: 'common'
    }
];

const LEGEND_2 = [
    {
        label: '1',
        text: <FormattedMessage id='schema13.text5' />,
        class: 'greyColor'
    },
    {
        label: '2',
        text: <FormattedMessage id='schema13.text6' />,
        class: 'blueColor'
    },
    {
        label: '3',
        text: <FormattedMessage id='schema13.text7' />,
        class: 'yellowColor'
    },
    {
        label: schema13PageIcons.legendIcon1,
        text: <FormattedMessage id='schema13.text8' />,
        class: 'common'
    },
    {
        label: schema13PageIcons.legendIcon2,
        text: <FormattedMessage id='schema13.text9' />,
        class: 'common'
    }
];

const CONTENT_1 = [
    {
        title: 'C',
        theme: '',
        fontSize: '',
        tooltipText: <FormattedMessage id='tooltip.text70' />
    },
    {
        title: 'C',
        theme: '',
        fontSize: '',
        tooltipText: <FormattedMessage id='tooltip.text70' />
    },
    {
        title: '...',
        theme: 'transparent',
        fontSize: ''
    }
];

const CONTENT_2 = [
    {
        title: 'C',
        theme: '',
        fontSize: '',
        tooltipText: <FormattedMessage id='tooltip.text70' />
    }
];

const CONTENT_3 = [
    {
        title: 'C',
        theme: '',
        fontSize: '',
        tooltipText: <FormattedMessage id='tooltip.text70' />
    },
    {
        title: '...',
        theme: 'transparent',
        fontSize: ''
    }
];

const Schema13View2 = () => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };

    const screenWidth = useWindowSize().width;
    const rowClassNames = (item: { class: string }) => classNames(styles.row, styles[item.class]);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema13.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema13.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-2:2019, 0.5, Figure 2
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.topWrapper}>
                                    <div className={styles.header}>
                                        <div className={styles.headerTitle}>
                                            A
                                            <div className={styles.tooltip}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text68' })} theme='blue' width='98px'/>
                                            </div>
                                        </div>
                                        <div className={styles.connection}>
                                            {schema13PageIcons.connection7}
                                            <div className={styles.tooltipArrow}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text74' })} theme='red' width='172px'/>
                                            </div>
                                        </div>
                                        <div className={styles.connection2}>
                                            {schema13PageIcons.connection4}
                                            <div className={styles.tooltipArrow}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text75' })} theme='red' width='130px'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.mainContent}>
                                        <div className={styles.itemWrapper}>
                                            <InfoBlock
                                                title='B'
                                                arrow={schema13PageIcons.connection3}
                                                content={CONTENT_1}
                                                contentSubTitle='3'
                                                subTitle='2'
                                                arrow2={schema13PageIcons.arrow2}
                                                size='small'
                                                sideConnection={schema13PageIcons.arrowHorizontal1}
                                                tooltipText={intl.formatMessage({ id: 'tooltip.text69' })}
                                                tooltipText2={intl.formatMessage({ id: 'tooltip.text72' })}
                                                tooltipText3={intl.formatMessage({ id: 'tooltip.text73' })}
                                                tooltipArrow1={intl.formatMessage({ id: 'tooltip.text75' })}
                                                tooltipArrow2={intl.formatMessage({ id: 'tooltip.text74' })}
                                                tooltipArrow3={intl.formatMessage({ id: 'tooltip.text75' })}
                                            />
                                        </div>
                                        <div className={styles.itemWrapper}>
                                            <InfoBlock
                                                title='B'
                                                arrow={schema13PageIcons.connection3}
                                                content={CONTENT_1}
                                                arrow2={schema13PageIcons.arrow2}
                                                size='small'
                                                theme='transparent'
                                                sideConnection={schema13PageIcons.arrowHorizontal2}
                                                tooltipText={intl.formatMessage({ id: 'tooltip.text69' })}
                                                tooltipArrow1={intl.formatMessage({ id: 'tooltip.text75' })}
                                                tooltipArrow2={intl.formatMessage({ id: 'tooltip.text74' })}
                                                tooltipArrow3={intl.formatMessage({ id: 'tooltip.text75' })}
                                            />
                                        </div>
                                        <div className={styles.itemWrapper}>
                                            <InfoBlock
                                                title='...'
                                                arrow={schema13PageIcons.connection3}
                                                content={CONTENT_1}
                                                arrow2={schema13PageIcons.arrow2}
                                                size='small'
                                                theme='transparent'
                                                sideConnection={schema13PageIcons.arrowHorizontal3}
                                                tooltipArrow1={intl.formatMessage({ id: 'tooltip.text75' })}
                                                tooltipArrow2={intl.formatMessage({ id: 'tooltip.text74' })}
                                                tooltipArrow3={intl.formatMessage({ id: 'tooltip.text75' })}
                                            />
                                        </div>
                                        <div className={styles.itemWrapper}>
                                            <InfoBlock
                                                title='B'
                                                arrow={schema13PageIcons.connection1}
                                                content={CONTENT_2}
                                                arrow2={schema13PageIcons.arrow2}
                                                size='small'
                                                theme='transparent'
                                                sideConnection={schema13PageIcons.arrowHorizontal4}
                                                tooltipText={intl.formatMessage({ id: 'tooltip.text69' })}
                                                tooltipArrow1={intl.formatMessage({ id: 'tooltip.text75' })}
                                                tooltipArrow2={intl.formatMessage({ id: 'tooltip.text74' })}
                                                tooltipArrow3={intl.formatMessage({ id: 'tooltip.text75' })}
                                            />
                                        </div>
                                        <div className={styles.itemWrapper}>
                                            <InfoBlock
                                                title='B'
                                                arrow={schema13PageIcons.connection1_1}
                                                content={CONTENT_3}
                                                arrow2={schema13PageIcons.arrow2}
                                                size='small'
                                                theme='transparent'
                                                arrowPosition='right'
                                                tooltipText={intl.formatMessage({ id: 'tooltip.text69' })}
                                                tooltipArrow2={intl.formatMessage({ id: 'tooltip.text74' })}
                                                tooltipArrow3={intl.formatMessage({ id: 'tooltip.text75' })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.bottomWrapper}>
                                    <div className={styles.tableTitle}>
                                        <FormattedMessage id='schema13.legend' />
                                    </div>
                                    <div className={styles.table}>
                                        {
                                            LEGEND_1.map((item, i) => (
                                                <div className={rowClassNames(item)} key={i}>
                                                    <div className={styles.cell1}>{item.label}</div>
                                                    <div className={styles.cell2}>
                                                        {item.text}
                                                        {
                                                            item.id === 'A' &&
                                                            <TooltipSchema7
                                                                tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_1' })}
                                                                tooltipText={<div>
                                                                    <FormattedMessage id='tooltip.text13_2' />
                                                                </div>}
                                                                href='partie désignante'
                                                                start={true}
                                                            />
                                                        }
                                                        {
                                                            item.id === 'B' &&
                                                            <TooltipSchema7
                                                                tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_5' })}
                                                                tooltipText={<div>
                                                                    <FormattedMessage id='tooltip.text13_7' />
                                                                </div>}
                                                                href='partie désignée'
                                                                start={true}
                                                            />
                                                        }
                                                        {
                                                            item.id === 'C' &&
                                                            <TooltipSchema7
                                                                tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_6' })}
                                                                tooltipText={<div>
                                                                    <FormattedMessage id='tooltip.text13_8' />
                                                                </div>}
                                                                href='partie désignée'
                                                                start={true}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={styles.table}>
                                        {
                                            LEGEND_2.map((item, i) => (
                                                <div className={rowClassNames(item)} key={i}>
                                                    <div className={styles.cell1}>{item.label}</div>
                                                    <div className={styles.cell2}>{item.text}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteText56' /></p><p><FormattedMessage id='note.noteText55' /></p></div>} />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema13.figure2Title' })}
                    label='NF EN ISO 19650-2:2019, 0.5'
                    caption={intl.formatMessage({ id: 'schema13.figure2' })}
                    image={figure2}
                    id={129}
                />
            }
        </div>
    );
};

export default Schema13View2;
