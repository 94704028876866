import React from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema7PageIcons } from '../../../../assets/icons/svg';

type Data = {
    step: string,
    stepText: string,
    stepStartText: string,
    stepEndText: string,
    arrowSliced?: boolean,
    color?: string,
    label?: string
};

const InfoBlock1 = ({ stepText, stepStartText, stepEndText, arrowSliced, step, color, label }: Data) => {
    const rootClassNames = classNames(styles.wrapper, {
        [styles.grey]: color === 'grey',
        [styles.greyRed]: color === 'greyRed'
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.arrowBlock}>
                {
                    !arrowSliced
                        ? schema7PageIcons.arrowOrange
                        : schema7PageIcons.arrowOrangeSliced
                }
                <div className={styles.arrowText}>{stepText}</div>
                {
                    arrowSliced && <div className={styles.arrowTextDots}>...</div>
                }
            </div>
            <div className={styles.mainBlock}>
                <div className={styles.leftBlock}>
                    <div className={styles.mainBlockText}>
                        {stepStartText}
                    </div>
                    <div className={styles.icon}>
                        {schema7PageIcons.arrowsRound}
                    </div>
                </div>
                <div className={styles.middleBlock}>
                    <div className={styles.square}>
                        <div className={styles.iconText}>
                            {step}
                        </div>
                    </div>
                    <div className={styles.arrow}>
                        {schema7PageIcons.arrowLong}
                    </div>
                    <div className={styles.circle}>
                        <div className={styles.iconText}>
                            {step}
                        </div>
                    </div>
                    <div className={styles.arrow}>
                        {schema7PageIcons.arrowShort}
                    </div>
                    {
                        label &&
                        <div className={styles.label}>{label}</div>
                    }
                </div>
                <div className={styles.rightBlock}>
                    <div className={styles.mainBlockText}>
                        {stepEndText}
                    </div>
                    <div className={styles.icon}>
                        {schema7PageIcons.arrowsRound}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(InfoBlock1);
