import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { commonIcons, schema13PageIcons } from '../../../../assets/icons/svg';
import figure2 from '../images/figure2.png';
// COMPONENTS
import InfoBlock from '../InfoBlock';
import Note from '../../../../common/components/Note';
import Tooltip from '../Tooltip';
import Selector from '../Selector/index';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
import selectorOption from '../Schema13View7/selectorOptions';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';

const CONTENT_1 = [
    {
        title: <FormattedMessage id='schema13.block4Content1' />,
        theme: '',
        fontSize: 'medium'
    },
    {
        title: <FormattedMessage id='schema13.block4Content1' />,
        theme: '',
        fontSize: 'medium'
    },
    {
        title: <FormattedMessage id='schema13.block4Content3' />,
        theme: '',
        fontSize: 'small'
    }
];

const CONTENT_4 = [
    {
        title: 'C',
        theme: '',
        fontSize: '',
        tooltipText: <FormattedMessage id='tooltip.text70' />
    }
];

type Schema13View7Props = {
    setTab: React.Dispatch<React.SetStateAction<number>>
}

const Schema13View7 = ({ setTab }:Schema13View7Props) => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };

    const screenWidth = useWindowSize().width;

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema13.exampleTitle4' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema13.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-2:2019, 0.5, Figure 2
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.topWrapper}>
                                    <div className={styles.topLabel}>
                                        <FormattedMessage id='schema13.topLabel1' />
                                        <p className={styles.topLabelDetails}>
                                            <FormattedMessage id='schema13.topLabelDetails1' />
                                        </p>
                                    </div>

                                    <div className={styles.header}>
                                        <div className={styles.headerTitle}>
                                            <FormattedMessage id='schema13.infoTitle3' />
                                        </div>
                                        <div className={styles.connection}>
                                            {schema13PageIcons.connection9}
                                            <div className={styles.tooltipArrow}>
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text74' })} theme='red' width='172px'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.mainContent}>
                                        <div className={styles.itemWrapper}>
                                            <InfoBlock
                                                title={intl.formatMessage({ id: 'schema13.block3Title' })}
                                                arrow={schema13PageIcons.connection3_1}
                                                content={CONTENT_1}
                                                contentSubTitle={intl.formatMessage({ id: 'schema13.block1ContentSubTitle' })}
                                                subTitle={intl.formatMessage({ id: 'schema13.block1SubTitle' })}
                                                arrow2={schema13PageIcons.arrow1}
                                                sideConnection={schema13PageIcons.arrowHorizontal11}
                                                tooltipArrow1={intl.formatMessage({ id: 'tooltip.text75' })}
                                                tooltipArrow2={intl.formatMessage({ id: 'tooltip.text74' })}
                                                tooltipArrow3={intl.formatMessage({ id: 'tooltip.text75' })}
                                            />
                                        </div>
                                        <div className={styles.itemWrapper}>
                                            <InfoBlock
                                                title='...'
                                                arrow={schema13PageIcons.connection1_2}
                                                content={CONTENT_4}
                                                theme='transparent'
                                                disabled={true}
                                                size='narrow'
                                                tooltipArrow4={intl.formatMessage({ id: 'tooltip.text75' })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.bottomWrapper}>
                                    <Selector
                                        setTab={ setTab }
                                        buttonWhite={intl.formatMessage({ id: 'schema13.buttonText2' })}
                                        buttonBlue={intl.formatMessage({ id: 'schema13.buttonText6' })}
                                        selectorOption={selectorOption}
                                    />
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note
                    content={<div><p><FormattedMessage id='note.noteText55' /></p></div>}
                />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema13.figure2Title' })}
                    label='NF EN ISO 19650-2:2019, 0.5'
                    caption={intl.formatMessage({ id: 'schema13.figure2' })}
                    image={figure2}
                    id={129}
                />
            }
        </div>
    );
};

export default Schema13View7;
