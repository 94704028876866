import React, { ReactElement } from 'react';
// STYLES
import styles from './styles.module.scss';
// ICONS

type StepBlockProps = {
    word: string;
    arrow: Element | ReactElement<any, any> | string;
};

const StepBlock = ({ word, arrow }: StepBlockProps) => {
    return (
        <div className={styles.stepsBlock}>
            <div className={styles.horizontalArrow}>{arrow}</div>
            <div className={styles.stepText}>{word}</div>
            <div className={styles.horizontalArrow}>{arrow}</div>
        </div>
    );
};

export default React.memo(StepBlock);
