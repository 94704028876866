import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import Brick from './images/brickImage.png';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';

const Schema9View2 = () => {
    const screenWidth = useWindowSize().width;
    const contentTextClassNames = classNames(styles.contentText, styles.contentTextSecond);
    const contentHintClassNames = classNames(styles.contentHint, styles.imageHint);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema9.title' />
                </div>
                <div className={styles.subTitle}>
                    <div className={styles.isoLabel}>
                        Plan BIM 2022
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.cardsWrapper}>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <div className={styles.header}>
                                                <FormattedMessage id='schema9.block1Title' />
                                            </div>
                                            <div className={styles.content}>
                                                <div className={styles.contentText}>
                                                    <FormattedMessage id='schema9.block1Text1' />
                                                </div>
                                                <div className={styles.contentHint}>
                                                    <FormattedMessage id='schema9.block1Text3' />
                                                </div>
                                                <div className={contentTextClassNames}>
                                                    <FormattedMessage id='schema9.block1Text2' />
                                                </div>
                                                <div className={styles.contentHint}>
                                                    <FormattedMessage id='schema9.block1Text4' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <div className={styles.header}>
                                                <FormattedMessage id='schema9.block2Title' />
                                            </div>
                                            <div className={styles.content}>
                                                <div className={styles.contentImage}>
                                                    <img src={Brick} alt='brick' className={styles.image}/>
                                                </div>
                                                <div className={contentHintClassNames}>
                                                    <FormattedMessage id='schema9.block2Text1' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
            </div>
        </div>
    );
};

export default Schema9View2;
