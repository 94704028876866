import React from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema7PageIcons } from '../../../../assets/icons/svg';

type Data = {
    step: string,
    stepText: string,
    stepLink: string,
    color?: string,
    label?: string
};

const InfoBlock2 = ({ stepText, stepLink, step, color, label }: Data) => {
    const rootClassNames = classNames(styles.wrapper, {
        [styles.grey]: color === 'grey'
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.arrowBlock}>
                {schema7PageIcons.arrowOrangeBig}
                <div className={styles.arrowText}>{stepText} <a href='' className={styles.arrowTextLink}>{stepLink}</a></div>
            </div>
            <div className={styles.mainBlock}>
                <div className={styles.middleBlock}>
                    <div className={styles.square}>
                        <div className={styles.iconText}>
                            {step}
                        </div>
                    </div>
                    <div className={styles.arrow}>
                        {schema7PageIcons.arrowLong}
                    </div>
                    <div className={styles.circle}>
                        <div className={styles.iconText}>
                            {step}
                        </div>
                    </div>
                    <div className={styles.arrow}>
                        {schema7PageIcons.arrowShort}
                    </div>
                    {
                        label &&
                        <div className={styles.label}>{label}</div>
                    }
                </div>
                <div className={styles.middleBlock}>
                    <div className={styles.square}>
                        <div className={styles.iconText}>
                            {step}
                        </div>
                    </div>
                    <div className={styles.arrow}>
                        {schema7PageIcons.arrowLong}
                    </div>
                    <div className={styles.circle}>
                        <div className={styles.iconText}>
                            {step}
                        </div>
                    </div>
                    <div className={styles.arrow}>
                        {schema7PageIcons.arrowShort}
                    </div>
                    {
                        label &&
                        <div className={styles.label}>{label}</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(InfoBlock2);
