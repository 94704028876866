import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import highlightBasic1 from './images/HighlightBox1.svg';
import highlightBasic2 from './images/HighlightBox2.svg';
import highlightHover1 from './images/HighlightBox1_1.svg';
import highlightHover2 from './images/HighlightBox2_1.svg';
import { commonIcons, schema1PageIcons } from '../../../../assets/icons/svg';
import figure2 from '../images/figure2.png';
// COMPONENTS
import { TOOLTIP_7, TOOLTIP_8 } from '../tooltipsData';
import Tooltip from '../Tooltip';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema1View5 = () => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };

    const screenWidth = useWindowSize().width;
    const deepBlueContainer = classNames(styles.infoBlock, styles.deepBlue);
    const darkBlueContainer = classNames(styles.infoBlock, styles.darkBlue);
    const figureBasicSmall = classNames(styles.figureBasic, styles.small);
    const figureHoverSmall = classNames(styles.figureHover, styles.small);
    const figureBasicBig = classNames(styles.figureBasic, styles.big);
    const figureHoverBig = classNames(styles.figureHover, styles.big);
    const textBlockHoverable = classNames(styles.textBlock, styles.hoverable);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema1.title5' />
                </div>
                <div className={styles.subTitle}>
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 5.1, Figure 2
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoBlock}>
                                    <div className={styles.header}>
                                        <FormattedMessage id='schema1.block1Title' />
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.textBlock}>
                                            <div className={styles.hoverable}>
                                                <div className={styles.text}>
                                                    <FormattedMessage id='schema1.block1Text1' />
                                                </div>
                                                <div className={figureBasicSmall}>
                                                    <img className={styles.figure} alt='highlight' src={highlightBasic1}/>
                                                </div>
                                                <div className={figureHoverSmall}>
                                                    <img className={styles.figure} alt='highlight' src={highlightHover1}/>
                                                </div>
                                                <Tooltip
                                                    texts={<ul>{TOOLTIP_7.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                                    title={<p>{TOOLTIP_7.title}</p>}
                                                    width={TOOLTIP_7.width}
                                                    moveTop={true}
                                                    black={true}
                                                />
                                                <div className={styles.sideLink}>
                                                    <div className={styles.arrow}>
                                                        {schema1PageIcons.arrow2}
                                                    </div>
                                                    <div className={styles.midBlock}>
                                                        <FormattedMessage id='schema1.midBlockText2' />
                                                    </div>
                                                    <div className={styles.arrow}>
                                                        {schema1PageIcons.arrow2}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.midBlock}>
                                            <FormattedMessage id='schema1.midBlockText1' />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={textBlockHoverable}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block1Text2' />
                                            </div>
                                            <div className={figureBasicBig}>
                                                <img className={styles.figure} alt='highlight' src={highlightBasic2}/>
                                            </div>
                                            <div className={figureHoverBig}>
                                                <img className={styles.figure} alt='highlight' src={highlightHover2}/>
                                            </div>
                                            <Tooltip
                                                texts2={<p>{TOOLTIP_8.texts2}</p>}
                                                title={<p>{TOOLTIP_8.title}</p>}
                                                width={TOOLTIP_8.width}
                                                black={true}
                                            />
                                            <div className={styles.sideLink}>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                                <div className={styles.midBlock}>
                                                    <FormattedMessage id='schema1.midBlockText1' />
                                                </div>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={deepBlueContainer}>
                                    <div className={styles.header}>
                                        <FormattedMessage id='schema1.block2Title' />
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block2Text1' />
                                            </div>
                                            <div className={styles.sideLink}>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                                <div className={styles.midBlock}>
                                                    <FormattedMessage id='schema1.midBlockText3' />
                                                </div>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.midBlock}>
                                            <FormattedMessage id='schema1.midBlockText1' />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block2Text2' />
                                            </div>
                                            <div className={styles.sideLink}>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                                <div className={styles.midBlock}>
                                                    <FormattedMessage id='schema1.midBlockText3' />
                                                </div>
                                                <div className={styles.arrow}>
                                                    {schema1PageIcons.arrow2}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={darkBlueContainer}>
                                    <div className={styles.header}>
                                        <FormattedMessage id='schema1.block3Title' />
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block3Text1' />
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.midBlock}>
                                            <FormattedMessage id='schema1.midBlockText4' />
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema1PageIcons.arrow1}
                                        </div>
                                        <div className={styles.textBlock}>
                                            <div className={styles.text}>
                                                <FormattedMessage id='schema1.block3Text2' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema1.figure2Title' })}
                    label='NF EN ISO 19650-1:2019, 5.1, Figure 2'
                    caption={intl.formatMessage({ id: 'schema1.figure2' })}
                    image={figure2}
                    id={113}
                />
            }
        </div>
    );
};

export default Schema1View5;
