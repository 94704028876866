import React, { ReactElement } from 'react';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { commonIcons } from '../../../assets/icons/svg';
import classNames from 'classnames';

type Data = {
    content: ReactElement<any, any> | string,
    theme?: string
}

const Note = ({ content, theme }: Data) => {
    const rootClassNames = classNames(styles.note, {
        [styles.themeBlue]: theme === 'blue'
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.info}>
                {commonIcons.info}
            </div>
            <div className={styles.text}>
                {content}
            </div>
        </div>
    );
};

export default Note;
