import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons } from '../../assets/icons/svg';
import LogoLeft from './images/MinistereChargeDuLogement.svg';
import LogoLeftSmall from './images/MinistereChargeDuLogementSmall.svg';
import LogoRight from './images/PlanBimLogo.png';
import LogoRightSmall from './images/PlanBimLogoSmall.png';
// UTILS
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// ACTIONS
import setNavigationPopup from '../../store/application/actions/setNavigationPopup';
import setSearchQuery from '../../store/application/actions/setSearchQuery';
import setPageIdBack from '../../store/application/actions/setPageIdBack';
import setPageId from '../../store/application/actions/setPageId';
import setSearchOpen from '../../store/application/actions/setSearchOpen';
// SELECTORS
import { getPageId, getPageIdBack, getSearchOpen, getSearchQuery } from '../../store/application/selectors/selectors';
import useWindowSize from '../../common/hooks/useWindowSize';
import { TABLET_WIDTH } from '../../common/constants/constants';
// COMPONENTS
import Tooltip from './Tooltip';

const Header = () => {
    const intl = useIntl();
    const [searchVisible, setSearchVisible] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [pageIsScrolled, setPageIsScrolled] = useState(false);
    const mobileMenuRef = useRef<any>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const pageId = useSelector(getPageId);
    const screenWidth = useWindowSize().width;
    const searchInput = useRef<any>(null);
    const pageIdBack = useSelector(getPageIdBack);
    const searchQuery = useSelector(getSearchQuery);
    const searchOpen = useSelector(getSearchOpen);

    const handleChange = (event: { target: HTMLInputElement; }) => {
        setSearchValue(event.target.value);
    };

    useEffect(() => {
        if (mobileMenuRef) {
            if (mobileMenu) {
                disableBodyScroll(mobileMenuRef.current);
            } else {
                enableBodyScroll(mobileMenuRef.current);
            }
        }
    }, [mobileMenu]);

    const closeMobileMenu = () => {
        setMobileMenu(false);
    };

    const openMobileMenu = () => {
        setMobileMenu(true);
    };

    const handleChangeView = (pageId: number) => () => {
        dispatch(setPageId(pageId));
        history.push(`/page/${pageId}`);

        if (screenWidth <= TABLET_WIDTH) {
            closeMobileMenu();
        }
    };

    const handleClearSearch = () => {
        setSearchValue('');
    };

    const handleSearchClick = () => {
        setSearchVisible(!searchVisible);
        dispatch(setSearchOpen(!searchVisible));
    };

    useEffect(() => {
        if (searchOpen !== searchVisible) {
            setSearchVisible(searchOpen);
        }
    }, [searchOpen]);

    const handleGoBack = () => {
        if (pageIdBack) {
            handleChangeView(pageIdBack)();
            dispatch(setPageIdBack(null));
            handleClearSearch();
        }
    };

    useEffect(() => {
        if (searchVisible) {
            searchInput.current.focus();
        }
    }, [searchVisible]);

    useEffect(() => {
        dispatch(setSearchQuery(searchValue));
        if (searchValue && (pageId !== 109)) {
            handleChangeView(109)();
            dispatch(setPageIdBack(pageId));
        }
    }, [searchValue]);

    useEffect(() => {
        if (searchVisible && (pageId !== 109)) {
            handleClearSearch();
            setSearchVisible(false);
        }

        if (pageId === 109) {
            setSearchVisible(true);
        }
    }, [pageId]);

    useEffect(() => {
        if (searchValue !== searchQuery) {
            setSearchValue(searchQuery);
        }
    }, [searchQuery]);

    const handleScroll: EventListener = (event: Event | any) => {
        const scrollTop = event.target.scrollingElement.scrollTop;

        if (!scrollTop) {
            setPageIsScrolled(false);
        } else if (scrollTop && !pageIsScrolled) {
            setPageIsScrolled(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);

            clearAllBodyScrollLocks();
        };
    }, []);

    const handleNavigationOpen = () => {
        dispatch(setNavigationPopup(true));

        if (screenWidth <= TABLET_WIDTH) {
            closeMobileMenu();
        }
    };

    const burgerClassNames = classNames(styles.burgerButton, {
        [styles.opened]: mobileMenu
    });
    const mobileMenuClassNames = classNames(styles.mobileMenu, {
        [styles.opened]: mobileMenu
    });
    const searchContainerClassNames = classNames(styles.searchContainer, {
        [styles.searchVisible]: searchVisible
    });
    const rootClassNames = classNames(styles.root, {
        [styles.headerSmall]: pageIsScrolled,
        [styles.searchVisible]: searchVisible
    });
    const middleLinksLeftClassNames = classNames(styles.middleLinks, styles.middleLinksLeft);
    const middleLinksRightClassNames = classNames(styles.middleLinks, styles.middleLinksRight);
    const middleLinksTopClassNames = classNames(styles.middleLinks, styles.middleLinksTop);
    const middleLinksBottomClassNames = classNames(styles.middleLinks, styles.middleLinksBottom);

    return (
        <div className={rootClassNames}>
            <div className={styles.headerContainer}>
                <div className={styles.headerWrapper}>
                    <div className={styles.logoLeft} onClick={handleChangeView(1)}>
                        <img className={styles.logo} src={LogoLeft} alt='Ministere Charge Du Logement'/>
                        <img className={styles.logoSmall} src={LogoLeftSmall} alt='Ministere Charge Du Logement'/>
                    </div>
                    <div className={styles.menuWrapper}>
                        <div className={styles.link} onClick={handleNavigationOpen}>
                            <FormattedMessage id='header.link1' />
                        </div>
                        <div className={middleLinksLeftClassNames}>
                            <div className={styles.link} onClick={handleChangeView(2)}>
                                <FormattedMessage id='header.link2' />
                            </div>
                            <div className={styles.link} onClick={handleChangeView(8)}>
                                <FormattedMessage id='header.link3' />
                            </div>
                            <div className={styles.link} onClick={handleChangeView(71)}>
                                <FormattedMessage id='header.link4' />
                            </div>
                        </div>
                        <div className={middleLinksRightClassNames}>
                            <div className={styles.link} onClick={handleChangeView(84)}>
                                <FormattedMessage id='header.link6' />
                            </div>
                            <div className={styles.link} onClick={handleChangeView(95)}>
                                <FormattedMessage id='header.link7' />
                            </div>
                        </div>
                        <div className={styles.link} onClick={handleChangeView(109)}>
                            <FormattedMessage id='header.link5' />
                        </div>
                        <div className={burgerClassNames}>
                            <div className={styles.open} onClick={openMobileMenu}>
                                <div className={styles.line}/>
                                <div className={styles.line}/>
                                <div className={styles.line}/>
                            </div>
                            <div className={styles.close} onClick={closeMobileMenu}>
                                {commonIcons.close}
                            </div>
                        </div>
                        <div className={styles.searchButton} onClick={handleSearchClick}>
                            <div className={styles.searchIcon}>
                                {commonIcons.search}
                            </div>
                        </div>
                    </div>
                    <a href='https://plan-bim-2022.fr/' target='_blank' className={styles.logoRight}>
                        <img className={styles.logo} src={LogoRight} alt='Plan BIM 2022'/>
                        <img className={styles.logoSmall} src={LogoRightSmall} alt='Plan BIM 2022'/>
                    </a>
                </div>
                <div className={searchContainerClassNames}>
                    <div className={styles.searchWrapper}>
                        <form className={styles.inputWrapper}>
                            <div className={styles.searchIcon}>
                                {commonIcons.search}
                            </div>
                            <input
                                placeholder={intl.formatMessage({ id: 'header.searchPlaceholder' })}
                                className={styles.input}
                                value={searchValue}
                                onChange={handleChange}
                                ref={searchInput}
                            />
                        </form>
                        {
                            !!pageIdBack &&
                        <div className={styles.back} onClick={handleGoBack}>
                            {commonIcons.back}
                            <Tooltip content={<div><FormattedMessage id='tooltip.text89' /></div>}/>
                        </div>
                        }
                        <div className={styles.close} onClick={handleClearSearch}>
                            {commonIcons.close2}
                            <Tooltip content={<div><FormattedMessage id='tooltip.text88' /></div>}/>
                        </div>
                    </div>
                </div>
                <div className={mobileMenuClassNames} ref={mobileMenuRef}>
                    <div className={styles.link} onClick={handleNavigationOpen}>
                        <FormattedMessage id='header.link1' />
                    </div>
                    <div className={middleLinksTopClassNames}>
                        <div className={styles.link} onClick={handleChangeView(2)}>
                            <FormattedMessage id='header.link2' />
                        </div>
                        <div className={styles.link} onClick={handleChangeView(8)}>
                            <FormattedMessage id='header.link3' />
                        </div>
                        <div className={styles.link} onClick={handleChangeView(71)}>
                            <FormattedMessage id='header.link4' />
                        </div>
                    </div>
                    <div className={middleLinksBottomClassNames}>
                        <div className={styles.link} onClick={handleChangeView(84)}>
                            <FormattedMessage id='header.link6' />
                        </div>
                        <div className={styles.link} onClick={handleChangeView(98)}>
                            <FormattedMessage id='header.link7' />
                        </div>
                    </div>
                    <div className={styles.link} onClick={handleChangeView(109)}>
                        <FormattedMessage id='header.link5' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
