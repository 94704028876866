import React from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema12PageIcons } from '../../../../assets/icons/svg';
// COMPONENTS
import Tooltip from '../Tooltip';

type Data = {
    content: string,
    theme?: string,
    plus?: boolean,
    marginsBig?: boolean,
    marginsMiddle?: boolean,
    marginRight?: boolean,
    marginLeft?: boolean,
    marginRightShort?: boolean,
    marginLeftShort?: boolean,
    arrow?: string,
    index?: number,
    tooltipContent?: string,
    tooltipWidth?: string,
    tooltipReversed?: boolean,
    moveBottom?: boolean,
    step?: number,
    handleStepClick?: React.Dispatch<React.SetStateAction<number>>
}

const Item = (
    {
        content,
        theme,
        plus,
        marginsBig,
        marginsMiddle,
        marginRight,
        marginRightShort,
        marginLeft,
        marginLeftShort,
        arrow,
        index,
        tooltipContent,
        tooltipWidth,
        tooltipReversed,
        moveBottom,
        step,
        handleStepClick
    }: Data) => {
    const rootClassNames = classNames(styles.item, {
        [styles.themeBasic]: theme === 'basic',
        [styles.themeGrey]: theme === 'grey',
        [styles.plus]: plus,
        [styles.marginsBig]: marginsBig,
        [styles.marginsMiddle]: marginsMiddle,
        [styles.marginRight]: marginRight,
        [styles.marginLeft]: marginLeft,
        [styles.marginRightShort]: marginRightShort,
        [styles.marginLeftShort]: marginLeftShort,
        [styles.moveBottom]: moveBottom
    });

    const handleSetStep = (step: number | undefined) => () => {
        if (!step || !handleStepClick) {
            return;
        }

        handleStepClick(step);
    };

    return <div className={rootClassNames} style={{ zIndex: index }} onClick={handleSetStep(step)}>
        <div className={styles.text}>
            {content}
            <div className={styles.plusIcon}>{schema12PageIcons.plusIcon}</div>
        </div>
        {
            arrow === 'long' && <div className={styles.arrow}>{schema12PageIcons.arrowLong}</div>
        }
        {
            arrow === 'short' && <div className={styles.arrow}>{schema12PageIcons.arrowShort}</div>
        }
        {
            tooltipContent && <Tooltip theme='red' content={tooltipContent} width={tooltipWidth} direction={tooltipReversed ? 'reversed' : ''}/>
        }
    </div>;
};

export default React.memo(Item);
