import React from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema13PageIcons } from '../../../../assets/icons/svg';

type Data = {
    buttonBlue?: Element | string | undefined,
    buttonWhite?: Element | string | undefined,
    dropDownOpen?: boolean,
    setTab: React.Dispatch<React.SetStateAction<number>>,
    selectorOption?: Array<{
        title: string,
        subTitle: string,
        tab: number
    }>
}

const InfoBlock2 = ({ buttonBlue, buttonWhite, dropDownOpen, setTab, selectorOption }: Data) => {
    const buttonWhiteClassNames = classNames(styles.button, styles.buttonWhite);
    const buttonBlueClassNames = classNames(styles.button, styles.buttonBlue);
    const buttonWrapperClassNames = classNames(styles.buttonWrapper, {
        [styles.open]: dropDownOpen
    });
    const iconClassNames = classNames(styles.icon, { [styles.iconRotated]: dropDownOpen });
    const dropDownClassNames = classNames(styles.dropDown, {
        [styles.dropDownOpen]: dropDownOpen
    });

    const handleTabChange = (tabIndex: number) => () => {
        document.body.scrollIntoView();
        setTab(tabIndex);
    };

    return (
        <div className={styles.root}>
            {buttonWhite &&
            <div className={buttonWhiteClassNames} onClick={handleTabChange(99)}>
                {buttonWhite}
                <div className={styles.icon}>{schema13PageIcons.buttonIcon2}</div>
            </div>
            }
            {buttonBlue &&
            <div className={buttonWrapperClassNames}>
                <div className={buttonBlueClassNames}>
                    {buttonBlue}
                    <div className={iconClassNames}>{schema13PageIcons.buttonIcon1}</div>
                </div>
                <div
                    className={dropDownClassNames}
                >
                    {selectorOption && selectorOption.map(({ title, subTitle, tab }) => (
                        <div
                            key={title}
                            className={styles.dropDownItem}
                            onClick={handleTabChange(tab)}
                        >
                            <p className={styles.dropDownItemTitle}>{title}</p>
                            <span className={styles.dropDownItemSubTitle}>{subTitle}</span>
                        </div>
                    ))}
                </div>
            </div>
            }
        </div>
    );
};

export default React.memo(InfoBlock2);
