import React from 'react';
import styles from './styles.module.scss';
import { schema13PageIcons } from '../../../../assets/icons/svg';
import classNames from 'classnames';

type BottomLineProps = {
    firstWord: string;
    secondWord?: string;
    withConseption?: boolean;
};

const BottomLine = ({ firstWord, secondWord, withConseption }: BottomLineProps) => {
    const rootClassNames = classNames(styles.bottomLine, {
        [styles.bottomLineLonger]: withConseption
    });

    return (
        <div className={rootClassNames}>
            {withConseption && (
                <>
                    <div>{schema13PageIcons.bottomLine3}</div>
                    <div className={styles.text2}>{secondWord}</div>
                    <div>{schema13PageIcons.bottomLine4}</div>
                </>
            )}
            <div>{schema13PageIcons.bottomLine1}</div>
            <div className={styles.text}>{firstWord}</div>
            <div>{schema13PageIcons.bottomLine2}</div>
        </div>
    );
};

export default React.memo(BottomLine);
