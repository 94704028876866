import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { commonIcons } from '../../../assets/icons/svg';
// UTILS
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// ACTIONS
import setAboutPopup from '../../../store/application/actions/setAboutPopup';

const AboutPopup = () => {
    const dispatch = useDispatch();
    const popupRef = useRef<any>(null);

    useEffect(() => {
        disableBodyScroll(popupRef.current);

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const handleClosePopup = () => {
        dispatch(setAboutPopup(false));
    };

    return (
        <div className={styles.root}>
            <div className={styles.background} onClick={handleClosePopup}/>
            <div className={styles.container} ref={popupRef}>
                <div className={styles.header}>
                    <FormattedMessage id='aboutPopup.title' />
                    <div className={styles.closeIcon} onClick={handleClosePopup}>
                        {commonIcons.close}
                    </div>
                </div>
                <div className={styles.body}>
                    <FormattedMessage id='aboutPopup.text1' />
                    <span className={styles.blueText}><FormattedMessage id='aboutPopup.text2' /></span>.
                    <br/>
                    <FormattedMessage id='aboutPopup.text3' />
                    <br/>
                    <FormattedMessage id='aboutPopup.text4' />
                    <br/>
                    <FormattedMessage id='aboutPopup.text5' />
                    <span className={styles.blueText}><FormattedMessage id='aboutPopup.text2' /></span>.
                    <div className={styles.title}><FormattedMessage id='aboutPopup.text6' /></div>
                    <FormattedMessage id='aboutPopup.text7' />
                </div>
                <div className={styles.id}>
                    Page ID: 111
                </div>
            </div>
        </div>
    );
};

export default AboutPopup;
