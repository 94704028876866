import React from 'react';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import Schema7View1 from '../../components/schemas/schema7/Schema7View1';
import Schema7View2 from '../../components/schemas/schema7/Schema7View2';
import Schema7View3 from '../../components/schemas/schema7/Schema7View3';
import Schema7View4 from '../../components/schemas/schema7/Schema7View4';
import Schema7View5 from '../../components/schemas/schema7/Schema7View5';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema7Page = ({ handleSetView, forward, back, tab }: Props) => {
    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <Schema7View1/>
                    }
                    {
                        tab === 1 && <Schema7View2/>
                    }
                    {
                        tab === 2 && <Schema7View3/>
                    }
                    {
                        tab === 3 && <Schema7View4/>
                    }
                    {
                        tab === 4 && <Schema7View5/>
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema7Page;
