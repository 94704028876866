import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons, schema12PageIcons } from '../../../../assets/icons/svg';
import figure4 from './../images/figure4.png';
// COMPONENTS
import Note from '../../../../common/components/Note';
import TopContent from '../TopContent';
import Item from '../Item';
import Tooltip from '../Tooltip';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';

const LABELS_1 = [
    {
        title: <FormattedMessage id='schema12.labelText1' />,
        theme: 'navyTheme',
        shape: 'round',
        tooltip: <div>
            <FormattedMessage id='tooltip.text85_1' />
            <ul>
                <li>
                    <b><FormattedMessage id='tooltip.text85_2' /></b>
                    <FormattedMessage id='tooltip.text85_3' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text85_4' /></b>
                    <FormattedMessage id='tooltip.text85_5' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text85_6' /></b>
                    <FormattedMessage id='tooltip.text85_7' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text85_8' /></b>
                    <FormattedMessage id='tooltip.text85_9' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text85_10' /></b>
                    <FormattedMessage id='tooltip.text85_11' />
                </li>
            </ul>
        </div>
    }
];

type Data = {
    handleStepClick: React.Dispatch<React.SetStateAction<number>>
}

const Schema12View2 = ({ handleStepClick }: Data) => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };

    const screenWidth = useWindowSize().width;
    const centerLabelClassNames = classNames(styles.label, styles.centerLabel);
    const doubleContainerRightClassNames = classNames(styles.doubleContainer, styles.marginRight);

    const handleSetStep = (step: number) => () => {
        handleStepClick(step);
    };

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema12.title2' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema12.subTitle2' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 5.1, Figure 4
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.topContent}>
                                    <TopContent
                                        title1={intl.formatMessage({ id: 'schema12.topContentTitle3' })}
                                        title2={intl.formatMessage({ id: 'schema12.topContentTitle4' })}
                                        labels1={LABELS_1}
                                    />
                                </div>
                                <div className={styles.mainContent}>
                                    <div className={styles.iconStart}>
                                        <div className={styles.icon} onClick={handleSetStep(86)}>
                                            <div className={styles.hintText}>
                                                <FormattedMessage id='schema12.textStart' />
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>{schema12PageIcons.arrowShort}</div>
                                    </div>
                                    <div className={styles.outerContainer}>
                                        <Item
                                            content='1.1'
                                            theme='basic'
                                            index={2}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text28' })}
                                            tooltipWidth='204px'
                                        />
                                        <div className={styles.doubleContainer}>
                                            <div className={styles.connectionIn}>{schema12PageIcons.connectionDoubleIn}</div>
                                            <div className={styles.connectionOut}>{schema12PageIcons.connectionDoubleOut2}</div>
                                            <Item
                                                content='1.2'
                                                theme='basic'
                                                index={1}
                                                tooltipContent={intl.formatMessage({ id: 'tooltip.text29' })}
                                                tooltipWidth='204px'
                                            />
                                            <Item
                                                content='1.3'
                                                theme='basic'
                                                index={1}
                                                tooltipContent={intl.formatMessage({ id: 'tooltip.text30' })}
                                                tooltipWidth='204px'
                                            />
                                        </div>
                                        <div className={styles.tripleContainer}>
                                            <div className={styles.connectionIn}>{schema12PageIcons.connectionTripleIn}</div>
                                            <div className={styles.connectionOut}>{schema12PageIcons.connectionTripleOut}</div>
                                            <Item
                                                content='1.5'
                                                theme='basic'
                                                index={1}
                                                tooltipContent={intl.formatMessage({ id: 'tooltip.text32' })}
                                                tooltipWidth='204px'
                                            />
                                            <Item
                                                content='1.4'
                                                theme='basic'
                                                index={1}
                                                tooltipContent={intl.formatMessage({ id: 'tooltip.text31' })}
                                                tooltipWidth='204px'
                                            />
                                            <Item
                                                content='1.6'
                                                theme='basic'
                                                index={1}
                                                tooltipContent={intl.formatMessage({ id: 'tooltip.text33' })}
                                                tooltipWidth='204px'
                                                tooltipReversed={true}
                                            />
                                        </div>
                                        <div className={doubleContainerRightClassNames}>
                                            <div className={styles.connectionIn}>{schema12PageIcons.connectionDoubleIn}</div>
                                            <div className={styles.connectionOut}>{schema12PageIcons.connectionDoubleOut}</div>
                                            <Item
                                                content='1.7'
                                                theme='basic'
                                                index={1}
                                                tooltipContent={intl.formatMessage({ id: 'tooltip.text34' })}
                                                tooltipWidth='204px'
                                            />
                                            <Item
                                                content='1.8'
                                                theme='basic'
                                                index={1}
                                                tooltipContent={intl.formatMessage({ id: 'tooltip.text35' })}
                                                tooltipWidth='204px'
                                            />
                                        </div>
                                        <Item
                                            content='2'
                                            theme='grey'
                                            arrow='short'
                                            plus={true}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text15' })}
                                            tooltipWidth='100px'
                                            marginsMiddle={true}
                                            step={88}
                                            handleStepClick={handleStepClick}
                                        />
                                        <Item
                                            content='3'
                                            theme='grey'
                                            arrow='short'
                                            plus={true}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text16' })}
                                            tooltipWidth='87px'
                                            marginsMiddle={true}
                                            step={89}
                                            handleStepClick={handleStepClick}
                                        />
                                        <Item
                                            content='4'
                                            theme='grey'
                                            arrow='short'
                                            plus={true}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text17' })}
                                            tooltipWidth='87px'
                                            marginsMiddle={true}
                                            step={90}
                                            handleStepClick={handleStepClick}
                                        />
                                        <Item
                                            content='5'
                                            theme='grey'
                                            arrow='short'
                                            plus={true}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text18' })}
                                            tooltipWidth='87px'
                                            marginsMiddle={true}
                                            step={91}
                                            handleStepClick={handleStepClick}
                                        />
                                        <Item
                                            content='6'
                                            theme='grey'
                                            arrow='short'
                                            plus={true}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text19' })}
                                            tooltipWidth='106px'
                                            marginsMiddle={true}
                                            step={92}
                                            handleStepClick={handleStepClick}
                                        />
                                        <Item
                                            content='7'
                                            theme='grey'
                                            arrow='short'
                                            plus={true}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text20' })}
                                            tooltipWidth='141px'
                                            marginsMiddle={true}
                                            step={93}
                                            handleStepClick={handleStepClick}
                                        />
                                        <Item
                                            content='8'
                                            theme='grey'
                                            plus={true}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text21' })}
                                            tooltipWidth='123px'
                                            marginsMiddle={true}
                                            step={94}
                                            handleStepClick={handleStepClick}
                                        />
                                        <div className={styles.connectionContainer}>
                                            <div className={centerLabelClassNames}>
                                                A
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text22' })} direction='reversed' width='180px'/>
                                            </div>
                                            <div className={styles.connection}>{schema12PageIcons.connection2}</div>
                                        </div>
                                    </div>
                                    <div className={styles.iconEnd}>
                                        <div className={styles.arrow}>{schema12PageIcons.arrowShort}</div>
                                        <div className={styles.icon} onClick={handleSetStep(94)}>
                                            <div className={styles.hintText}>
                                                <FormattedMessage id='schema12.textEnd' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteText46' /></p></div>}/>
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema12.figure4Title' })}
                    label='NF EN ISO 19650-1:2019, 5.1'
                    caption={intl.formatMessage({ id: 'schema12.figure4' })}
                    image={figure4}
                    id={121}
                />
            }
        </div>
    );
};

export default Schema12View2;
