import React, { ReactElement } from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// COMPONENTS
import Tooltip from '../Tooltip';

type Data = {
    theme?: string,
    size?: string,
    title: string,
    titleSmall?: boolean,
    subTitle?: string,
    arrow?: ReactElement<any, any>,
    arrow2?: ReactElement<any, any>,
    content?: Array<{
        title: Element | ReactElement<any, any> | string,
        theme: string,
        fontSize: string,
        tooltipText?: Element | ReactElement<any, any> | undefined }>,
    contentSubTitle?: string,
    contentSubTitleFont? : 'small',
    arrowPosition?: string,
    sideConnection?: ReactElement<any, any>,
    disabled?: boolean,
    tooltipText?: string,
    tooltipText2?: string,
    tooltipText3?: string,
    tooltipArrow1?: string,
    tooltipArrow2?: string,
    tooltipArrow3?: string,
    tooltipArrow4?: string
}

const InfoBlock = (
    {
        theme,
        title,
        titleSmall,
        size,
        arrow,
        arrow2,
        content,
        contentSubTitle,
        contentSubTitleFont,
        subTitle,
        arrowPosition,
        sideConnection,
        disabled,
        tooltipText,
        tooltipText2,
        tooltipText3,
        tooltipArrow1,
        tooltipArrow2,
        tooltipArrow3,
        tooltipArrow4
    }: Data) => {
    const rootClassNames = classNames(styles.root, {
        [styles.themeLightBlue]: theme === 'lightBlue',
        [styles.themeTransparent]: theme === 'transparent',
        [styles.smallSize]: size === 'small',
        [styles.narrowSize]: size === 'narrow',
        [styles.disabled]: disabled,
        [styles.withTooltip]: tooltipText,
        [styles.titleSmall]: titleSmall
    });
    const sideArrowClassNames = classNames(styles.sideArrow, {
        [styles.withTooltip]: tooltipArrow1
    });
    const arrowClassNames = classNames(styles.arrow, {
        [styles.right]: arrowPosition === 'right',
        [styles.left]: arrowPosition === 'left'
    });
    const textItemClassNames = (item: {
        title: Element | ReactElement<any, any> | string,
        theme: string,
        fontSize: string,
        tooltipText?: Element | ReactElement<any, any> | undefined
    }) => classNames(styles.textItem, {
        [styles.transparent]: item.theme === 'transparent',
        [styles.fontSmall]: item.fontSize === 'small',
        [styles.fontMedium]: item.fontSize === 'medium',
        [styles.withTooltip]: item.tooltipText
    });
    const contentSubtitleClassNames = classNames(styles.contentSubtitle, {
        [styles.contentSubTitleSmallFont]: contentSubTitleFont === 'small',
        [styles.withTooltip]: tooltipText3
    });
    const subTitleClassNames = classNames(styles.subTitle, {
        [styles.withTooltip]: tooltipText2
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <div className={styles.titleTrigger}>
                        {title}
                        {
                            tooltipText && <div className={styles.tooltip}>
                                <Tooltip content={tooltipText} theme='blue' width='98px'/>
                            </div>
                        }
                    </div>
                    {
                        sideConnection && <div className={sideArrowClassNames}>
                            {sideConnection}
                            {
                                tooltipArrow1 && <div className={styles.tooltipArrow}>
                                    <Tooltip content={tooltipArrow1} theme='red' width='130px'/>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    arrow && <div className={arrowClassNames}>
                        {arrow}
                        {
                            tooltipArrow2 && <div className={styles.tooltipArrow}>
                                <Tooltip content={tooltipArrow2} theme='red' width='172px'/>
                            </div>
                        }
                        {
                            tooltipArrow4 && <div className={styles.tooltipArrow}>
                                <Tooltip content={tooltipArrow4} theme='red' width='130px'/>
                            </div>
                        }
                    </div>
                }
                {
                    content &&
                    <div className={styles.content}>
                        <div className={styles.items}>
                            <div className={styles.itemsContainer}>
                                {
                                    content.map((item, i) => {
                                        return i === 0
                                            ? <div className={styles.itemWrapper} key={i}>
                                                <div className={textItemClassNames(item)}>{item.title}
                                                    {
                                                        item.tooltipText && <div className={styles.tooltip}>
                                                            <Tooltip content={item.tooltipText} theme='blue' width='98px'/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            : <div className={styles.itemWrapper} key={i}>
                                                {
                                                    arrow2 && <div className={styles.itemArrow}>
                                                        {arrow2}
                                                        {
                                                            tooltipArrow3 && <div className={styles.tooltipArrow}>
                                                                <Tooltip content={tooltipArrow3} theme='red' width='130px'/>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className={textItemClassNames(item)}>{item.title}
                                                    {
                                                        item.tooltipText && <div className={styles.tooltip}>
                                                            <Tooltip content={item.tooltipText} theme='blue' width='98px'/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>;
                                    })
                                }
                            </div>
                            {
                                contentSubTitle && <div className={contentSubtitleClassNames}>
                                    {contentSubTitle}
                                    {
                                        tooltipText3 && <div className={styles.tooltip}>
                                            <Tooltip content={tooltipText3} theme='grey' width='98px'/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    subTitle && <div className={subTitleClassNames}>
                        {subTitle}
                        {
                            tooltipText2 && <div className={styles.tooltip}>
                                <Tooltip content={tooltipText2} theme='grey' width='98px'/>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default React.memo(InfoBlock);
