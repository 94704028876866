import React from 'react';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { quoiPageIcons } from '../../assets/icons/svg';
// COMPONENTS
import Note from '../../common/components/Note';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number
};

const QuoiPage = ({ handleSetView, forward, back }: Props) => {
    const handleChangePage = (direction:'right'|'left') => {
        if (direction === 'right') {
            handleSetView(forward)();
        } else {
            handleSetView(back)();
        }
    };

    const handleStepSwitch = (index: number) => (event: any) => {
        event.preventDefault();
        handleSetView(index)();
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleChangePage}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    <div className={styles.innerContainer}>
                        <div className={styles.title}>
                            <FormattedMessage id='quoiPage.title' />
                        </div>
                        <div className={styles.subTitle}>
                            <FormattedMessage id='quoiPage.subTitle' />
                        </div>
                        <div className={styles.buttonsBlock}>
                            <div className={styles.button} onClick={handleStepSwitch(72)}>
                                <FormattedMessage id='quoiPage.button1' />
                            </div>
                            <div className={styles.arrowIcon}>
                                {quoiPageIcons.arrow1}
                            </div>
                            <div className={styles.button} onClick={handleStepSwitch(73)}>
                                <FormattedMessage id='quoiPage.button2' />
                            </div>
                            <div className={styles.arrowIcon}>
                                {quoiPageIcons.arrow1}
                            </div>
                            <div className={styles.button} onClick={handleStepSwitch(82)}>
                                <FormattedMessage id='quoiPage.button3' />
                            </div>
                        </div>
                        <Note
                            content={<div><p><FormattedMessage id='note.noteText59' /></p></div>}
                            theme='blue'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuoiPage;
