import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons } from '../../../assets/icons/svg';

type Data = {
    title: string,
    subTitle?: string,
    section1Heading?: string,
    textBlock1?: string | ReactElement<any, any>,
    textBlock2?: string | ReactElement<any, any>,
    textBlockFull?: string | ReactElement<any, any>,
    textBlocksLine?: boolean,
    redNoteTitle?: string,
    redNoteText?: string,
    blueNoteTitle?: string,
    blueNoteText?: string | ReactElement<any, any>,
    note?: string,
    footer?: string | ReactElement<any, any>,
    links?: string[],
    detailsTextBlock1?: string | ReactElement<any, any>
    detailsTextBlock2?: string | ReactElement<any, any>
    detailsTextFull?: string | ReactElement<any, any>,
    examples?: { src: string, title?: string }[],
    examplesTitle?: string,
    detailsMore?: boolean,
    detailsNoBorder?: boolean,
    bottomTitleBlue?: boolean,
    blueNoteTextGrey?: boolean,
    bottomTitleCenter?: boolean,
    redNoteTextThin?: boolean,
    mainTextBig?: boolean
};

const SchemaIntro = ({
    title,
    subTitle,
    section1Heading,
    textBlock1,
    textBlock2,
    textBlockFull,
    redNoteTitle,
    redNoteText,
    blueNoteTitle,
    blueNoteText,
    note,
    footer,
    links,
    detailsTextBlock1,
    detailsTextBlock2,
    detailsTextFull,
    examples,
    examplesTitle,
    textBlocksLine,
    detailsMore,
    detailsNoBorder,
    bottomTitleBlue,
    blueNoteTextGrey,
    bottomTitleCenter,
    redNoteTextThin,
    mainTextBig
}: Data) => {
    const detailsRef = useRef<null | any>(null);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const handleSwitchDetails = () => {
        setDetailsVisible(!detailsVisible);
    };
    useEffect(() => {
        if (detailsVisible) {
            setTimeout(() => {
                detailsRef.current?.scrollIntoView({
                    behavior: 'smooth'
                });
            }, 500);
        }
    }, [detailsVisible]);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.title}>{title}</div>
                    {
                        subTitle && <div className={styles.subTitle}>{subTitle}</div>
                    }
                </div>
                <div className={styles.bottom}>
                    {
                        section1Heading && <div className={classNames(styles.section1Heading, {
                            [styles.bottomTitleBlue]: bottomTitleBlue,
                            [styles.bottomTitleCenter]: bottomTitleCenter
                        })}>{section1Heading}</div>
                    }
                    {
                        !textBlockFull && (textBlock1 || textBlock2) &&
                        <div className={classNames(styles.blocks, {
                            [styles.withBorder]: textBlocksLine
                        })}>
                            <div className={classNames(styles.block, {
                                [styles.mainTextBig]: mainTextBig
                            })}>
                                {
                                    textBlock1 && <div className={styles.text}>{textBlock1}</div>
                                }
                                {
                                    (redNoteTitle || redNoteText) &&
                                    <div className={classNames(styles.redNote, {
                                        [styles.redNoteTextThin]: redNoteTextThin
                                    })}>
                                        {
                                            redNoteTitle && <div className={styles.redNoteTitle}>{redNoteTitle}</div>
                                        }
                                        {
                                            redNoteText && <div className={styles.redNoteText}>{redNoteText}</div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className={classNames(styles.block, {
                                [styles.mainTextBig]: mainTextBig
                            })}>
                                {
                                    textBlock2 && <div className={styles.text}>{textBlock2}</div>
                                }
                                {
                                    (blueNoteTitle || blueNoteText) &&
                                    <div className={classNames(styles.blueNote, {
                                        [styles.blueNoteTextGrey]: blueNoteTextGrey
                                    })}>
                                        {
                                            blueNoteTitle && <div className={styles.blueNoteTitle}>{blueNoteTitle}</div>
                                        }
                                        {
                                            blueNoteText && <div className={styles.blueNoteText}>{blueNoteText}</div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className={classNames(styles.mobileBlock, {
                                [styles.mainTextBig]: mainTextBig
                            })}>
                                {
                                    textBlock1 && <div className={styles.text}>{textBlock1}</div>
                                }
                                {
                                    textBlock2 && <div className={styles.text}>{textBlock2}</div>
                                }
                            </div>
                            {
                                (redNoteTitle || redNoteText || blueNoteTitle || blueNoteText) &&
                                <div className={styles.mobileBlock}>
                                    {
                                        (redNoteTitle || redNoteText) &&
                                        <div className={classNames(styles.redNote, {
                                            [styles.redNoteTextThin]: redNoteTextThin
                                        })}>
                                            {
                                                redNoteTitle && <div className={styles.redNoteTitle}>{redNoteTitle}</div>
                                            }
                                            {
                                                redNoteText && <div className={styles.redNoteText}>{redNoteText}</div>
                                            }
                                        </div>
                                    }
                                    {
                                        (blueNoteTitle || blueNoteText) &&
                                        <div className={classNames(styles.blueNote, {
                                            [styles.blueNoteTextGrey]: blueNoteTextGrey
                                        })}>
                                            {
                                                blueNoteTitle && <div className={styles.blueNoteTitle}>{blueNoteTitle}</div>
                                            }
                                            {
                                                blueNoteText && <div className={styles.blueNoteText}>{blueNoteText}</div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        textBlockFull &&
                            <div className={classNames(styles.textBlockWrapper, {
                                [styles.mainTextBig]: mainTextBig
                            })}>
                                <div className={styles.blockFull}>{textBlockFull}</div>
                                {
                                    (blueNoteTitle || blueNoteText) &&
                                    <div className={classNames(styles.blueNote, {
                                        [styles.blueNoteTextGrey]: blueNoteTextGrey
                                    })}>
                                        {
                                            blueNoteTitle && <div className={styles.blueNoteTitle}>{blueNoteTitle}</div>
                                        }
                                        {
                                            blueNoteText && <div className={styles.blueNoteText}>{blueNoteText}</div>
                                        }
                                    </div>
                                }
                            </div>
                    }
                    {
                        ((detailsTextFull || detailsTextBlock1 || detailsTextBlock2) && detailsMore) &&
                        <div className={classNames(styles.details, {
                            [styles.noBorder]: detailsNoBorder
                        })} >
                            {
                                detailsMore &&
                                <div className={styles.detailsTitle} onClick={handleSwitchDetails} ref={detailsRef}>
                                    {
                                        detailsVisible && <FormattedMessage id='intro.lessDetails' />
                                    }
                                    {
                                        !detailsVisible && <FormattedMessage id='intro.moreDetails' />
                                    }
                                </div>
                            }
                            {
                                detailsVisible && detailsTextFull &&
                                <div className={styles.detailsText}>{detailsTextFull}</div>
                            }
                            {
                                detailsVisible && detailsTextBlock1 &&
                                <div className={styles.detailsText}>
                                    <div className={styles.blocks}>
                                        <div className={styles.block}>{detailsTextBlock1}</div>
                                        <div className={styles.block}>{detailsTextBlock2}</div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        ((detailsTextFull || detailsTextBlock1 || detailsTextBlock2) && !detailsMore) &&
                        <div className={classNames(styles.details, {
                            [styles.noBorder]: detailsNoBorder,
                            [styles.noShowMore]: !detailsMore
                        })} >
                            {
                                detailsTextFull &&
                                <div className={styles.detailsText}>{detailsTextFull}</div>
                            }
                            {
                                detailsTextBlock1 &&
                                <div className={styles.detailsText}>
                                    <div className={styles.blocks}>
                                        <div className={styles.block}>{detailsTextBlock1}</div>
                                        <div className={styles.block}>{detailsTextBlock2}</div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        (examples?.length || examplesTitle) &&
                        <div className={styles.examples} >
                            {
                                examplesTitle && <div className={styles.examplesTitle}>{examplesTitle}</div>
                            }
                            {
                                examples?.length && examples?.length === 1 &&
                                <div className={styles.examplesSingle}>
                                    <div className={styles.example}>
                                        <img src={examples[0].src} alt='' className={styles.exampleImage}/>
                                    </div>
                                </div>
                            }
                            {
                                examples?.length && examples?.length > 1 &&
                                <div className={styles.examplesMultiple}>
                                    <ol>
                                        {
                                            examples?.map((item, i) => (
                                                <li key={i}>
                                                    {item.title
                                                        ? <div className={styles.exampleTitle}>{item.title}</div>
                                                        : <div className={styles.marker}>{i + 1}.</div>
                                                    }
                                                    <div className={styles.example}>
                                                        <img src={item.src} alt='' className={styles.exampleImage}/>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ol>
                                </div>
                            }
                        </div>
                    }
                    {
                        note &&
                        <div className={styles.note}>
                            <div className={styles.noteHeading}>
                                <span className={styles.noteIcon}>{commonIcons.info}</span>
                                <FormattedMessage id='intro.note' />
                            </div>
                            <div className={styles.noteText}>{note}</div>
                        </div>
                    }
                    {
                        (footer || links?.length) &&
                        <div className={styles.bottomBlock}>
                            {
                                links?.length &&
                                <div className={styles.seeMore}>
                                    <div className={styles.seeMoreTitle}><FormattedMessage id='intro.seeAlso' /></div>
                                    <div className={styles.seeMoreList}>
                                        {
                                            links.map((item, i) => (
                                                <div className={styles.link} key={i}>{item}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            <div className={styles.footer}>
                                {footer}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SchemaIntro;
