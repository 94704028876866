import React from 'react';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema6PageIcons } from '../../../../assets/icons/svg';

type Data = {
    color?: string
};

const InfoBlock2 = ({ color }: Data) => {
    const rootClassNames = classNames(styles.wrapper, {
        [styles.grey]: color === 'grey'
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.block}>
                <div className={styles.blockTitle}>
                    <FormattedMessage id='schema6.legend1Title' />
                </div>
                <div className={styles.blockContent}>
                    <div className={styles.blockIcon}>{schema6PageIcons.iconSquare}</div>
                    <div className={styles.blockText}>
                        <div className={styles.blockTextContent}>
                            <FormattedMessage id='schema6.legend1Text' />
                        </div>
                        <div className={styles.example}>
                            <div className={styles.exampleTitle}>
                                <FormattedMessage id='schema6.legend1Example' />
                            </div>
                            <FormattedMessage id='schema6.legend1ExampleText' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.blockTitle}>
                    <FormattedMessage id='schema6.legend2Title' />
                </div>
                <div className={styles.blockContent}>
                    <div className={styles.blockIcon}>{schema6PageIcons.iconRound}</div>
                    <div className={styles.blockText}>
                        <div className={styles.blockTextContent}>
                            <FormattedMessage id='schema6.legend2Text' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(InfoBlock2);
