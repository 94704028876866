import fr from './fr';

type Translations = {
    [key: string]: {
        [key: string]: string
    }
}

const translations: Translations = {
    fr
};

export default translations;
