import React from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema6PageIcons } from '../../../../assets/icons/svg';

type Data = {
    stepText: string,
    stepExchangeText: string,
    stepDebutText: string,
    stepReviewText: string,
    direction?: string
};

const InfoBlock1 = ({ stepText, stepDebutText, stepExchangeText, stepReviewText, direction }: Data) => {
    const rootClassNames = classNames(styles.wrapper, {
        [styles.reversed]: direction === 'reversed'
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.exchangeBlock}>
                {schema6PageIcons.circleGreen}
                <div className={styles.exchangeText}>{stepExchangeText}</div>
            </div>
            <div className={styles.stepReviewBlock}>
                {schema6PageIcons.circleOrangeArrow}
                <div className={styles.reviewText}>{stepReviewText}</div>
            </div>
            <div className={styles.arrowBlock}>
                {schema6PageIcons.bigOrangeArrow}
                <div className={styles.arrowText}>{stepText}</div>
            </div>
        </div>
    );
};

export default React.memo(InfoBlock1);
