import React from 'react';
// STYLES
import styles from './styles.module.scss';

type DATA = {
    text: string
};

const Tooltip = ({ text }: DATA) => {
    return (
        <div className={styles.toolTip}>
            <div className={styles.tooltipText}>
                <p>{text}</p>
            </div>
        </div>
    );
};

export default React.memo(Tooltip);
