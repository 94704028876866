import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import InfoBlock4 from '../InfoBlock4';
import InfoBlock3 from '../InfoBlock3';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// IMAGES
import figure7 from '../images/figure7.png';
import { commonIcons } from '../../../../assets/icons/svg';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema6View1 = () => {
    const intl = useIntl();

    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };
    const screenWidth = useWindowSize().width;

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema6.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema6.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 6.3.3 , Figure 7
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoContent}>
                                    <div className={styles.steps}>
                                        <div className={styles.step1}>
                                            <div className={styles.number}>1.</div>
                                            <div className={styles.stepText}>
                                                {intl.formatMessage({ id: 'schema6.step1Text' })}
                                            </div>
                                        </div>
                                        <div className={styles.step2}>
                                            <div className={styles.number}>2.</div>
                                            <div className={styles.stepText}>
                                                {intl.formatMessage({ id: 'schema6.step2Text' })}
                                            </div>
                                        </div>
                                        <div className={styles.step3}>
                                            <div className={styles.number}>3.</div>
                                            <div className={styles.stepText}>
                                                {intl.formatMessage({ id: 'schema6.step3Text' })}
                                            </div>
                                        </div>
                                        <div className={styles.step4}>
                                            <div className={styles.number}>4.</div>
                                            <div className={styles.stepText}>
                                                {intl.formatMessage({ id: 'schema6.step4Text' })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.infoTop}>
                                        <InfoBlock4
                                            stepText={intl.formatMessage({ id: 'schema6.step' })}
                                            stepExchangeText={intl.formatMessage({ id: 'schema6.stepExchange' })}
                                            stepDebutText={intl.formatMessage({ id: 'schema6.stepDebut' })}
                                            stepReviewText={intl.formatMessage({ id: 'schema6.stepReview' })}
                                        />
                                        <InfoBlock3
                                            button1Text={intl.formatMessage({ id: 'schema6.button1' })}
                                            button2Text={intl.formatMessage({ id: 'schema6.button2' })}
                                            direction='start'
                                            arrowRight='solid'
                                            arrowRightText={intl.formatMessage({ id: 'schema6.text1' })}
                                        />
                                        <div className={styles.stepEndBlock}>
                                            <div className={styles.endText}>{intl.formatMessage({ id: 'schema6.stepEnd' })}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema6.figure7Title' })}
                    label='NF EN ISO 19650-1:2019, 6.3.3'
                    caption={intl.formatMessage({ id: 'schema6.figure7' })}
                    image={figure7}
                    id={116}
                />
            }
        </div>
    );
};

export default Schema6View1;
