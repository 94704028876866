import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { commonIcons } from '../../../assets/icons/svg';
// UTILS
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// ACTIONS
import setInfoPopup from '../../../store/application/actions/setInfoPopup';

const InfoPopup = () => {
    const dispatch = useDispatch();
    const popupRef = useRef<any>(null);

    useEffect(() => {
        disableBodyScroll(popupRef.current);

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const handleClosePopup = () => {
        dispatch(setInfoPopup(false));
    };

    return (
        <div className={styles.root}>
            <div className={styles.background} onClick={handleClosePopup}/>
            <div className={styles.container} ref={popupRef}>
                <div className={styles.header}>
                    <FormattedMessage id='infoPopup.title' />
                    <div className={styles.closeIcon} onClick={handleClosePopup}>
                        {commonIcons.close}
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.attentionBlock}>
                        <div className={styles.attentionTitle}>
                            <FormattedMessage id='infoPopup.attention' />
                        </div>
                        <div className={styles.attentionText}>
                            <FormattedMessage id='infoPopup.attentionText1' />
                            <ul>
                                <li><FormattedMessage id='infoPopup.attentionText2' /></li>
                                <li><FormattedMessage id='infoPopup.attentionText3' /></li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.medium}><FormattedMessage id='infoPopup.text1' /></div>
                    <div className={styles.quote}><FormattedMessage id='infoPopup.text2' /></div>
                    <div className={styles.bold}><FormattedMessage id='infoPopup.text3' /></div>
                    <FormattedMessage id='infoPopup.text4' />
                    <br/>
                    <FormattedMessage id='infoPopup.text5' />
                    <br/>
                    <br/>
                    <FormattedMessage id='infoPopup.text6' />
                    <ul>
                        <li><FormattedMessage id='infoPopup.text7' /></li>
                        <li><FormattedMessage id='infoPopup.text8' /></li>
                        <li><FormattedMessage id='infoPopup.text9' /></li>
                    </ul>
                    <br/>
                    <br/>
                    <FormattedMessage id='infoPopup.text10' />
                </div>
                <div className={styles.id}>
                    Page ID: 112
                </div>
            </div>
        </div>
    );
};

export default InfoPopup;
