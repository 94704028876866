import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons, schema11PageIcons } from '../../../../assets/icons/svg';
import Connectors from './images/Connectors.svg';
import figure4 from '../images/figure4.png';
// COMPONENTS
import Tooltip from '../Tooltip';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema11View8 = () => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };
    const screenWidth = useWindowSize().width;
    const infoBlockBottomClassNames = classNames(styles.infoBlock, styles.infoBlockBottom);
    const infoBlockRightClassNames = classNames(styles.infoBlock, styles.infoBlockRight);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema11.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema11.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 6.3.1
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.cardsWrapper}>
                                    <div className={styles.connectorsWrapper}>
                                        <img className={styles.connectors} src={Connectors} alt='connectors'/>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <FormattedMessage id='schema11.block1Title' />
                                            <Tooltip text={intl.formatMessage({ id: 'tooltip.text9' })} />
                                        </div>
                                    </div>
                                    <div className={styles.arrow}>
                                        {schema11PageIcons.arrow1}
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <FormattedMessage id='schema11.block2Title' />
                                        </div>
                                    </div>
                                    <div className={styles.arrow}>
                                        {schema11PageIcons.arrow1}
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <FormattedMessage id='schema11.block3Title' />
                                            <Tooltip text={intl.formatMessage({ id: 'tooltip.text11' })} />
                                        </div>
                                    </div>
                                    <div className={styles.arrow}>
                                        {schema11PageIcons.arrow1}
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <FormattedMessage id='schema11.block4Title' />
                                            <Tooltip text={intl.formatMessage({ id: 'tooltip.text12' })} />
                                        </div>
                                    </div>
                                    <div className={styles.okBlock}>
                                        <div className={styles.ok}>
                                            <div className={styles.background}>
                                                {schema11PageIcons.squareBackground}
                                            </div>
                                            <div className={styles.okText}>
                                                <FormattedMessage id='schema11.ok' />
                                            </div>
                                        </div>
                                        <div className={styles.yesText}>
                                            <FormattedMessage id='schema11.yes' />
                                        </div>
                                        <div className={styles.noText}>
                                            <FormattedMessage id='schema11.no' />
                                        </div>
                                        <div className={infoBlockRightClassNames}>
                                            <div className={styles.mainContent}>
                                                <FormattedMessage id='schema11.block6Title' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={infoBlockBottomClassNames}>
                                        <div className={styles.mainContent}>
                                            <FormattedMessage id='schema11.block5Title' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema12.figure4Title' })}
                    label='NF EN ISO 19650-1:2019, 6.3.1'
                    caption={intl.formatMessage({ id: 'schema11.figure4' })}
                    image={figure4}
                    id={119}
                />
            }
        </div>
    );
};

export default Schema11View8;
