import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useParams,
    useHistory
} from 'react-router-dom';
// STYLES
import styles from './styles.module.scss';
// ACTIONS
// import { getRSNMinimalisticList } from '../../store/terms/actions/actions';
// COMPONENTS
import MainPage from '../MainPage';
import PourquoiPage from '../PourquoiPage';
import CommentPage from '../CommentPage';
import QuoiPage from '../QuoiPage';
import EnPratiquePage from '../EnPratiquePage';
import EnFrancePage from '../EnFrancePage';
import Schema1Page from '../Schema1Page';
import Schema2Page from '../Schema2Page';
import Schema3Page from '../Schema3Page';
import Schema4Page from '../Schema4Page';
import Schema5Page from '../Schema5Page';
import Schema6Page from '../Schema6Page';
import Schema7Page from '../Schema7Page';
import Schema8Page from '../Schema8Page';
import Schema9Page from '../Schema9Page';
import Schema11Page from '../Schema11Page';
import Schema12Page from '../Schema12Page';
import Schema13Page from '../Schema13Page';
import IndexPage from '../IndexPage';
// SELECTORS
import { getPageId } from '../../store/application/selectors/selectors';
// ACTIONS
import setPageId from '../../store/application/actions/setPageId';

const NavigationPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const pageId = useSelector(getPageId);
    const [view, setView] = useState<number | null>(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const linkId = Number.parseInt(params?.pageId);

        if (linkId && linkId !== pageId) {
            setView(linkId);
            dispatch(setPageId(linkId));
        } else {
            setView(pageId);
        }
    }, [params]);

    useEffect(() => {
        if (view && (pageId !== view)) {
            setView(pageId);
        }
    }, [pageId]);

    const handleSetView = (view: number) => () => {
        setView(view);

        if (pageId !== view) {
            dispatch(setPageId(view));
            history.push(`/page/${view}`);
        }
    };

    /* useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(getRSNMinimalisticList({ page: 1, page_size: 10 })).then((result: { data: any[] }) => {
            if (result?.data) {
                setTerms(result?.data);
            }
        });
    }, []); */

    return (
        <div className={styles.root}>
            {
                view === 1 && <MainPage handleSetView={handleSetView}/>
            }
            {
                view === 2 && <PourquoiPage handleSetView={handleSetView} forward={3} back={1}/>
            }
            {
                view === 3 && <Schema1Page handleSetView={handleSetView} tab={0} forward={4} back={2}/>
            }
            {
                view === 4 && <Schema1Page handleSetView={handleSetView} tab={1} forward={5} back={3}/>
            }
            {
                view === 5 && <Schema1Page handleSetView={handleSetView} tab={2} forward={6} back={4}/>
            }
            {
                view === 6 && <Schema1Page handleSetView={handleSetView} tab={3} forward={7} back={5}/>
            }
            {
                view === 7 && <Schema1Page handleSetView={handleSetView} tab={4} forward={8} back={6}/>
            }
            {
                view === 8 && <CommentPage handleSetView={handleSetView} forward={9} back={7}/>
            }
            {
                view === 9 && <Schema2Page handleSetView={handleSetView} tab={0} forward={10} back={8}/>
            }
            {
                view === 10 && <Schema2Page handleSetView={handleSetView} tab={2} forward={11} back={9}/>
            }
            {
                view === 11 && <Schema2Page handleSetView={handleSetView} tab={3} forward={12} back={10}/>
            }
            {
                view === 12 && <Schema2Page handleSetView={handleSetView} tab={4} forward={13} back={11}/>
            }
            {
                view === 13 && <Schema2Page handleSetView={handleSetView} tab={5} forward={14} back={12}/>
            }
            {
                view === 14 && <Schema2Page handleSetView={handleSetView} tab={6} forward={15} back={13}/>
            }
            {
                view === 15 && <Schema3Page handleSetView={handleSetView} tab={0} forward={16} back={14}/>
            }
            {
                view === 16 && <Schema3Page handleSetView={handleSetView} tab={2} forward={17} back={15}/>
            }
            {
                view === 17 && <Schema3Page handleSetView={handleSetView} tab={3} forward={18} back={16}/>
            }
            {
                view === 18 && <Schema3Page handleSetView={handleSetView} tab={4} forward={19} back={17}/>
            }
            {
                view === 19 && <Schema3Page handleSetView={handleSetView} tab={5} forward={20} back={18}/>
            }
            {
                view === 20 && <Schema4Page handleSetView={handleSetView} tab={0} forward={21} back={19}/>
            }
            {
                view === 21 && <Schema4Page handleSetView={handleSetView} tab={3} forward={22} back={20}/>
            }
            {
                view === 22 && <Schema4Page handleSetView={handleSetView} tab={4} forward={23} back={21}/>
            }
            {
                view === 23 && <Schema4Page handleSetView={handleSetView} tab={5} forward={24} back={22}/>
            }
            {
                view === 24 && <Schema4Page handleSetView={handleSetView} tab={6} forward={25} back={23}/>
            }
            {
                view === 25 && <Schema4Page handleSetView={handleSetView} tab={7} forward={26} back={24}/>
            }
            {
                view === 26 && <Schema4Page handleSetView={handleSetView} tab={1} forward={27} back={25}/>
            }
            {
                view === 27 && <Schema5Page handleSetView={handleSetView} tab={0} forward={28} back={26}/>
            }
            {
                view === 28 && <Schema5Page handleSetView={handleSetView} tab={3} forward={29} back={27}/>
            }
            {
                view === 29 && <Schema5Page handleSetView={handleSetView} tab={4} forward={30} back={28}/>
            }
            {
                view === 30 && <Schema5Page handleSetView={handleSetView} tab={5} forward={31} back={29}/>
            }
            {
                view === 31 && <Schema5Page handleSetView={handleSetView} tab={6} forward={32} back={30}/>
            }
            {
                view === 32 && <Schema5Page handleSetView={handleSetView} tab={7} forward={33} back={31}/>
            }
            {
                view === 33 && <Schema5Page handleSetView={handleSetView} tab={10} forward={34} back={32}/>
            }
            {
                view === 34 && <Schema6Page handleSetView={handleSetView} tab={2} forward={35} back={33}/>
            }
            {
                view === 35 && <Schema6Page handleSetView={handleSetView} tab={3} forward={36} back={34}/>
            }
            {
                view === 36 && <Schema6Page handleSetView={handleSetView} tab={5} forward={37} back={35}/>
            }
            {
                view === 37 && <Schema6Page handleSetView={handleSetView} tab={0} forward={38} back={36}/>
            }
            {
                view === 38 && <Schema7Page handleSetView={handleSetView} tab={0} forward={39} back={37}/>
            }
            {
                view === 39 && <Schema7Page handleSetView={handleSetView} tab={1} forward={40} back={38}/>
            }
            {
                view === 40 && <Schema7Page handleSetView={handleSetView} tab={2} forward={41} back={39}/>
            }
            {
                view === 41 && <Schema7Page handleSetView={handleSetView} tab={3} forward={42} back={40}/>
            }
            {
                view === 42 && <Schema7Page handleSetView={handleSetView} tab={4} forward={43} back={41}/>
            }
            {
                view === 43 && <Schema8Page handleSetView={handleSetView} tab={0} forward={44} back={42}/>
            }
            {
                view === 44 && <Schema8Page handleSetView={handleSetView} tab={17} forward={45} back={43}/>
            }
            {
                view === 45 && <Schema8Page handleSetView={handleSetView} tab={18} forward={46} back={44}/>
            }
            {
                view === 46 && <Schema8Page handleSetView={handleSetView} tab={19} forward={47} back={45}/>
            }
            {
                view === 47 && <Schema8Page handleSetView={handleSetView} tab={20} forward={48} back={46}/>
            }
            {
                view === 48 && <Schema8Page handleSetView={handleSetView} tab={2} forward={49} back={47}/>
            }
            {
                view === 49 && <Schema8Page handleSetView={handleSetView} tab={3} forward={50} back={48}/>
            }
            {
                view === 50 && <Schema8Page handleSetView={handleSetView} tab={4} forward={51} back={49}/>
            }
            {
                view === 51 && <Schema8Page handleSetView={handleSetView} tab={5} forward={52} back={50}/>
            }
            {
                view === 52 && <Schema8Page handleSetView={handleSetView} tab={6} forward={53} back={51}/>
            }
            {
                view === 53 && <Schema8Page handleSetView={handleSetView} tab={7} forward={54} back={52}/>
            }
            {
                view === 54 && <Schema8Page handleSetView={handleSetView} tab={8} forward={55} back={53}/>
            }
            {
                view === 55 && <Schema8Page handleSetView={handleSetView} tab={9} forward={56} back={54}/>
            }
            {
                view === 56 && <Schema8Page handleSetView={handleSetView} tab={10} forward={57} back={55}/>
            }
            {
                view === 57 && <Schema8Page handleSetView={handleSetView} tab={11} forward={58} back={56}/>
            }
            {
                view === 58 && <Schema8Page handleSetView={handleSetView} tab={12} forward={59} back={57}/>
            }
            {
                view === 59 && <Schema8Page handleSetView={handleSetView} tab={13} forward={60} back={58}/>
            }
            {
                view === 60 && <Schema8Page handleSetView={handleSetView} tab={14} forward={61} back={59}/>
            }
            {
                view === 61 && <Schema8Page handleSetView={handleSetView} tab={15} forward={62} back={60}/>
            }
            {
                view === 62 && <Schema8Page handleSetView={handleSetView} tab={21} forward={63} back={61}/>
            }
            {
                view === 63 && <Schema8Page handleSetView={handleSetView} tab={22} forward={64} back={62}/>
            }
            {
                view === 64 && <Schema9Page handleSetView={handleSetView} tab={0} forward={65} back={63}/>
            }
            {
                view === 65 && <Schema9Page handleSetView={handleSetView} tab={2} forward={66} back={64}/>
            }
            {
                view === 66 && <Schema9Page handleSetView={handleSetView} tab={3} forward={67} back={65}/>
            }
            {
                view === 67 && <Schema9Page handleSetView={handleSetView} tab={4} forward={68} back={66}/>
            }
            {
                view === 68 && <Schema9Page handleSetView={handleSetView} tab={5} forward={69} back={67}/>
            }
            {
                view === 69 && <Schema9Page handleSetView={handleSetView} tab={6} forward={70} back={68}/>
            }
            {
                view === 70 && <Schema9Page handleSetView={handleSetView} tab={7} forward={71} back={69}/>
            }
            {
                view === 71 && <QuoiPage handleSetView={handleSetView} forward={74} back={70}/>
            }
            {
                view === 72 && <Schema2Page handleSetView={handleSetView} tab={7} forward={73} back={71}/>
            }
            {
                view === 73 && <Schema11Page handleSetView={handleSetView} tab={0} forward={74} back={72}/>
            }
            {
                view === 74 && <Schema11Page handleSetView={handleSetView} tab={1} forward={75} back={73}/>
            }
            {
                view === 75 && <Schema11Page handleSetView={handleSetView} tab={2} forward={76} back={74}/>
            }
            {
                view === 76 && <Schema11Page handleSetView={handleSetView} tab={3} forward={77} back={75}/>
            }
            {
                view === 77 && <Schema11Page handleSetView={handleSetView} tab={4} forward={78} back={76}/>
            }
            {
                view === 78 && <Schema11Page handleSetView={handleSetView} tab={5} forward={79} back={77}/>
            }
            {
                view === 79 && <Schema11Page handleSetView={handleSetView} tab={6} forward={80} back={78}/>
            }
            {
                view === 80 && <Schema11Page handleSetView={handleSetView} tab={7} forward={81} back={79}/>
            }
            {
                view === 81 && <Schema11Page handleSetView={handleSetView} tab={8} forward={82} back={80}/>
            }
            {
                view === 82 && <Schema1Page handleSetView={handleSetView} tab={8} forward={83} back={81}/>
            }
            {
                view === 83 && <Schema1Page handleSetView={handleSetView} tab={5} forward={84} back={82}/>
            }
            {
                view === 84 && <EnPratiquePage handleSetView={handleSetView} forward={85} back={83}/>
            }
            {
                view === 85 && <Schema12Page handleSetView={handleSetView} tab={0} forward={86} back={84}/>
            }
            {
                view === 86 && <Schema12Page handleSetView={handleSetView} tab={2} forward={87} back={85}/>
            }
            {
                view === 87 && <Schema12Page handleSetView={handleSetView} tab={3} forward={88} back={86}/>
            }
            {
                view === 88 && <Schema12Page handleSetView={handleSetView} tab={4} forward={89} back={87}/>
            }
            {
                view === 89 && <Schema12Page handleSetView={handleSetView} tab={5} forward={90} back={88}/>
            }
            {
                view === 90 && <Schema12Page handleSetView={handleSetView} tab={6} forward={91} back={89}/>
            }
            {
                view === 91 && <Schema12Page handleSetView={handleSetView} tab={7} forward={92} back={90}/>
            }
            {
                view === 92 && <Schema12Page handleSetView={handleSetView} tab={8} forward={93} back={91}/>
            }
            {
                view === 93 && <Schema12Page handleSetView={handleSetView} tab={9} forward={94} back={92}/>
            }
            {
                view === 94 && <Schema12Page handleSetView={handleSetView} tab={10} forward={95} back={93}/>
            }
            {
                view === 95 && <EnFrancePage handleSetView={handleSetView} forward={96} back={94}/>
            }
            {
                view === 96 && <Schema13Page handleSetView={handleSetView} tab={0} forward={97} back={95}/>
            }
            {
                view === 97 && <Schema13Page handleSetView={handleSetView} tab={2} forward={98} back={96}/>
            }
            {
                view === 98 && <Schema13Page handleSetView={handleSetView} tab={3} forward={99} back={97}/>
            }
            {
                view === 99 && <Schema13Page handleSetView={handleSetView} tab={4} forward={100} back={98}/>
            }
            {
                view === 100 && <Schema13Page handleSetView={handleSetView} tab={5} forward={101} back={99}/>
            }
            {
                view === 101 && <Schema13Page handleSetView={handleSetView} tab={6} forward={102} back={100}/>
            }
            {
                view === 102 && <Schema13Page handleSetView={handleSetView} tab={7} forward={103} back={101}/>
            }
            {
                view === 103 && <Schema13Page handleSetView={handleSetView} tab={8} forward={104} back={102}/>
            }
            {
                view === 104 && <Schema13Page handleSetView={handleSetView} tab={9} forward={105} back={103}/>
            }
            {
                view === 105 && <Schema13Page handleSetView={handleSetView} tab={10} forward={106} back={104}/>
            }
            {
                view === 106 && <Schema13Page handleSetView={handleSetView} tab={11} forward={107} back={105}/>
            }
            {
                view === 107 && <Schema1Page handleSetView={handleSetView} tab={6} forward={108} back={106}/>
            }
            {
                view === 108 && <Schema13Page handleSetView={handleSetView} tab={12} forward={1} back={107}/>
            }
            {
                view === 109 && <IndexPage/>
            }
        </div>
    );
};

export default NavigationPage;
