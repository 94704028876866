import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
// SELECTORS
import { getPageId } from '../../../../store/application/selectors/selectors';
// ACTIONS
import setPageIdBack from '../../../../store/application/actions/setPageIdBack';
import setSearchQuery from '../../../../store/application/actions/setSearchQuery';
import setPageId from '../../../../store/application/actions/setPageId';

type Data = {
    title1: string,
    title2: string,
    labels1?: Array<{
        title: ReactElement<any, any> | string,
        theme: string,
        shape: string,
        tooltip?: ReactElement<any, any> | string,
        search?: string
    }>,
    labels2?: Array<{
        title: ReactElement<any, any> | string,
        theme: string,
        shape: string,
        tooltip?: ReactElement<any, any> | string,
        search?: string
    }>,
}

const TopContent = ({ title1, title2, labels1, labels2 }: Data) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pageId = useSelector(getPageId);

    const handleSearch = (query: string) => {
        dispatch(setPageIdBack(pageId));
        dispatch(setSearchQuery(query));
        history.push('/page/109');
        dispatch(setPageId(109));
    };

    const handleClickLabel = (label: { title: React.ReactElement<any, any> | string; search?: string }) => () => {
        if (label.search) {
            handleSearch(label.search);
        }
    };

    const labelClassNames = (label: { theme: string, shape: string }) => classNames(styles.label, {
        [styles[label.theme]]: label.theme,
        [styles[label.shape]]: label.shape
    });

    return <div className={styles.root}>
        <div className={styles.block}>
            <div className={styles.title}>{title1}</div>
            <div className={styles.labels}>
                {
                    labels1 && labels1.map((label, i) => (
                        <div className={labelClassNames(label)} key={i} onClick={handleClickLabel(label)}>
                            {label.title}
                            {
                                label.tooltip &&
                                <Tooltip
                                    theme={label.theme}
                                    width='410px'
                                    content={label.tooltip}
                                />
                            }
                        </div>
                    ))
                }
            </div>
        </div>
        <div className={styles.block}>
            <div className={styles.title}>{title2}</div>
            <div className={styles.labels}>
                {
                    labels2 && labels2.map((label, i) => (
                        <div className={labelClassNames(label)} key={i} onClick={handleClickLabel(label)}>
                            {label.title}
                            {
                                label.tooltip &&
                                <Tooltip
                                    theme={label.theme}
                                    width='404px'
                                    content={label.tooltip}
                                />
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    </div>;
};

export default React.memo(TopContent);
