import React from 'react';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commentPageIcons } from '../../assets/icons/svg';
// COMPONENTS
import Note from '../../common/components/Note';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number
};

const CommentPage = ({ handleSetView, forward, back }: Props) => {
    const handleChangePage = (direction:'right'|'left') => {
        if (direction === 'right') {
            handleSetView(forward)();
        } else {
            handleSetView(back)();
        }
    };

    const setView = (index: number) => () => {
        handleSetView(index)();
    };

    const wrapper1ClassNames = classNames(styles.buttonWrapper, styles.buttonWrapper1);
    const wrapper2ClassNames = classNames(styles.buttonWrapper, styles.buttonWrapper2);
    const wrapper3ClassNames = classNames(styles.buttonWrapper, styles.buttonWrapper3);
    const wrapper4ClassNames = classNames(styles.buttonWrapper, styles.buttonWrapper4);

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleChangePage}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    <div className={styles.innerContainer}>
                        <div className={styles.title}>
                            <FormattedMessage id='commentPage.title' />
                        </div>
                        <div className={styles.subTitle}>
                            <FormattedMessage id='commentPage.subTitle' />
                        </div>
                        <div className={styles.buttonsBlock}>
                            <div className={styles.buttonContainer}>
                                <div className={styles.buttonsBlockFirst}>
                                    <div className={styles.button}>
                                        <FormattedMessage id='commentPage.button1' />
                                    </div>
                                    <div className={styles.arrowIcon}>
                                        {commentPageIcons.arrow1}
                                    </div>
                                </div>
                                <div className={styles.buttonsBlockSmall}>
                                    <div className={styles.button} onClick={setView(9)}>
                                        <FormattedMessage id='commentPage.button2' />
                                    </div>
                                    <div className={wrapper1ClassNames}>
                                        <div className={styles.button}>
                                            <FormattedMessage id='commentPage.button3' />
                                        </div>
                                        <div className={styles.arrowIcon}>
                                            {commentPageIcons.arrow2}
                                        </div>
                                        <div className={styles.buttonsBlockSmall}>
                                            <div className={wrapper2ClassNames}>
                                                <div className={styles.button} onClick={setView(15)}>
                                                    <FormattedMessage id='commentPage.button3_1' />
                                                </div>
                                                <div className={styles.arrowIcon}>
                                                    {commentPageIcons.arrow3}
                                                </div>
                                                <div className={styles.buttonsBlockSmall}>
                                                    <div className={styles.button} onClick={setView(20)}>
                                                        <FormattedMessage id='commentPage.button3_1_1' />
                                                    </div>
                                                    <div className={styles.button} onClick={setView(26)}>
                                                        <FormattedMessage id='commentPage.button3_1_2' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={wrapper3ClassNames}>
                                                <div className={styles.button} onClick={setView(27)}>
                                                    <FormattedMessage id='commentPage.button3_2' />
                                                </div>
                                                <div className={styles.arrowIcon}>
                                                    {commentPageIcons.arrow4}
                                                </div>
                                                <div className={styles.buttonsBlockSmall}>
                                                    <div className={styles.button} onClick={setView(28)}>
                                                        <FormattedMessage id='commentPage.button3_2_1' />
                                                    </div>
                                                    <div className={styles.button} onClick={setView(33)}>
                                                        <FormattedMessage id='commentPage.button3_2_2' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={wrapper4ClassNames}>
                                        <div className={styles.button} onClick={setView(43)}>
                                            <FormattedMessage id='commentPage.button4' />
                                        </div>
                                        <div className={styles.arrowIcon}>
                                            {commentPageIcons.arrow5}
                                        </div>
                                        <div className={styles.buttonsBlockSmall}>
                                            <div className={styles.button} onClick={setView(44)}>
                                                <FormattedMessage id='commentPage.button4_1' />
                                            </div>
                                            <div className={styles.button} onClick={setView(45)}>
                                                <FormattedMessage id='commentPage.button4_2' />
                                            </div>
                                            <div className={styles.button} onClick={setView(46)}>
                                                <FormattedMessage id='commentPage.button4_3' />
                                            </div>
                                            <div className={styles.button} onClick={setView(47)}>
                                                <FormattedMessage id='commentPage.button4_6' />
                                            </div>
                                            <div className={styles.button} onClick={setView(63)}>
                                                <FormattedMessage id='commentPage.button4_4' />
                                            </div>
                                            <div className={styles.button} onClick={setView(64)}>
                                                <FormattedMessage id='commentPage.button4_5' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Note
                            content={<div><p><FormattedMessage id='note.noteText58' /></p></div>}
                            theme='blue'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentPage;
