import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons, navigationIcons } from '../../../assets/icons/svg';
// UTILS
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// ACTIONS
import setNavigationPopup from '../../../store/application/actions/setNavigationPopup';
import setPageId from '../../../store/application/actions/setPageId';
// SELECTORS
import { getPageId } from '../../../store/application/selectors/selectors';
import { TABLET_WIDTH } from '../../constants/constants';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import useWindowSize from '../../hooks/useWindowSize';

const NavigationPopup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const popupRef = useRef<any>(null);
    const pageId = useSelector(getPageId);

    useEffect(() => {
        disableBodyScroll(popupRef.current);

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const handleClosePopup = () => {
        dispatch(setNavigationPopup(false));
    };

    const handleChangeView = (pageId: number) => () => {
        dispatch(setPageId(pageId));
        history.push(`/page/${pageId}`);
        dispatch(setNavigationPopup(false));
    };

    const blueDotClassNames = (view: number, nextPoint: number) => classNames(styles.dot, styles.blueDot, {
        [styles.active]: pageId >= view && pageId < nextPoint
    });
    const orangeDotClassNames = (view: number, nextPoint: number) => classNames(styles.dot, styles.orangeDot, {
        [styles.active]: pageId >= view && pageId < nextPoint
    });
    const greenDotClassNames = (view: number, nextPoint: number) => classNames(styles.dot, styles.greenDot, {
        [styles.active]: pageId >= view && pageId < nextPoint
    });
    const purpleDotClassNames = (view: number, nextPoint: number) => classNames(styles.dot, styles.purpleDot, {
        [styles.active]: pageId >= view && pageId < nextPoint
    });
    const yellowDotClassNames = (view: number, nextPoint: number) => classNames(styles.dot, styles.yellowDot, {
        [styles.active]: pageId >= view && pageId < nextPoint
    });

    const screenWidth = useWindowSize().width;

    return (
        <div className={styles.root}>
            <div className={styles.background} onClick={handleClosePopup}/>
            <div className={styles.container} ref={popupRef}>
                <div className={styles.header}>
                    <FormattedMessage id='navigationPopup.title' />
                    <div className={styles.closeIcon} onClick={handleClosePopup}>
                        {commonIcons.close}
                    </div>
                </div>
                <div className={styles.body}>
                    <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                        <TransformComponent wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}>
                            <div className={styles.mobileWrapper}>
                                <div className={styles.home} onClick={handleChangeView(1)}>
                                    <div className={styles.homeIcon}>{navigationIcons.home}</div>
                                    <div className={styles.homeTitle}><FormattedMessage id='navigationPopup.home' /></div>
                                </div>
                                <div className={styles.connections}>
                                    <div className={styles.map}>{navigationIcons.map}</div>
                                    <div className={styles.blueLine}>
                                        <div className={blueDotClassNames(2, 3)} onClick={handleChangeView(2)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.blueLink1' />
                                            </div>
                                        </div>
                                        <div className={blueDotClassNames(3, 4)} onClick={handleChangeView(3)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.blueLink2' />
                                            </div>
                                        </div>
                                        <div className={blueDotClassNames(4, 8)} onClick={handleChangeView(4)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.blueLink3' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.yellowTopLine}>
                                        <div className={yellowDotClassNames(9, 15)} onClick={handleChangeView(9)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink2' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(15, 20)} onClick={handleChangeView(15)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink3' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(20, 26)} onClick={handleChangeView(20)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink4' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(26, 27)} onClick={handleChangeView(26)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink5' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.yellowMiddleLine}>
                                        <div className={yellowDotClassNames(8, 9)} onClick={handleChangeView(8)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink1' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(15, 15)} onClick={handleChangeView(15)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink6' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(27, 28)} onClick={handleChangeView(27)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink7' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(28, 33)} onClick={handleChangeView(28)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink8' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(33, 43)} onClick={handleChangeView(33)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink9' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.yellowBottomLine}>
                                        <div className={yellowDotClassNames(43, 44)} onClick={handleChangeView(43)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink10' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(44, 45)} onClick={handleChangeView(44)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink11' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(45, 46)} onClick={handleChangeView(45)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink12' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(46, 47)} onClick={handleChangeView(46)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink13' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(47, 63)} onClick={handleChangeView(47)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink14' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(63, 64)} onClick={handleChangeView(63)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink15' />
                                            </div>
                                        </div>
                                        <div className={yellowDotClassNames(64, 71)} onClick={handleChangeView(64)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.yellowLink16' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.orangeLine}>
                                        <div className={orangeDotClassNames(71, 72)} onClick={handleChangeView(71)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.orangeLink1' />
                                            </div>
                                        </div>
                                        <div className={orangeDotClassNames(72, 73)} onClick={handleChangeView(72)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.orangeLink2' />
                                            </div>
                                        </div>
                                        <div className={orangeDotClassNames(73, 82)} onClick={handleChangeView(73)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.orangeLink3' />
                                            </div>
                                        </div>
                                        <div className={orangeDotClassNames(82, 84)} onClick={handleChangeView(82)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.orangeLink4' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.greenLine}>
                                        <div className={greenDotClassNames(84, 85)} onClick={handleChangeView(84)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.greenLink1' />
                                            </div>
                                        </div>
                                        <div className={greenDotClassNames(85, 86)} onClick={handleChangeView(85)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.greenLink2' />
                                            </div>
                                        </div>
                                        <div className={greenDotClassNames(86, 87)} onClick={handleChangeView(86)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.greenLink3' />
                                            </div>
                                        </div>
                                        <div className={greenDotClassNames(87, 95)} onClick={handleChangeView(87)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.greenLink4' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.purpleLine}>
                                        <div className={purpleDotClassNames(95, 96)} onClick={handleChangeView(95)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.purpleLink1' />
                                            </div>
                                        </div>
                                        <div className={purpleDotClassNames(96, 97)} onClick={handleChangeView(96)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.purpleLink2' />
                                            </div>
                                        </div>
                                        <div className={purpleDotClassNames(97, 107)} onClick={handleChangeView(97)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.purpleLink3' />
                                            </div>
                                        </div>
                                        <div className={purpleDotClassNames(107, 108)} onClick={handleChangeView(107)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.purpleLink4' />
                                            </div>
                                        </div>
                                        <div className={purpleDotClassNames(108, 109)} onClick={handleChangeView(108)}>
                                            <div className={styles.point}/>
                                            <div className={styles.link}>
                                                <FormattedMessage id='navigationPopup.purpleLink5' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TransformComponent>
                    </TransformWrapper>
                </div>
            </div>
        </div>
    );
};

export default NavigationPopup;
