import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { commonIcons } from '../../../assets/icons/svg';
// UTILS
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// ACTIONS
import setTermPopup from '../../../store/application/actions/setTermPopup';
// SELECTORS
import { getTermPopup } from '../../../store/application/selectors/selectors';
// import { getRSNByIDExtended } from '../../../store/terms/actions/actions';
// CONSTANTS
import { TERMS_LIST_PAGES } from '../../../pages/IndexPage/data/vulgapp_terms';

const TermPopup = () => {
    const [term, setTerm] = useState<null | {
        content: {
            title: string,
            acronym: string,
            description: any,
            example: any,
            note: string
        },
        relations: {
            linked_rs: {
                title: string,
                acronym: string,
                description: any,
                example: any
            },
            linked_rs_notes: {
                list: any[]
            }
        }
    } | any>(null);
    const dispatch = useDispatch();
    const popupRef = useRef<any>(null);
    const termPopup = useSelector(getTermPopup);

    useEffect(() => {
        disableBodyScroll(popupRef.current);

        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const handleClosePopup = () => {
        dispatch(setTermPopup(null));
    };

    useEffect(() => {
        if (!termPopup) return;

        setTerm(TERMS_LIST_PAGES.find((item: { pageId: number }) => item.pageId === termPopup)?.term || null);
    }, [termPopup]);

    return (
        term && <div className={styles.root}>
            <div className={styles.background} onClick={handleClosePopup}/>
            <div className={styles.container} ref={popupRef}>
                <div className={styles.header}>
                    <div className={styles.headerTexts}>
                        <div className={styles.heading}>
                            <div className={styles.title}>
                                <span className={styles.flag}>
                                    {commonIcons.flag}
                                </span>
                                <span>{ term?.content?.title }
                                    { term?.content?.acronym && <span className={styles.acronym}>({ term?.content?.acronym })</span> }
                                </span>
                            </div>
                            <div className={styles.subTitle}>
                                <FormattedMessage id='termPopup.subTitle' />
                            </div>
                        </div>
                        <div
                            className={styles.headingLabel}
                            style={{ color: term?.content?.label?.color, backgroundColor: term?.content?.label?.backgroundColor }}
                        >
                            {term?.content?.label?.text}
                        </div>
                    </div>
                    <div className={styles.closeIcon} onClick={handleClosePopup}>
                        {commonIcons.close}
                    </div>
                </div>
                <div className={styles.body}>
                    {
                        term?.content?.description
                            ? <div className={styles.definition}>
                                <div className={styles.title}>
                                    <FormattedMessage id='termPopup.definition' />
                                </div>
                                <div
                                    className={styles.content}
                                    dangerouslySetInnerHTML={{ __html: term?.content?.description.replace(/\\/g, '') }}
                                />
                            </div>
                            : <div className={styles.noDefinition}>
                                <div className={styles.title}>
                                    <FormattedMessage id='termPopup.definition' />
                                </div>
                                <div className={styles.content}>
                                    <FormattedMessage id='termPopup.noDefinition' />
                                </div>
                            </div>
                    }
                    {
                        term?.content?.example
                            ? <div className={styles.definition}>
                                <div className={styles.title}>
                                    <FormattedMessage id='termPopup.example' />
                                </div>
                                <div
                                    className={styles.content}
                                    dangerouslySetInnerHTML={{ __html: term?.content?.example.replace(/\\/g, '') }}
                                />
                            </div>
                            : <div className={styles.noDefinition}>
                                <div className={styles.title}>
                                    <FormattedMessage id='termPopup.example' />
                                </div>
                                <div className={styles.content}>
                                    <FormattedMessage id='termPopup.noExample' />
                                </div>
                            </div>
                    }
                    {
                        term?.relations?.linked_rs &&
                        <div className={styles.blockLinkedUsage}>
                            <div className={styles.blockHeader}>
                                <FormattedMessage id='termPopup.linkedUsage' />
                            </div>
                            <div className={styles.blockContent}>
                                <div className={styles.blockTitle}>
                                    <div className={styles.underlined}>
                                        {
                                            term?.relations?.linked_rs?.flag &&
                                            <span className={styles.flagSmall}>
                                                {commonIcons.flag}
                                            </span>
                                        }
                                        <span>
                                            {term?.relations?.linked_rs?.title}
                                            {
                                                term?.relations?.linked_rs?.acronym &&
                                                <span className={styles.acronym}>({term?.relations?.linked_rs?.acronym})</span>
                                            }
                                        </span>
                                    </div>
                                    <div
                                        className={styles.blockLabel}
                                        style={{
                                            color: term?.relations?.linked_rs?.label?.color,
                                            backgroundColor: term?.relations?.linked_rs?.label?.backgroundColor
                                        }}
                                    >
                                        {term?.relations?.linked_rs?.label?.text}
                                    </div>
                                </div>
                                {
                                    term?.relations?.linked_rs?.description
                                        ? <div className={styles.definition}>
                                            <div className={styles.title}>
                                                <FormattedMessage id='termPopup.definition' />
                                            </div>
                                            <div
                                                className={styles.content}
                                                dangerouslySetInnerHTML={{ __html: term?.relations?.linked_rs?.description.replace(/\\/g, '') }}
                                            />
                                        </div>
                                        : <div className={styles.noDefinition}>
                                            <div className={styles.title}>
                                                <FormattedMessage id='termPopup.definition' />
                                            </div>
                                            <div className={styles.content}>
                                                <FormattedMessage id='termPopup.noDefinition' />
                                            </div>
                                        </div>
                                }
                                {
                                    term?.relations?.linked_rs?.example
                                        ? <div className={styles.definition}>
                                            <div className={styles.title}>
                                                <FormattedMessage id='termPopup.example' />
                                            </div>
                                            <div
                                                className={styles.content}
                                                dangerouslySetInnerHTML={{ __html: term?.relations?.linked_rs?.example.replace(/\\/g, '') }}
                                            />
                                        </div>
                                        : <div className={styles.noDefinition}>
                                            <div className={styles.title}>
                                                <FormattedMessage id='termPopup.example' />
                                            </div>
                                            <div className={styles.content}>
                                                <FormattedMessage id='termPopup.noExample' />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        term?.relations?.linked_rs_notes.list.map((item: any, i: number) => (
                            <div className={styles.blockLinkedNote} key={i}>
                                <div className={styles.blockHeader}>
                                    <FormattedMessage id='termPopup.linkedNormativeTerm' />
                                </div>
                                <div className={styles.blockContent}>
                                    <div className={styles.blockTitle}>
                                        <div className={styles.underlined}>
                                            {
                                                item.flag &&
                                                <span className={styles.flagSmall}>
                                                    {commonIcons.flag}
                                                </span>
                                            }
                                            <span>
                                                {item.title}
                                                {
                                                    item.acronym && <span className={styles.acronym}>({item.acronym})</span>
                                                }
                                            </span>
                                        </div>
                                        {
                                            item.label &&
                                            <div
                                                className={styles.blockLabel}
                                                style={{
                                                    color: item.label?.color,
                                                    backgroundColor: item.label?.backgroundColor
                                                }}
                                            >
                                                {item.label?.text}
                                            </div>
                                        }
                                    </div>
                                    {
                                        item.description
                                            ? <div className={styles.definition}>
                                                <div className={styles.title}>
                                                    <FormattedMessage id='termPopup.definition' />
                                                </div>
                                                <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.description.replace(/\\/g, '') }}/>
                                            </div>
                                            : <div className={styles.noDefinition}>
                                                <div className={styles.title}>
                                                    <FormattedMessage id='termPopup.definition' />
                                                </div>
                                                <div className={styles.content}>
                                                    <FormattedMessage id='termPopup.noDefinition' />
                                                </div>
                                            </div>
                                    }
                                    {
                                        item.example
                                            ? <div className={styles.definition}>
                                                <div className={styles.title}>
                                                    <FormattedMessage id='termPopup.example' />
                                                </div>
                                                <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.example.replace(/\\/g, '') }}/>
                                            </div>
                                            : <div className={styles.noDefinition}>
                                                <div className={styles.title}>
                                                    <FormattedMessage id='termPopup.example' />
                                                </div>
                                                <div className={styles.content}>
                                                    <FormattedMessage id='termPopup.noExample' />
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                    {
                        term?.content.note &&
                        <div className={styles.noteGeneral}>
                            <div className={styles.noteGeneralTitle}>
                                <FormattedMessage id='termPopup.noteGeneral' />
                            </div>
                            {term?.content.note}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default TermPopup;
