import {
    SET_ABOUT_POPUP,
    SET_INFO_POPUP,
    SET_IMAGE_POPUP,
    SET_TERM_POPUP,
    SET_NAVIGATION_POPUP,
    SET_PAGE_ID,
    SET_PAGE_ID_BACK,
    SET_SEARCH_QUERY,
    SET_SEARCH_OPEN
} from '../types/types';
import { ActionType } from '../../ts-types';

const initialState = {
    aboutPopup: false,
    infoPopup: false,
    imagePopup: false,
    navigationPopup: false,
    termPopup: null,
    pageId: 1,
    pageIdBack: null,
    searchQuery: '',
    searchOpen: false,
    locale: 'fr'
};

export default function (state = initialState, action: ActionType) {
    switch (action.type) {
    case SET_ABOUT_POPUP:
        return { ...state, aboutPopup: action.payload };
    case SET_INFO_POPUP:
        return { ...state, infoPopup: action.payload };
    case SET_IMAGE_POPUP:
        return { ...state, imagePopup: action.payload };
    case SET_TERM_POPUP:
        return { ...state, termPopup: action.payload };
    case SET_NAVIGATION_POPUP:
        return { ...state, navigationPopup: action.payload };
    case SET_PAGE_ID:
        return { ...state, pageId: action.payload };
    case SET_PAGE_ID_BACK:
        return { ...state, pageIdBack: action.payload };
    case SET_SEARCH_QUERY:
        return { ...state, searchQuery: action.payload };
    case SET_SEARCH_OPEN:
        return { ...state, searchOpen: action.payload };
    default:
        return state;
    }
}
