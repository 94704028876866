import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// COMPONENTS
import Arrows from '../Arrows';
import Note from '../../../../common/components/Note';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';

const Schema4View3 = () => {
    const screenWidth = useWindowSize().width;
    const disabledContainerClassNames = classNames(styles.infoBlock, styles.disabled);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema4.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema4.subTitle' />
                    <div className={styles.isoLabel}>
                        PLAN BIM 2022
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.cardsWrapper}>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block1Title' />
                                            </span>
                                        </div>
                                        <Arrows color='blue' additionalClass='arrowDownAnimation'/>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block2Title' />
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block3Title' />
                                            </span>
                                        </div>
                                    </div>
                                    <div className={disabledContainerClassNames}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema4.block4Title' />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteText35' /></p><p><FormattedMessage id='note.noteText36' /></p></div>} />
            </div>
        </div>
    );
};

export default Schema4View3;
