import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// COMPONENTS
import InfoBlock1 from '../../schema7/InfoBlock1';
import InfoBlock2 from '../../schema7/InfoBlock2';
import InfoBlock3 from '../../schema7/InfoBlock3';
import BottomBlock from '../../schema6/InfoBlock2';
import Note from '../../../../common/components/Note';
import Tooltip from '../Tooltip';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// IMAGES
import figure9 from '../images/figure9.png';
import { commonIcons } from '../../../../assets/icons/svg';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema7View4 = () => {
    const intl = useIntl();

    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };
    const screenWidth = useWindowSize().width;
    const buttonBlueClassNames = classNames(styles.button, styles.buttonBlue);
    const buttonWhiteClassNames = classNames(styles.button, styles.buttonWhite);
    const buttonPurpleClassNames = classNames(styles.button, styles.buttonPurple);
    const buttonNavyClassNames = classNames(styles.button, styles.buttonNavy);
    const wrapperTripleClassNames = classNames(styles.wrapper, styles.wrapperTriple);
    const wrapperUpClassNames = classNames(styles.wrapper, styles.wrapperMoveUp);
    const infoTopClassNames = classNames(styles.infoTop);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema7.title' />
                </div>
                <div className={styles.subTitle}>
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 6.3.5, Figure 9
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoContent}>
                                    <div className={infoTopClassNames}>
                                        <div className={styles.wrapper}>
                                            <div className={buttonBlueClassNames}>
                                                <FormattedMessage id='schema7.button1' />
                                                <Tooltip
                                                    tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_1' })}
                                                    tooltipText={<div>
                                                        <FormattedMessage id='tooltip.text13_2' />
                                                    </div>}
                                                    href='partie désignante'
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.wrapper}>
                                            <div className={buttonWhiteClassNames}>
                                                <FormattedMessage id='schema7.button2' />
                                            </div>
                                            <div className={buttonWhiteClassNames}>
                                                <FormattedMessage id='schema7.button3' />
                                            </div>
                                        </div>
                                        <div className={styles.mainContent}>
                                            <div className={styles.contentWrapper}>
                                                <InfoBlock1
                                                    step='1'
                                                    arrowSliced={false}
                                                    stepText={intl.formatMessage({ id: 'schema7.step1' })}
                                                    stepStartText={intl.formatMessage({ id: 'schema7.step1ReviewEnd' })}
                                                    stepEndText={intl.formatMessage({ id: 'schema7.step2ReviewStart' })}
                                                    label={intl.formatMessage({ id: 'schema7.pim' })}
                                                    color='grey'
                                                />
                                            </div>
                                            <div className={styles.contentWrapper}>
                                                <InfoBlock1
                                                    step='2'
                                                    arrowSliced={false}
                                                    stepText={intl.formatMessage({ id: 'schema7.step2' })}
                                                    stepStartText={intl.formatMessage({ id: 'schema7.step2ReviewEnd' })}
                                                    stepEndText={intl.formatMessage({ id: 'schema7.step3ReviewStart' })}
                                                    label={intl.formatMessage({ id: 'schema7.pim' })}
                                                    color='grey'
                                                />
                                            </div>
                                            <div className={styles.contentWrapper}>
                                                <InfoBlock1
                                                    step='...'
                                                    arrowSliced={true}
                                                    stepText={intl.formatMessage({ id: 'schema7.step3' })}
                                                    stepStartText={intl.formatMessage({ id: 'schema7.stepReviewEnd' })}
                                                    stepEndText={intl.formatMessage({ id: 'schema7.stepReviewStart' })}
                                                    label={intl.formatMessage({ id: 'schema7.pim' })}
                                                    color='grey'
                                                />
                                            </div>
                                            <div className={styles.contentWrapper}>
                                                <InfoBlock2
                                                    step='...'
                                                    stepText={intl.formatMessage({ id: 'schema7.step' })}
                                                    stepLink={intl.formatMessage({ id: 'schema7.stepLink' })}
                                                    label={intl.formatMessage({ id: 'schema7.aim' })}
                                                    color='grey'
                                                />
                                            </div>
                                        </div>
                                        <div className={wrapperUpClassNames}>
                                            <div className={buttonNavyClassNames}>
                                                <FormattedMessage id='schema7.button4' />
                                            </div>
                                            <div className={buttonPurpleClassNames}>
                                                <FormattedMessage id='schema7.button5' />
                                            </div>
                                        </div>
                                        <div className={wrapperTripleClassNames}>
                                            <div className={styles.blockContainer}>
                                                <InfoBlock3
                                                    buttonText={intl.formatMessage({ id: 'schema7.buttonX' })}
                                                    mainText={intl.formatMessage({ id: 'schema7.textX1' })}
                                                    bottomText={intl.formatMessage({ id: 'schema7.textX2' })}
                                                    color='blue'
                                                />
                                            </div>
                                            <div className={styles.blockContainer}>
                                                <InfoBlock3
                                                    buttonText={intl.formatMessage({ id: 'schema7.buttonY' })}
                                                    mainText={intl.formatMessage({ id: 'schema7.textY1' })}
                                                    bottomText={intl.formatMessage({ id: 'schema7.textY2' })}
                                                    color='blue'
                                                />
                                            </div>
                                            <div className={styles.blockContainer}>
                                                <InfoBlock3
                                                    buttonText={intl.formatMessage({ id: 'schema7.buttonZ' })}
                                                    mainText={intl.formatMessage({ id: 'schema7.textZ1' })}
                                                    bottomText={intl.formatMessage({ id: 'schema7.textZ2' })}
                                                    color='purple'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.infoBottom}>
                                        <BottomBlock color='grey'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteText43' /></p><p><FormattedMessage id='note.noteText44' /></p></div>} />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema7.figure9Title' })}
                    label='NF EN ISO 19650-1:2019, 6.3.5'
                    caption={intl.formatMessage({ id: 'schema7.figure9' })}
                    image={figure9}
                    id={117}
                />
            }
        </div>
    );
};

export default Schema7View4;
