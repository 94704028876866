import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// COMPONENTS
import Tooltip from '../Tooltip';
import Note from '../../../../common/components/Note';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// ACTIONS
import setPageIdBack from '../../../../store/application/actions/setPageIdBack';
import setSearchQuery from '../../../../store/application/actions/setSearchQuery';
import setPageId from '../../../../store/application/actions/setPageId';
// SELECTORS
import { getPageId } from '../../../../store/application/selectors/selectors';

const Schema3View3 = () => {
    const intl = useIntl();
    const screenWidth = useWindowSize().width;
    const history = useHistory();
    const dispatch = useDispatch();
    const pageId = useSelector(getPageId);

    const handleSearch = (query: string) => () => {
        dispatch(setPageIdBack(pageId));
        dispatch(setSearchQuery(query));
        history.push('/page/109');
        dispatch(setPageId(109));
    };

    const yellowContainerClassNames = classNames(styles.infoBlock, styles.yellowColored);
    const blueContainerClassNames = classNames(styles.infoBlock, styles.blueColored);
    const whiteContainerClassNames = classNames(styles.mainContent, styles.whiteColored);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema3.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema3.subTitle' />
                    <div className={styles.isoLabel}>
                        PLAN BIM 2022
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.cardsWrapper}>
                                    <div className={yellowContainerClassNames}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema3.block1Title' />
                                            </span>
                                            <Tooltip text={intl.formatMessage({ id: 'tooltip.text8' })} />
                                        </div>
                                    </div>
                                    <div className={yellowContainerClassNames}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema3.block2Title' />
                                            </span>
                                            <Tooltip text={intl.formatMessage({ id: 'tooltip.text8' })} />
                                        </div>
                                    </div>
                                    <div className={blueContainerClassNames}>
                                        <div className={styles.mainContent}>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema3.block3Title' />
                                            </span>
                                            <span className={styles.infoBlockText}>
                                                <FormattedMessage id='schema3.block3Title1' />
                                            </span>
                                            <Tooltip text={intl.formatMessage({ id: 'tooltip.text8' })} />
                                        </div>
                                        <div className={whiteContainerClassNames}>
                                            <span className={styles.infoBlockText}>
                                                <ul>
                                                    <li>
                                                        <FormattedMessage id='schema3.block5Title' />
                                                        <span onClick={handleSearch('BEP')} className={styles.link}>
                                                            <FormattedMessage id='schema3.block5Title1' />
                                                        </span>
                                                        <FormattedMessage id='schema3.block5Title2' />
                                                    </li>
                                                    <li>
                                                        <FormattedMessage id='schema3.block5Title6' />
                                                        <span onClick={handleSearch('TIR')} className={styles.link}>
                                                            <FormattedMessage id='schema3.block5Title3' />
                                                        </span>
                                                    </li>
                                                    <li><FormattedMessage id='schema3.block5Title4' /></li>
                                                </ul>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteText33' /></p><p><FormattedMessage id='note.noteText34' /></p></div>} />
            </div>
        </div>
    );
};

export default Schema3View3;
