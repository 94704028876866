import React, { useState } from 'react';
// COMPONENTS
import InfoBlock2 from '../InfoBlock2';
// STYLES
import styles from './styles.module.scss';

type ExampleSelectorProps = {
    setTab: React.Dispatch<React.SetStateAction<number>>,
    selectorOption?: Array<{
        title: string,
        subTitle: string,
        tab: number
    }>,
    buttonBlue?: Element | string,
    buttonWhite?: Element | string
};

const Selector = ({ setTab, selectorOption, buttonBlue, buttonWhite }: ExampleSelectorProps) => {
    const [isSelectorOpen, setSelectorOpen] = useState<boolean>(false);
    const handleOpenSelector = () => {
        setSelectorOpen((prev) => !prev);
    };

    return (
        <div className={styles.exampleWrapper} onClick={handleOpenSelector}>
            <InfoBlock2
                buttonBlue={buttonBlue}
                buttonWhite={buttonWhite}
                dropDownOpen={isSelectorOpen}
                setTab={setTab}
                selectorOption={selectorOption}
            />
        </div>
    );
};

export default React.memo(Selector);
