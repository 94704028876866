function descendingComparator (a: any, b: any, orderBy: keyof any) {
    if (orderBy === 'col1') {
        if (b.content.title.localeCompare(a.content.title, 'fr') < 0) {
            return -1;
        }
        if (b.content.title.localeCompare(a.content.title, 'fr') > 0) {
            return 1;
        }
        return 0;
    } else if (orderBy === 'col4') {
        if (b.pageId < a.pageId) {
            return -1;
        }
        if (b.pageId > a.pageId) {
            return 1;
        }
        return 0;
    }
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any> (
    order: Order,
    orderBy: Key
): (
    // eslint-disable-next-line no-unused-vars
    a: { [key in Key]: string | number | any },
    // eslint-disable-next-line no-unused-vars
    b: { [key in Key]: string | number | any },
) => number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
