import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { pourquoiPageIcons, commonIcons } from '../../assets/icons/svg';
// COMPONENTS
import Note from '../../common/components/Note';
import ArrowNavigation from '../../components/ArrowNavigation';
import Tooltip from '../../components/schemas/schema1/Tooltip';
import { TOOLTIP_2, TOOLTIP_3, TOOLTIP_9 } from '../../components/schemas/schema1/tooltipsData';
// ACTIONS
import setTermPopup from '../../store/application/actions/setTermPopup';
import setPageIdBack from '../../store/application/actions/setPageIdBack';
import setSearchQuery from '../../store/application/actions/setSearchQuery';
import setPageId from '../../store/application/actions/setPageId';
// SELECTORS
import { getPageId } from '../../store/application/selectors/selectors';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number
};

const PourquoiPage = ({ handleSetView, forward, back }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pageId = useSelector(getPageId);

    const handleSearch = (query: string) => () => {
        dispatch(setPageIdBack(pageId));
        dispatch(setSearchQuery(query));
        history.push('/page/109');
        dispatch(setPageId(109));
    };

    const handleChangePage = (direction:'right'|'left') => {
        if (direction === 'right') {
            handleSetView(forward)();
        } else {
            handleSetView(back)();
        }
    };

    const handleStepSwitch = (index: number) => (event: any) => {
        event.preventDefault();
        handleSetView(index)();
    };

    const handleOpenTermPopup = (id: number | null) => () => {
        if (id) {
            dispatch(setTermPopup(id));
        }
    };

    const buttonComplexClassNames = classNames(styles.button, styles.buttonComplex);

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleChangePage}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    <div className={styles.innerContainer}>
                        <div className={styles.title}>
                            <FormattedMessage id='pourquoiPage.title' />
                        </div>
                        <div className={styles.subTitle}>
                            <FormattedMessage id='pourquoiPage.subTitle1' />
                            <span className={styles.bold}>
                                <FormattedMessage id='pourquoiPage.subTitle2' />
                            </span>
                            <FormattedMessage id='pourquoiPage.subTitle3' />
                            <span className={styles.bold}>
                                <FormattedMessage id='pourquoiPage.subTitle4' />
                            </span>
                            <FormattedMessage id='pourquoiPage.subTitle5' />
                            <span className={styles.bold}>
                                <FormattedMessage id='pourquoiPage.subTitle6' />
                            </span>
                            <FormattedMessage id='pourquoiPage.subTitle7' />
                        </div>
                        <div className={styles.buttonsBlock}>
                            <div className={styles.button} onClick={handleStepSwitch(3)}>
                                <FormattedMessage id='pourquoiPage.button1' />
                            </div>
                            <div className={styles.arrowIcon}>
                                {pourquoiPageIcons.arrow1}
                            </div>
                            <div className={buttonComplexClassNames}>
                                <div className={styles.top} onClick={handleStepSwitch(4)}>
                                    <FormattedMessage id='pourquoiPage.button2' />
                                </div>
                                <div className={styles.bottom} onClick={handleOpenTermPopup(131)}>
                                    <span className={styles.flagIcon}>
                                        {commonIcons.France}
                                    </span>
                                    <FormattedMessage id='pourquoiPage.button2_1' />
                                </div>
                            </div>
                        </div>
                        <Note content={<div>
                            <p><FormattedMessage id='note.noteTitle5' /></p>
                            <ul>
                                <li><div className={styles.linkHover}>
                                    <span onClick={handleSearch('AIR')}><FormattedMessage id='note.noteText12' /></span>
                                    <Tooltip
                                        texts={<ul>{TOOLTIP_3.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                        title={<p>{TOOLTIP_3.title}</p>}
                                        width="254px"
                                        moveTopLess={true}
                                    />
                                </div></li>
                                <li><div className={styles.linkHover}>
                                    <span onClick={handleSearch('PIR')}><FormattedMessage id='note.noteText13' /></span>
                                    <Tooltip
                                        texts={<ul>{TOOLTIP_2.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                        title={<p>{TOOLTIP_2.title}</p>}
                                        width="237px"
                                        moveTopLess={true}
                                    />
                                </div></li>
                                <li><div className={styles.linkHover}>
                                    <span onClick={handleSearch('EIR')}><FormattedMessage id='note.noteText14' /></span>
                                    <Tooltip
                                        texts={<ul>{TOOLTIP_9.texts.map((item, i) => (<li key={i}>{item}</li>))}</ul>}
                                        title={<p>{TOOLTIP_9.title}</p>}
                                        width="233px"
                                        moveTopLess={true}
                                    />
                                </div></li>
                            </ul>
                            <p><FormattedMessage id='note.noteText15' />
                                <span
                                    className={styles.linkHover}
                                    onClick={handleSearch('cahier des charges BIM')}>
                                    <FormattedMessage id='note.noteText16' />
                                </span>
                                <FormattedMessage id='note.noteText17_1' />
                                <span onClick={handleSetView(107)} className={styles.linkHover}>
                                    <FormattedMessage id='note.noteText17_2' />
                                </span>.
                            </p>
                            <p><FormattedMessage id='note.noteText17_3' /></p>
                        </div>} theme='blue'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PourquoiPage;
