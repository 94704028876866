import React, { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { mainPageIcons, commonIcons } from '../../assets/icons/svg';
// ACTIONS
import setInfoPopup from '../../store/application/actions/setInfoPopup';
import setNavigationPopup from '../../store/application/actions/setNavigationPopup';
import setSearchOpen from '../../store/application/actions/setSearchOpen';
// COMPONENTS
import Tooltip from './Tooltip';
// SELECTORS
import { getSearchOpen } from '../../store/application/selectors/selectors';

type Props = {
    handleSetView: (view: number) => MouseEventHandler<HTMLDivElement>
};

const MainPage = ({ handleSetView }: Props) => {
    const dispatch = useDispatch();
    const infoWrapperClassNames = classNames(styles.infoWrapper, styles.middle);
    const buttonComplexClassNames = classNames(styles.button, styles.buttonComplex);
    const buttonDashedClassNames = classNames(styles.button, styles.buttonDashed);
    const searchOpen = useSelector(getSearchOpen);

    const handleOpenPopup = () => {
        dispatch(setInfoPopup(true));
    };

    const handleNavigationOpen = () => {
        dispatch(setNavigationPopup(true));
    };

    const handleSearchOpen = () => {
        dispatch(setSearchOpen(!searchOpen));
    };

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    <div className={styles.innerContainer}>
                        <div className={styles.text}>
                            <FormattedMessage id='mainPage.text1' />
                            <br/>
                            <br/>
                            <FormattedMessage id='mainPage.text2' />
                            <span className={styles.bold}>
                                <FormattedMessage id='mainPage.text3' />
                            </span>
                            <FormattedMessage id='mainPage.text4' />
                        </div>
                        <div className={styles.link} onClick={handleOpenPopup}>
                            <div className={styles.icon}>{commonIcons.info}</div>
                            <div className={styles.linkText}>
                                <FormattedMessage id='mainPage.link' />
                            </div>
                            <div className={styles.icon}>{commonIcons.link2}</div>
                        </div>
                    </div>
                </div>
                <div className={infoWrapperClassNames}>
                    <div className={styles.innerContainer}>
                        <div className={styles.title}>
                            <FormattedMessage id='mainPage.title1' />
                        </div>
                        <div className={styles.buttonsBlock}>
                            <div className={styles.button} onClick={handleSetView(2)}>
                                <FormattedMessage id='mainPage.button1' />
                                <Tooltip content={<div>
                                    <b><FormattedMessage id='mainPage.tooltip1_1' /></b>
                                    <FormattedMessage id='mainPage.tooltip1_2' />
                                </div>}/>
                            </div>
                            <div className={styles.arrowIcon}>
                                {mainPageIcons.arrow1}
                            </div>
                            <div className={buttonComplexClassNames} onClick={handleSetView(8)}>
                                <div className={styles.top}>
                                    <FormattedMessage id='mainPage.button2' />
                                </div>
                                <div className={styles.bottom}>
                                    <span className={styles.flagIcon}>
                                        {commonIcons.France}
                                    </span>
                                    <FormattedMessage id='mainPage.button2_1' />
                                </div>
                                <Tooltip content={<div>
                                    <b><FormattedMessage id='mainPage.tooltip2_1' /></b>
                                    <FormattedMessage id='mainPage.tooltip2_2' />
                                </div>}/>
                            </div>
                            <div className={styles.arrowIcon}>
                                {mainPageIcons.arrow1}
                            </div>
                            <div className={styles.button} onClick={handleSetView(71)}>
                                <FormattedMessage id='mainPage.button3' />
                                <Tooltip content={<div>
                                    <b><FormattedMessage id='mainPage.tooltip3_1' /></b>
                                    <FormattedMessage id='mainPage.tooltip3_2' />
                                </div>}/>
                            </div>
                        </div>
                        <div className={styles.title}>
                            <FormattedMessage id='mainPage.subTitle1' />
                        </div>
                        <div className={styles.buttonsBlock}>
                            <div className={buttonDashedClassNames} onClick={handleSetView(84)}>
                                <FormattedMessage id='mainPage.button4' />
                                <Tooltip content={<div>
                                    <b><FormattedMessage id='mainPage.tooltip4_1' /></b>
                                    <FormattedMessage id='mainPage.tooltip4_2' />
                                </div>}/>
                            </div>
                            <div className={buttonDashedClassNames} onClick={handleSetView(95)}>
                                <FormattedMessage id='mainPage.button5' />
                                <Tooltip content={<div>
                                    <b><FormattedMessage id='mainPage.tooltip5_1' /></b>
                                    <FormattedMessage id='mainPage.tooltip5_2' />
                                </div>}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.infoWrapper}>
                    <div className={styles.innerContainer}>
                        <div className={styles.title}>
                            <FormattedMessage id='mainPage.title2' />
                        </div>
                        <div className={styles.subTitle}>
                            <FormattedMessage id='mainPage.subTitle2' />
                        </div>
                        <div className={styles.blocks}>
                            <div className={styles.block}>
                                <div className={styles.blockIconBig}>
                                    {commonIcons.pageArrowLeft}
                                </div>
                                <div className={styles.blockIconBig}>
                                    {commonIcons.pageArrowRight}
                                </div>
                                <div className={styles.blockText}>
                                    <FormattedMessage id='mainPage.block1' />
                                </div>
                            </div>
                            <div className={styles.block}>
                                <div className={styles.blockIcon} onClick={handleNavigationOpen}>
                                    <FormattedMessage id='mainPage.plan' />
                                </div>
                                <div className={styles.blockText}>
                                    <FormattedMessage id='mainPage.block2' />
                                </div>
                            </div>
                            <div className={styles.block}>
                                <div className={styles.blockIcon} onClick={handleSearchOpen}>
                                    {commonIcons.search2}
                                </div>
                                <div className={styles.blockText}>
                                    <FormattedMessage id='mainPage.block3' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainPage;
