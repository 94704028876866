import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// STYLES
import styles from './styles.module.scss';
import TermsContainer from '../../components/TermsContainer';
// CONSTANTS
import { TERMS_LIST, TERMS_LIST_PAGES } from './data/vulgapp_terms';
// SELECTORS
import { getSearchQuery } from '../../store/application/selectors/selectors';

const IndexPage = () => {
    const [terms, setTerms] = useState<any[]>([]);
    const [termsFiltered, setTermsFiltered] = useState<any[]>(TERMS_LIST.data);
    const searchValue = useSelector(getSearchQuery);

    useEffect(() => {
        if (searchValue) {
            setTermsFiltered(terms.filter(term => term.content.full_title.toLowerCase().includes(searchValue.toLowerCase())));
        } else {
            setTermsFiltered(terms);
        }
    }, [searchValue]);

    useEffect(() => {
        const terms = TERMS_LIST.data.map(item => ({
            ...item,
            pageId: TERMS_LIST_PAGES.find(page => item.id === page.id)?.pageId || 0
        }));
        setTerms(terms);

        if (searchValue) {
            setTermsFiltered(terms.filter(term => term.content.full_title.toLowerCase().includes(searchValue.toLowerCase())));
        } else {
            setTermsFiltered(terms);
        }
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <TermsContainer terms={termsFiltered}/>
            </div>
        </div>
    );
};

export default IndexPage;
