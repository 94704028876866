import React from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema5PageIcons } from '../../../../assets/icons/svg';

type Data = {
    title: string,
    direction: string,
    arrow?: string,
    color?: string,
    icon?: string,
    noHover?: boolean
};

const IconBlock = ({ title, direction, arrow, color, icon, noHover }: Data) => {
    const rootClassNames = classNames(styles.arrow, {
        [styles.arrowLeft]: direction === 'left',
        [styles.arrowRight]: direction === 'right',
        [styles[`${color}`]]: color,
        [styles.noHover]: noHover
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.text}>
                {title}
            </div>
            {
                arrow && <div className={styles.arrowIcon}>
                    {
                        arrow === 'down'
                            ? <div className={styles.arrowWrapper}>
                                {schema5PageIcons.arrow1}
                                <div className={styles.hover}>
                                    {schema5PageIcons.arrow1Hover}
                                </div>
                            </div>
                            : <div className={styles.arrowWrapper}>
                                {schema5PageIcons.arrow2}
                                <div className={styles.hover}>
                                    {schema5PageIcons.arrow2Hover}
                                </div>
                            </div>
                    }
                </div>
            }
            {
                icon && <div className={styles.icon}>
                    {
                        icon === 'square'
                            ? <div className={styles.arrowWrapper}>
                                {schema5PageIcons.iconSquare}
                                <div className={styles.hover}>
                                    {schema5PageIcons.iconSquareHover}
                                </div>
                            </div>
                            : <div className={styles.arrowWrapper}>
                                {schema5PageIcons.iconRound}
                                <div className={styles.hover}>
                                    {schema5PageIcons.iconRoundHover}
                                </div>
                            </div>
                    }
                </div>
            }
        </div>
    );
};

export default React.memo(IconBlock);
