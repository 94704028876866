import React from 'react';
import { useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema5View1 from '../../components/schemas/schema5/Schema5View1';
import Schema5View2 from '../../components/schemas/schema5/Schema5View2';
import Schema5View3 from '../../components/schemas/schema5/Schema5View3';
import Schema5View4 from '../../components/schemas/schema5/Schema5View4';
import Schema5View5 from '../../components/schemas/schema5/Schema5View5';
import Schema5View6 from '../../components/schemas/schema5/Schema5View6';
import Schema5View7 from '../../components/schemas/schema5/Schema5View7';
import Schema5View8 from '../../components/schemas/schema5/Schema5View8';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema5Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema5.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema5.introSubTitle' })}
                            textBlock1={<div>
                                <p>
                                    {intl.formatMessage({ id: 'schema5.intro1TextBlock1' })}
                                    <b>{intl.formatMessage({ id: 'schema5.intro1TextBlock2' })}</b>
                                    {intl.formatMessage({ id: 'schema5.intro1TextBlock3' })}
                                    <br/>
                                    {intl.formatMessage({ id: 'schema5.intro1TextBlock4' })}
                                </p>
                                <p>
                                    {intl.formatMessage({ id: 'schema5.intro1TextBlock5' })}
                                    <br/>
                                    {intl.formatMessage({ id: 'schema5.intro1TextBlock6' })}
                                </p>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema5.intro1TextBlock7' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema5.intro1TextBlock8' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema5.intro1TextBlock9' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema5.intro1TextBlock10' })}</li>
                                </ul>
                            </div>}
                            textBlock2={<div>
                                <p>{intl.formatMessage({ id: 'schema5.intro1TextBlock11' })}</p>
                                <p>
                                    {intl.formatMessage({ id: 'schema5.intro1TextBlock12' })}
                                    <br/>
                                    {intl.formatMessage({ id: 'schema5.intro1TextBlock13' })}
                                </p>
                            </div>}
                        />
                    }
                    {
                        tab === 1 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema5.introTitle2' })}
                            subTitle={intl.formatMessage({ id: 'schema5.introSubTitle2' })}
                            section1Heading={intl.formatMessage({ id: 'schema5.intro2Section1Heading' })}
                            textBlock1={intl.formatMessage({ id: 'schema5.intro2TextBlock1' })}
                            textBlock2={intl.formatMessage({ id: 'schema5.intro2TextBlock2' })}
                            redNoteTitle={intl.formatMessage({ id: 'schema5.intro2RedNoteTitle' })}
                            redNoteText={intl.formatMessage({ id: 'schema5.intro2RedNoteText' })}
                            blueNoteTitle={intl.formatMessage({ id: 'schema5.intro2BlueNoteTitle' })}
                            blueNoteText={intl.formatMessage({ id: 'schema5.intro2BlueNoteText' })}
                            footer={intl.formatMessage({ id: 'schema5.intro2Footer' })}
                            links={['Link', 'Link', 'Link']}
                        />
                    }
                    {
                        tab === 2 && <Schema5View1/>
                    }
                    {
                        tab === 3 && <Schema5View2/>
                    }
                    {
                        tab === 4 && <Schema5View3/>
                    }
                    {
                        tab === 5 && <Schema5View4/>
                    }
                    {
                        tab === 6 && <Schema5View5/>
                    }
                    {
                        tab === 7 && <Schema5View6/>
                    }
                    {
                        tab === 8 && <Schema5View7/>
                    }
                    {
                        tab === 9 && <Schema5View8/>
                    }
                    {
                        tab === 10 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema5.introTitle3' })}
                            subTitle={intl.formatMessage({ id: 'schema5.introSubTitle3' })}
                            section1Heading={intl.formatMessage({ id: 'schema5.intro3Section1Heading' })}
                            bottomTitleCenter={true}
                            bottomTitleBlue={true}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema5Page;
