import React from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import arrowBlue from '../../../../pages/Schema4Page/images/forwardBlue.svg';
import arrowRed from '../../../../pages/Schema4Page/images/forwardRed.svg';

const ARROWS = [0, 1, 2, 3];

type Data = {
    color: string,
    additionalClass: string,
    direction?: string,
    small?: boolean
};

const Arrows = ({ color, additionalClass, direction, small }: Data) => {
    const rootClassNames = classNames(styles.root, {
        [styles[additionalClass]]: additionalClass,
        [styles.right]: direction === 'right',
        [styles.left]: direction === 'left',
        [styles.small]: small
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.arrowsInnerContainer}>
                {
                    ARROWS.map((item, i) => (
                        <div className={styles.arrow} key={i}>
                            <img alt='forward' className={styles.arrowImage} src={color === 'blue' ? arrowBlue : arrowRed}/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default React.memo(Arrows);
