import React, { Fragment, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { matchPath } from 'react-router';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import Header from './components/Header/index';
import Footer from './components/Footer';
import AboutPopup from './common/components/AboutPopup';
import InfoPopup from './common/components/InfoPopup';
import TermPopup from './common/components/TermPopup';
import NavigationPopup from './common/components/NavigationPopup';
import Routes from './routing/Routes';
// TRANSLATIONS
import translations from './translations';
// SELECTORS
import { getAboutPopup, getInfoPopup, getTermPopup, getNavigationPopup, getLocale } from './store/application/selectors/selectors';

export const App = () => {
    const locale = useSelector(getLocale);
    const isAboutPopupOpen = useSelector(getAboutPopup);
    const isInfoPopupOpen = useSelector(getInfoPopup);
    const isTermPopupOpen = useSelector(getTermPopup);
    const isNavigationPopupOpen = useSelector(getNavigationPopup);
    const messages = useMemo(() => translations[locale], [locale]);
    const history = useHistory();

    useEffect(() => {
        const match = matchPath(history.location.pathname, {
            path: '/:page/:pageId',
            exact: true,
            strict: false
        });

        if (!match) {
            history.push('/page/1');
        }
    }, []);

    return (
        <IntlProvider locale={locale} messages={messages} textComponent={Fragment}>
            <div className={styles.page}>
                <Header/>
                <div className={styles.pageContent}>
                    <Routes/>
                </div>
                <Footer/>
                {
                    isAboutPopupOpen && <AboutPopup/>
                }
                {
                    isInfoPopupOpen && <InfoPopup/>
                }
                {
                    isNavigationPopupOpen && <NavigationPopup/>
                }
                {
                    isTermPopupOpen && <TermPopup/>
                }
            </div>
        </IntlProvider>
    );
};
