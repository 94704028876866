import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { commonIcons, schema5PageIcons } from '../../../../assets/icons/svg';
import figure6 from '../images/figure6.png';
// COMPONENTS
import IconBlock from '../IconBlock';
import ButtonTooltip from '../ButtonTooltip';
import SideInfoBlock from '../SideInfoBlock';
import Note from '../../../../common/components/Note';
import ImagePopup from '../../../../common/components/ImagePopup';
// COMPONENTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema5View3 = () => {
    const intl = useIntl();

    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };
    const screenWidth = useWindowSize().width;
    const greenLegendClassNames = classNames(styles.legend, styles.greenColored);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema5.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema5.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 6.3.2 , Figure 6
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoLeft}>
                                    <SideInfoBlock title={intl.formatMessage({ id: 'schema5.infoText1' })} direction="left"/>
                                </div>
                                <div className={styles.infoContentWrapper}>
                                    <div className={styles.infoContent}>
                                        <div className={styles.button}>
                                            <ButtonTooltip
                                                title={intl.formatMessage({ id: 'schema5.button1' })}
                                                tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_1' })}
                                                tooltipText={<div>
                                                    <FormattedMessage id='tooltip.text13_2' />
                                                </div>}
                                                href='partie désignante'
                                            />
                                        </div>
                                        <div className={styles.arrowsGroup}>
                                            <div className={styles.arrow}>
                                                <IconBlock title={intl.formatMessage({ id: 'schema5.text1' })} direction="left" arrow="down"/>
                                            </div>
                                            <div className={styles.arrow}>
                                                <IconBlock title={intl.formatMessage({ id: 'schema5.text2' })} direction="right" arrow="up"/>
                                            </div>
                                        </div>
                                        <div className={styles.singleIcon}>
                                            <div className={styles.icon}>
                                                <IconBlock
                                                    title={intl.formatMessage({ id: 'schema5.text4' })}
                                                    direction="right"
                                                    icon="square"
                                                    color="redBlueColor"
                                                    noHover={true}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.arrowsGroup}>
                                            <div className={styles.arrow}>
                                                <IconBlock title={intl.formatMessage({ id: 'schema5.text3' })} direction="left" arrow="down"/>
                                            </div>
                                            <div className={styles.arrow}>
                                                <IconBlock title={intl.formatMessage({ id: 'schema5.text2' })} direction="right" arrow="up"/>
                                            </div>
                                        </div>
                                        <div className={styles.singleIcon}>
                                            <div className={styles.icon}>
                                                <IconBlock
                                                    title={intl.formatMessage({ id: 'schema5.text5' })}
                                                    direction="right"
                                                    icon="round"
                                                    color="greenColor"
                                                    noHover={true}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.arrowsGroup}>
                                            <div className={styles.arrow}>
                                                <IconBlock title={intl.formatMessage({ id: 'schema5.text3' })} direction="left" arrow="down"/>
                                            </div>
                                            <div className={styles.arrow}>
                                                <IconBlock title={intl.formatMessage({ id: 'schema5.text2' })} direction="right" arrow="up"/>
                                            </div>
                                        </div>
                                        <div className={styles.button}>
                                            <ButtonTooltip
                                                title={intl.formatMessage({ id: 'schema5.button2' })}
                                                tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_5' })}
                                                tooltipText={<div>
                                                    <FormattedMessage id='tooltip.text13_7' />
                                                </div>}
                                                href='partie désignée'
                                            />
                                        </div>
                                        <div className={styles.arrowsGroup}>
                                            <div className={styles.arrow}>
                                                <IconBlock title={intl.formatMessage({ id: 'schema5.text3' })} direction="left" arrow="down"/>
                                            </div>
                                            <div className={styles.arrow}>
                                                <IconBlock title={intl.formatMessage({ id: 'schema5.text2' })} direction="right" arrow="up"/>
                                            </div>
                                        </div>
                                        <div className={styles.button}>
                                            <ButtonTooltip
                                                title={intl.formatMessage({ id: 'schema5.button3' })}
                                                tooltipTitle={intl.formatMessage({ id: 'tooltip.text13_6' })}
                                                tooltipText={<div>
                                                    <FormattedMessage id='tooltip.text13_8' />
                                                </div>}
                                                href='partie désignée'
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.legendsBlock}>
                                        <div className={styles.legend}>
                                            <div className={styles.legendIcon}>{schema5PageIcons.iconSquare}</div>
                                            <FormattedMessage id='schema5.legend1' />
                                        </div>
                                        <div className={greenLegendClassNames}>
                                            <div className={styles.legendIcon}>{schema5PageIcons.iconRound}</div>
                                            <FormattedMessage id='schema5.legend2' />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.infoRight}>
                                    <SideInfoBlock title={intl.formatMessage({ id: 'schema5.infoText2' })} direction="right"/>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteText40' /></p></div>} />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema5.figure6Title' })}
                    label='NF EN ISO 19650-1:2019, 6.3.2 '
                    caption={intl.formatMessage({ id: 'schema5.figure6' })}
                    image={figure6}
                    id={115}
                />
            }
        </div>
    );
};

export default Schema5View3;
