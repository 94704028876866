import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import arrowHorizontal from '../../../../pages/Schema2Page/images/arrowHorizontal.svg';
import arrowVertical from '../../../../pages/Schema2Page/images/arrowVertical.svg';
import { commonIcons, schema2PageIcons } from '../../../../assets/icons/svg';
import figure10 from '../images/figure10.png';
// COMPONENTS
import Note from '../../../../common/components/Note';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema2View3 = () => {
    const intl = useIntl();

    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };
    const screenWidth = useWindowSize().width;
    const yellowContainerClassNames = classNames(styles.infoBlock, styles.yellowColored);
    const blueContainerClassNames = classNames(styles.infoBlock, styles.blueColored);
    const greenContainerClassNames = classNames(styles.infoBlock, styles.greenColored);
    const redContainerClassNames = classNames(styles.infoBlock, styles.redColored);
    const detailedContainerClassNames = classNames(styles.mainContent, styles.detailed);
    const detailedBottomContainerClassNames = classNames(styles.mainContent, styles.detailed, styles.bottom);

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema2.title4' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema2.subTitle4' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 12.1, Figure 10
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.cardsWrapper}>
                                    <div className={yellowContainerClassNames}>
                                        <div className={detailedContainerClassNames}>
                                            <FormattedMessage id='schema2.block1Title' />
                                            <div className={styles.info}>
                                                <FormattedMessage id='schema2.cardInfo3' />
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema2PageIcons.arrow2}
                                        </div>
                                        <div className={styles.hint}>
                                            <div className={styles.hintTitle}>
                                                <FormattedMessage id='schema2.step2Header' />
                                            </div>
                                            <FormattedMessage id='schema2.step2Text' />
                                        </div>
                                        <div className={styles.sideArrowHint}>
                                            <div className={styles.sideHintText}>
                                                <FormattedMessage id='schema2.sideHint1' />
                                            </div>
                                            <div className={styles.sideHintBg}>
                                                <img alt='arrow' src={arrowVertical} className={styles.sideHintBgImage}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={blueContainerClassNames}>
                                        <div className={detailedContainerClassNames}>
                                            <FormattedMessage id='schema2.block2Title' />
                                            <div className={styles.info}>
                                                <FormattedMessage id='schema2.cardInfo1' />
                                                <div className={styles.bottomText}>
                                                    <FormattedMessage id='schema2.cardInfo2' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={detailedBottomContainerClassNames}>
                                            <FormattedMessage id='schema2.block2Title' />
                                            <div className={styles.info}>
                                                <FormattedMessage id='schema2.cardInfo1' />
                                                <div className={styles.bottomText}>
                                                    <FormattedMessage id='schema2.cardInfo2' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={detailedBottomContainerClassNames}>
                                            <FormattedMessage id='schema2.block2Title' />
                                            <div className={styles.info}>
                                                <FormattedMessage id='schema2.cardInfo1' />
                                                <div className={styles.bottomText}>
                                                    <FormattedMessage id='schema2.cardInfo2' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema2PageIcons.arrow1}
                                        </div>
                                        <div className={styles.hint}>
                                            <div className={styles.hintTitle}>
                                                <FormattedMessage id='schema2.step1Header' />
                                            </div>
                                            <FormattedMessage id='schema2.step1Text' />
                                        </div>
                                    </div>
                                    <div className={greenContainerClassNames}>
                                        <div className={detailedContainerClassNames}>
                                            <FormattedMessage id='schema2.block3Title' />
                                            <div className={styles.info}>
                                                <FormattedMessage id='schema2.cardInfo4' />
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>
                                            {schema2PageIcons.arrow3}
                                        </div>
                                        <div className={styles.hint}>
                                            <div className={styles.hintTitle}>
                                                <FormattedMessage id='schema2.step3Header' />
                                            </div>
                                            <FormattedMessage id='schema2.step3Text' />
                                        </div>
                                        <div className={styles.sideArrowHint}>
                                            <div className={styles.sideHintText}>
                                                <FormattedMessage id='schema2.sideHint1' />
                                            </div>
                                            <div className={styles.sideHintBg}>
                                                <img alt='arrow' src={arrowHorizontal} className={styles.sideHintBgImage}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={redContainerClassNames}>
                                        <div className={styles.mainContent}>
                                            <FormattedMessage id='schema2.block4Title' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div>
                    <p><b><FormattedMessage id='note.noteText29' /></b></p>
                    <ul>
                        <li><FormattedMessage id='note.noteText29_1' /></li>
                        <li><FormattedMessage id='note.noteText29_2' /></li>
                        <li><FormattedMessage id='note.noteText29_3' /></li>
                    </ul>
                    <br/>
                    <p><b><FormattedMessage id='note.noteText30' /></b></p>
                    <ol>
                        <li><FormattedMessage id='note.noteText30_1' /></li>
                        <li><FormattedMessage id='note.noteText30_2' /></li>
                    </ol>
                    <br/>
                    <p><b><FormattedMessage id='note.noteText31' /></b><FormattedMessage id='note.noteText31_1' /></p>
                    <p><b><FormattedMessage id='note.noteText32' /></b><FormattedMessage id='note.noteText32_1' /></p>
                </div>} />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema2.figure10Title' })}
                    label='NF EN ISO 19650-1:2019, 12.1, Figure 10'
                    caption={intl.formatMessage({ id: 'schema2.figure10' })}
                    image={figure10}
                    id={114}
                />
            }
        </div>
    );
};

export default Schema2View3;
