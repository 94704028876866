import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema1View1 from '../../components/schemas/schema1/Schema1View1';
import Schema1View2 from '../../components/schemas/schema1/Schema1View2';
import Schema1View3 from '../../components/schemas/schema1/Schema1View3';
import Schema1View4 from '../../components/schemas/schema1/Schema1View4';
import Schema1View5 from '../../components/schemas/schema1/Schema1View5';
import ArrowNavigation from '../../components/ArrowNavigation';
// IMAGES
import example1 from '../../components/schemas/schema1/images/example1.png';
import example2 from '../../components/schemas/schema1/images/example2.png';
// SELECTORS
import { getPageId } from '../../store/application/selectors/selectors';
// ACTIONS
import setPageIdBack from '../../store/application/actions/setPageIdBack';
import setSearchQuery from '../../store/application/actions/setSearchQuery';
import setPageId from '../../store/application/actions/setPageId';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema1Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const pageId = useSelector(getPageId);

    const handleSearch = (query: string) => () => {
        dispatch(setPageIdBack(pageId));
        dispatch(setSearchQuery(query));
        history.push('/page/109');
        dispatch(setPageId(109));
    };

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange} />
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {tab === 0 && (
                        <SchemaIntro
                            title={intl.formatMessage({ id: 'schema1.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema1.introSubTitle' })}
                            section1Heading={intl.formatMessage({ id: 'schema1.intro1Section1Heading' })}
                            textBlock1={<div>
                                <p>{intl.formatMessage({ id: 'schema1.intro1TextBlock1_1' })}</p>
                                <p>{intl.formatMessage({ id: 'schema1.intro1TextBlock1_2' })}</p>
                            </div>}
                            textBlock2={<ul>
                                <li>{intl.formatMessage({ id: 'schema1.intro1TextBlock2_1' })}</li>
                                <li>{intl.formatMessage({ id: 'schema1.intro1TextBlock2_2' })}</li>
                            </ul>}
                            redNoteTitle={intl.formatMessage({ id: 'schema1.intro1RedNoteTitle' })}
                            redNoteText={intl.formatMessage({ id: 'schema1.intro1RedNoteText' })}
                            blueNoteTitle={intl.formatMessage({ id: 'schema1.intro1BlueNoteTitle' })}
                            blueNoteText={<div>
                                {intl.formatMessage({ id: 'schema1.intro1BlueNoteText1' })}
                                <br/>
                                {intl.formatMessage({ id: 'schema1.intro1BlueNoteText2' })}
                                <br/>
                                {intl.formatMessage({ id: 'schema1.intro1BlueNoteText3' })}
                                <br/>
                                {intl.formatMessage({ id: 'schema1.intro1BlueNoteText4' })}
                                <br/>
                                {intl.formatMessage({ id: 'schema1.intro1BlueNoteText5' })}
                            </div>}
                            bottomTitleBlue={true}
                        />
                    )}
                    {tab === 1 && (
                        <SchemaIntro
                            title={intl.formatMessage({ id: 'schema1.introTitle2' })}
                            section1Heading={intl.formatMessage({ id: 'schema1.intro2Section1Heading' })}
                            textBlock1={<div>
                                <p>
                                    {intl.formatMessage({ id: 'schema1.intro2TextBlock1_1' })}
                                    <b>{intl.formatMessage({ id: 'schema1.intro2TextBlock1_2' })}</b>
                                    {intl.formatMessage({ id: 'schema1.intro2TextBlock1_3' })}
                                </p>
                                <p>
                                    {intl.formatMessage({ id: 'schema1.intro2TextBlock1_4' })}
                                    <b>{intl.formatMessage({ id: 'schema1.intro2TextBlock1_5' })}</b>
                                    {intl.formatMessage({ id: 'schema1.intro2TextBlock1_6' })}
                                </p>
                            </div>}
                            textBlock2={<div>
                                <p>
                                    {intl.formatMessage({ id: 'schema1.intro2TextBlock2_1' })}
                                    <b>{intl.formatMessage({ id: 'schema1.intro2TextBlock2_2' })}</b>
                                    {intl.formatMessage({ id: 'schema1.intro2TextBlock2_3' })}
                                </p>
                                <p>
                                    {intl.formatMessage({ id: 'schema1.intro2TextBlock2_4' })}
                                    <b>{intl.formatMessage({ id: 'schema1.intro2TextBlock2_5' })}</b>
                                    {intl.formatMessage({ id: 'schema1.intro2TextBlock2_6' })}
                                </p>
                            </div>}
                            bottomTitleBlue={true}
                        />
                    )}
                    {tab === 2 && <Schema1View1 />}
                    {tab === 3 && <Schema1View2 />}
                    {tab === 4 && <Schema1View3 />}
                    {tab === 5 && <Schema1View4 />}
                    {tab === 6 && <Schema1View5 />}
                    {tab === 7 && (
                        <SchemaIntro
                            title={intl.formatMessage({ id: 'schema1.introTitle3' })}
                            textBlockFull={<div>
                                {intl.formatMessage({ id: 'schema1.intro3TextBlockFull1' })}
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema1.intro3TextBlockFull2' })}</li>
                                    <li>{intl.formatMessage({ id: 'schema1.intro3TextBlockFull3' })}</li>
                                </ul>
                            </div>}
                            examples={[
                                { src: example1, title: intl.formatMessage({ id: 'schema1.intro3Example1Title' }) },
                                { src: example2, title: intl.formatMessage({ id: 'schema1.intro3Example2Title' }) }
                            ]}
                            links={['Link', 'Link', 'Link']}
                            footer={intl.formatMessage({ id: 'schema1.intro3Footer' })}
                        />
                    )}
                    {tab === 8 && (
                        <SchemaIntro
                            title={intl.formatMessage({ id: 'schema1.introTitle4' })}
                            subTitle={intl.formatMessage({ id: 'schema1.introSubTitle4' })}
                            textBlockFull={<div>
                                <p>
                                    {intl.formatMessage({ id: 'schema1.intro4TextBlock1' })}
                                    <span className={styles.link} onClick={handleSearch('PIM')}>{intl.formatMessage({ id: 'schema1.intro4TextBlock2' })}</span>
                                    {intl.formatMessage({ id: 'schema1.intro4TextBlock3' })}
                                    <span className={styles.link} onClick={handleSearch('AIM')}>{intl.formatMessage({ id: 'schema1.intro4TextBlock4' })}</span>
                                    {intl.formatMessage({ id: 'schema1.intro4TextBlock5' })}
                                </p>
                                <p>{intl.formatMessage({ id: 'schema1.intro4TextBlock6' })}</p>
                                <p>{intl.formatMessage({ id: 'schema1.intro4TextBlock7' })}</p>
                            </div>}
                            mainTextBig={true}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Schema1Page;
