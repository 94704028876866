import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';
// IMAGES
import { commonIcons, schema12PageIcons } from '../../../../assets/icons/svg';
import figure3 from './../images/figure3.png';
// COMPONENTS
import Note from '../../../../common/components/Note';
import ImagePopup from '../../../../common/components/ImagePopup';
import TopContent from '../TopContent';
import Tooltip from '../Tooltip';
import Item from '../Item';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';

const LABELS_1 = [
    {
        title: <FormattedMessage id='schema12.labelText1' />,
        theme: 'navyTheme',
        shape: 'round',
        tooltip: <div>
            <FormattedMessage id='tooltip.text85_1' />
            <ul>
                <li>
                    <b><FormattedMessage id='tooltip.text85_2' /></b>
                    <FormattedMessage id='tooltip.text85_3' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text85_4' /></b>
                    <FormattedMessage id='tooltip.text85_5' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text85_6' /></b>
                    <FormattedMessage id='tooltip.text85_7' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text85_8' /></b>
                    <FormattedMessage id='tooltip.text85_9' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text85_10' /></b>
                    <FormattedMessage id='tooltip.text85_11' />
                </li>
            </ul>
        </div>
    }
];

const LABELS_2 = [
    {
        title: <FormattedMessage id='schema12.labelText2' />,
        theme: 'greenTheme',
        shape: 'round',
        tooltip: <div>
            <FormattedMessage id='tooltip.text86_1' />
            <ul>
                <li>
                    <b><FormattedMessage id='tooltip.text86_2' /></b>
                    <FormattedMessage id='tooltip.text86_3' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text86_4' /></b>
                    <FormattedMessage id='tooltip.text86_5' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text86_6' /></b>
                    <FormattedMessage id='tooltip.text86_7' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text86_8' /></b>
                    <FormattedMessage id='tooltip.text86_9' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text86_10' /></b>
                    <FormattedMessage id='tooltip.text86_11' />
                </li>
            </ul>
        </div>
    },
    {
        title: <FormattedMessage id='schema12.labelText3' />,
        theme: 'purpleTheme',
        shape: 'round',
        tooltip: <div>
            <FormattedMessage id='tooltip.text87_1' />
            <ul>
                <li>
                    <b><FormattedMessage id='tooltip.text87_2' /></b>
                    <FormattedMessage id='tooltip.text87_3' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text87_4' /></b>
                    <FormattedMessage id='tooltip.text87_5' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text87_6' /></b>
                    <FormattedMessage id='tooltip.text87_7' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text87_8' /></b>
                    <FormattedMessage id='tooltip.text87_9' />
                </li>
                <li>
                    <b><FormattedMessage id='tooltip.text87_10' /></b>
                    <FormattedMessage id='tooltip.text87_11' />
                </li>
            </ul>
        </div>
    }
];

type Data = {
    handleStepClick: React.Dispatch<React.SetStateAction<number>>
}

const Schema12View1 = ({ handleStepClick }: Data) => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };

    const screenWidth = useWindowSize().width;
    const cornerLabelClassNames = classNames(styles.label, styles.cornerLabel);
    const centerLabelClassNames = classNames(styles.label, styles.centerLabel);

    const handleSetStep = (step: number) => () => {
        handleStepClick(step);
    };

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema12.title1' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema12.subTitle1' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN ISO 19650-1:2019, 5.1, Figure 4
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.topContent}>
                                    <TopContent
                                        title1={intl.formatMessage({ id: 'schema12.topContentTitle1' })}
                                        title2={intl.formatMessage({ id: 'schema12.topContentTitle2' })}
                                        labels1={LABELS_1}
                                        labels2={LABELS_2}
                                    />
                                </div>
                                <div className={styles.mainContent}>
                                    <div className={styles.iconStart}>
                                        <div className={styles.icon} onClick={handleSetStep(98)}>
                                            <div className={styles.hintText}>
                                                <FormattedMessage id='schema12.textStart' />
                                            </div>
                                        </div>
                                        <div className={styles.arrow}>{schema12PageIcons.arrowLong}</div>
                                    </div>
                                    <div className={styles.outerContainer}>
                                        <div className={cornerLabelClassNames}>
                                            B
                                            <Tooltip content={intl.formatMessage({ id: 'tooltip.text23' })} position='corner' width='163px'/>
                                        </div>
                                        <Item
                                            content='1'
                                            theme='basic'
                                            plus={true}
                                            marginsBig={true}
                                            arrow='long'
                                            index={1}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text14' })}
                                            tooltipWidth='87px'
                                            moveBottom={true}
                                            step={87}
                                            handleStepClick={handleStepClick}
                                        />
                                        <div className={styles.middleContainer}>
                                            <div className={cornerLabelClassNames}>
                                                C
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text24' })} position='corner' width='129px'/>
                                            </div>
                                            <div className={centerLabelClassNames}>
                                                A
                                                <Tooltip content={intl.formatMessage({ id: 'tooltip.text22' })} direction='reversed' width='180px'/>
                                            </div>
                                            <div className={styles.connection}>{schema12PageIcons.connection1}</div>
                                            <div className={styles.innerContainer}>
                                                <div className={cornerLabelClassNames}>
                                                    D
                                                    <Tooltip content={intl.formatMessage({ id: 'tooltip.text25' })} position='corner' width='163px'/>
                                                </div>
                                                <Item
                                                    content='2'
                                                    theme='basic'
                                                    plus={true}
                                                    arrow='short'
                                                    index={1}
                                                    tooltipContent={intl.formatMessage({ id: 'tooltip.text15' })}
                                                    tooltipWidth='100px'
                                                    step={88}
                                                    handleStepClick={handleStepClick}
                                                />
                                                <Item
                                                    content='3'
                                                    theme='basic'
                                                    plus={true}
                                                    arrow='long'
                                                    index={1}
                                                    tooltipContent={intl.formatMessage({ id: 'tooltip.text16' })}
                                                    tooltipWidth='87px'
                                                    step={89}
                                                    handleStepClick={handleStepClick}
                                                />
                                            </div>
                                            <div className={styles.innerContainer}>
                                                <div className={cornerLabelClassNames}>
                                                    F
                                                    <Tooltip content={intl.formatMessage({ id: 'tooltip.text26' })} position='corner' width='189px'/>
                                                </div>
                                                <Item
                                                    content='4'
                                                    theme='basic'
                                                    plus={true}
                                                    arrow='short'
                                                    index={1}
                                                    tooltipContent={intl.formatMessage({ id: 'tooltip.text17' })}
                                                    tooltipWidth='87px'
                                                    step={90}
                                                    handleStepClick={handleStepClick}
                                                />
                                                <Item
                                                    content='5'
                                                    theme='basic'
                                                    plus={true}
                                                    arrow='long'
                                                    index={1}
                                                    tooltipContent={intl.formatMessage({ id: 'tooltip.text18' })}
                                                    tooltipWidth='87px'
                                                    step={91}
                                                    handleStepClick={handleStepClick}
                                                />
                                            </div>
                                            <div className={styles.innerContainer}>
                                                <div className={cornerLabelClassNames}>
                                                    E
                                                    <Tooltip content={intl.formatMessage({ id: 'tooltip.text27' })} position='corner' width='163px'/>
                                                </div>
                                                <Item
                                                    content='6'
                                                    theme='basic'
                                                    plus={true}
                                                    arrow='short'
                                                    index={1}
                                                    tooltipContent={intl.formatMessage({ id: 'tooltip.text19' })}
                                                    tooltipWidth='106px'
                                                    step={92}
                                                    handleStepClick={handleStepClick}
                                                />
                                                <Item
                                                    content='7'
                                                    theme='basic'
                                                    plus={true}
                                                    arrow='long'
                                                    index={1}
                                                    tooltipContent={intl.formatMessage({ id: 'tooltip.text20' })}
                                                    tooltipWidth='141px'
                                                    step={93}
                                                    handleStepClick={handleStepClick}
                                                />
                                            </div>
                                        </div>
                                        <Item
                                            content='8'
                                            theme='basic'
                                            plus={true}
                                            marginsBig={true}
                                            tooltipContent={intl.formatMessage({ id: 'tooltip.text21' })}
                                            tooltipWidth='123px'
                                            moveBottom={true}
                                            step={94}
                                            handleStepClick={handleStepClick}
                                        />
                                    </div>
                                    <div className={styles.iconEnd}>
                                        <div className={styles.arrow}>{schema12PageIcons.arrowLong}</div>
                                        <div className={styles.icon} onClick={handleSetStep(94)}>
                                            <div className={styles.hintText}>
                                                <FormattedMessage id='schema12.textEnd' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div>
                    <p><b>1</b><FormattedMessage id='note.noteText47' /></p>
                    <p><b>2</b><FormattedMessage id='note.noteText48' /></p>
                    <p><b>3</b><FormattedMessage id='note.noteText49' /></p>
                    <p><b>4</b><FormattedMessage id='note.noteText50' /></p>
                    <p><b>5</b><FormattedMessage id='note.noteText51' /></p>
                    <p><b>6</b><FormattedMessage id='note.noteText52' /></p>
                    <p><b>7</b><FormattedMessage id='note.noteText53' /></p>
                    <p><b>8</b><FormattedMessage id='note.noteText54' /></p>
                    <br/>
                    <p><FormattedMessage id='note.noteText46' /></p>
                </div>}
                />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema12.figure3Title' })}
                    label='NF EN ISO 19650-1:2019, 4'
                    caption={intl.formatMessage({ id: 'schema12.figure3' })}
                    image={figure3}
                    id={120}
                />
            }
        </div>
    );
};

export default Schema12View1;
