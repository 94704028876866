import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import Note from '../../../../common/components/Note';
import ImagePopup from '../../../../common/components/ImagePopup';
// CONSTANTS
import { TABLET_WIDTH } from '../../../../common/constants/constants';
// HOOKS
import useWindowSize from '../../../../common/hooks/useWindowSize';
// IMAGES
import figure8 from '../../schema8/images/figure8.png';
import { commonIcons } from '../../../../assets/icons/svg';
// ACTIONS
import setImagePopup from '../../../../store/application/actions/setImagePopup';
// SELECTORS
import { getImagePopup } from '../../../../store/application/selectors/selectors';

const Schema8View1 = () => {
    const intl = useIntl();
    const isImagePopupOpen = useSelector(getImagePopup);
    const dispatch = useDispatch();

    const handleOpenPopup = () => {
        dispatch(setImagePopup(true));
    };
    const screenWidth = useWindowSize().width;

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id='schema8.title' />
                </div>
                <div className={styles.subTitle}>
                    <FormattedMessage id='schema8.subTitle' />
                    <div className={styles.isoLabel} onClick={handleOpenPopup}>
                        NF EN 17412-1 (2020), 6.5, Figure 8
                        <div className={styles.eyeIcon}>
                            {commonIcons.eye}
                        </div>
                    </div>
                </div>
                <TransformWrapper disabled={screenWidth > TABLET_WIDTH}>
                    <TransformComponent
                        wrapperStyle={{ overflow: `${screenWidth > TABLET_WIDTH ? 'unset' : 'hidden'}` }}
                        wrapperClass={styles.scrollWrapperTablet}
                    >
                        <div className={styles.mobileWrapper}>
                            <div className={styles.infoWrapper}>
                                <div className={styles.cardsWrapper}>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <FormattedMessage id='schema8.block1Title' />
                                        </div>
                                    </div>
                                    <div className={styles.infoBlock}>
                                        <div className={styles.mainContent}>
                                            <FormattedMessage id='schema8.block2Title' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
                <Note content={<div><p><FormattedMessage id='note.noteText45' /></p></div>} />
            </div>
            {
                isImagePopupOpen &&
                <ImagePopup
                    title={intl.formatMessage({ id: 'schema8.figure8Title' })}
                    label='NF EN 17412-1 (2020), 6.5'
                    caption={intl.formatMessage({ id: 'schema8.figure8' })}
                    image={figure8}
                    id={118}
                />
            }
        </div>
    );
};

export default Schema8View1;
