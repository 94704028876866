import React, { ReactElement } from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';

type Data = {
    content: Element | ReactElement<any, any> | string,
    title: Element | ReactElement<any, any> | string,
    noMargin?: boolean
}

const NoteType2 = ({ content, title, noMargin }: Data) => {
    const rootClassNames = classNames(styles.note, {
        [styles.noMargin]: noMargin
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.info}>
                {title}
            </div>
            <div className={styles.text}>
                {content}
            </div>
        </div>
    );
};

export default NoteType2;
