import React from 'react';
import { Route, Switch } from 'react-router-dom';
// COMPONENTS
import NavigationPage from '../pages/NavigationPage';

export default () => {
    return (
        <Switch>
            <Route exact path='/:page/:pageId' component={NavigationPage}/>
        </Switch>
    );
};
