export const getLocale = (state: { application: { locale: string }}) => state.application.locale;

export const getAboutPopup = (state: { application: { aboutPopup: boolean }}) => state.application.aboutPopup;

export const getInfoPopup = (state: { application: { infoPopup: boolean }}) => state.application.infoPopup;

export const getImagePopup = (state: { application: { imagePopup: boolean }}) => state.application.imagePopup;

export const getTermPopup = (state: { application: { termPopup: number | null }}) => state.application.termPopup;

export const getNavigationPopup = (state: { application: { navigationPopup: boolean }}) => state.application.navigationPopup;

export const getPageId = (state: { application: { pageId: number }}) => state.application.pageId;

export const getPageIdBack = (state: { application: { pageIdBack: number | null }}) => state.application.pageIdBack;

export const getSearchQuery = (state: { application: { searchQuery: string }}) => state.application.searchQuery;

export const getSearchOpen = (state: { application: { searchOpen: boolean }}) => state.application.searchOpen;
