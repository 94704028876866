import React from 'react';
import { useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema4View1 from '../../components/schemas/schema4/Schema4View1';
import Schema4View2 from '../../components/schemas/schema4/Schema4View2';
import Schema4View3 from '../../components/schemas/schema4/Schema4View3';
import Schema4View4 from '../../components/schemas/schema4/Schema4View4';
import Schema4View5 from '../../components/schemas/schema4/Schema4View5';
import Schema4View6 from '../../components/schemas/schema4/Schema4View6';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema4Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema4.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema4.introSubTitle' })}
                            section1Heading={intl.formatMessage({ id: 'schema4.intro1Section1Heading' })}
                            bottomTitleBlue={true}
                            bottomTitleCenter={true}
                        />
                    }
                    {
                        tab === 1 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema4.introTitle2' })}
                            subTitle={intl.formatMessage({ id: 'schema4.introSubTitle2' })}
                            section1Heading={intl.formatMessage({ id: 'schema4.intro2Section1Heading' })}
                            textBlock1={<div>
                                <b>{intl.formatMessage({ id: 'schema4.intro2TextBlock1_1' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro2TextBlock1_2' })}</li>
                                </ul>
                                <b>{intl.formatMessage({ id: 'schema4.intro2TextBlock1_3' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro2TextBlock1_4' })}</li>
                                </ul>
                                <b>{intl.formatMessage({ id: 'schema4.intro2TextBlock1_5' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro2TextBlock1_6' })}</li>
                                </ul>
                            </div>}
                            textBlock2={<div>
                                <p><b>{intl.formatMessage({ id: 'schema4.intro2TextBlock2_1' })}</b></p>
                                <p><b>{intl.formatMessage({ id: 'schema4.intro2TextBlock2_2' })}</b></p>
                            </div>}
                            redNoteTitle={intl.formatMessage({ id: 'schema4.intro2RedNoteTitle' })}
                            redNoteText={intl.formatMessage({ id: 'schema4.intro2RedNoteText' })}
                            redNoteTextThin={true}
                            detailsMore={true}
                            detailsTextBlock1={<div>
                                <b>{intl.formatMessage({ id: 'schema4.intro3DetailsTextBlock1_1' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro3DetailsTextBlock1_2' })}</li>
                                </ul>
                            </div>}
                            detailsTextBlock2={<ul>
                                <b>{intl.formatMessage({ id: 'schema4.intro3DetailsTextBlock2_1' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro3DetailsTextBlock2_2' })}</li>
                                </ul>
                            </ul>}
                        />
                    }
                    {
                        tab === 2 && <Schema4View1/>
                    }
                    {
                        tab === 3 && <Schema4View2/>
                    }
                    {
                        tab === 4 && <Schema4View3/>
                    }
                    {
                        tab === 5 && <Schema4View4/>
                    }
                    {
                        tab === 6 && <Schema4View5/>
                    }
                    {
                        tab === 7 && <Schema4View6/>
                    }
                    {
                        tab === 8 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema4.introTitle3' })}
                            subTitle={intl.formatMessage({ id: 'schema4.introSubTitle3' })}
                            section1Heading={intl.formatMessage({ id: 'schema4.intro3Section1Heading' })}
                            textBlock1={<div>
                                <b>{intl.formatMessage({ id: 'schema4.intro3TextBlock1_1' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro3TextBlock1_2' })}</li>
                                </ul>
                                <b>{intl.formatMessage({ id: 'schema4.intro3TextBlock1_3' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro3TextBlock1_4' })}</li>
                                </ul>
                                <b>{intl.formatMessage({ id: 'schema4.intro3TextBlock1_5' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro3TextBlock1_6' })}</li>
                                </ul>
                            </div>}
                            textBlock2={<div>
                                <p><b>{intl.formatMessage({ id: 'schema4.intro2TextBlock2_1' })}</b></p>
                                <p><b>{intl.formatMessage({ id: 'schema4.intro2TextBlock2_2' })}</b></p>
                            </div>}
                            redNoteTitle={intl.formatMessage({ id: 'schema4.intro3RedNoteTitle' })}
                            redNoteText={intl.formatMessage({ id: 'schema4.intro3RedNoteText' })}
                            redNoteTextThin={true}
                            detailsTextBlock1={<div>
                                <b>{intl.formatMessage({ id: 'schema4.intro3DetailsTextBlock1_1' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro3DetailsTextBlock1_2' })}</li>
                                </ul>
                            </div>}
                            detailsTextBlock2={<ul>
                                <b>{intl.formatMessage({ id: 'schema4.intro3DetailsTextBlock2_1' })}</b>
                                <ul>
                                    <li>{intl.formatMessage({ id: 'schema4.intro3DetailsTextBlock2_2' })}</li>
                                </ul>
                            </ul>}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema4Page;
