const selectorOption = [
    {
        title: 'Exemple 1',
        subTitle: 'Test Description',
        tab: 100
    },
    {
        title: 'Exemple 2A',
        subTitle: 'Test Description',
        tab: 101
    },
    {
        title: 'Exemple 3A',
        subTitle: 'Test Description',
        tab: 103
    },
    {
        title: 'Exemple 3B',
        subTitle: 'Test Description',
        tab: 104
    },
    {
        title: 'Exemple 4A',
        subTitle: 'Test Description',
        tab: 105
    },
    {
        title: 'Exemple 4B',
        subTitle: 'Test Description',
        tab: 106
    }
];

export default selectorOption;
