import React from 'react';
// STYLES
import styles from './styles.module.scss';
import classNames from 'classnames';
// IMAGES
import { schema5PageIcons } from '../../../../assets/icons/svg';

type Data = {
    title: string,
    direction: string,
    color?: string
};

const SideInfoBlock = ({ title, direction, color }: Data) => {
    const rootClassNames = classNames(styles.block, {
        [styles.blockLeft]: direction === 'left',
        [styles.blockRight]: direction === 'right',
        [styles[`${color}`]]: color
    });

    return (
        <div className={rootClassNames}>
            <div className={styles.infoText}>
                {title}
            </div>
            <div className={styles.arrows}>
                {
                    direction === 'left'
                        ? schema5PageIcons.arrowsBottom
                        : schema5PageIcons.arrowsTop
                }
            </div>
        </div>
    );
};

export default React.memo(SideInfoBlock);
