import React from 'react';
import { FormattedMessage } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// IMAGES
import { enFrancePageIcons } from '../../assets/icons/svg';
// COMPONENTS
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number
};

const EnFrancePage = ({ handleSetView, forward, back }: Props) => {
    const handleChangePage = (direction:'right'|'left') => {
        if (direction === 'right') {
            handleSetView(forward)();
        } else {
            handleSetView(back)();
        }
    };

    const handleStepSwitch = (index: number) => (event: any) => {
        event.preventDefault();
        handleSetView(index)();
    };

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <ArrowNavigation onChange={handleChangePage}/>
                <div className={styles.infoWrapper}>
                    <div className={styles.innerContainer}>
                        <div className={styles.title}>
                            <FormattedMessage id='enFrancePage.title' />
                        </div>
                        <div className={styles.buttonsBlock}>
                            <div className={styles.button} onClick={handleStepSwitch(96)}>
                                <FormattedMessage id='enFrancePage.button1' />
                            </div>
                            <div className={styles.arrowIcon}>
                                {enFrancePageIcons.arrow1}
                            </div>
                            <div className={styles.button} onClick={handleStepSwitch(97)}>
                                <FormattedMessage id='enFrancePage.button2' />
                            </div>
                            <div className={styles.arrowIcon}>
                                {enFrancePageIcons.arrow1}
                            </div>
                            <div className={styles.button} onClick={handleStepSwitch(107)}>
                                <FormattedMessage id='enFrancePage.button3' />
                            </div>
                            <div className={styles.arrowIcon}>
                                {enFrancePageIcons.arrow1}
                            </div>
                            <div className={styles.button} onClick={handleStepSwitch(108)}>
                                <FormattedMessage id='enFrancePage.button4' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnFrancePage;
