import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TOOLTIP_1 = {
    title: <FormattedMessage id='tooltip.title1' />,
    texts: [
        <FormattedMessage id='tooltip.text1_1' />,
        <FormattedMessage id='tooltip.text1_2' />,
        <FormattedMessage id='tooltip.text1_3' />
    ],
    width: '225px'
};

export const TOOLTIP_2 = {
    title: <FormattedMessage id='tooltip.title1' />,
    texts: [
        <FormattedMessage id='tooltip.text2_1' />,
        <FormattedMessage id='tooltip.text2_2' />,
        <FormattedMessage id='tooltip.text2_3' />
    ],
    width: '232px'
};

export const TOOLTIP_3 = {
    title: <FormattedMessage id='tooltip.title1' />,
    texts: [
        <FormattedMessage id='tooltip.text3_1' />,
        <FormattedMessage id='tooltip.text3_2' />,
        <FormattedMessage id='tooltip.text3_3' />,
        <FormattedMessage id='tooltip.text3_4' />,
        <FormattedMessage id='tooltip.text3_5' />
    ],
    width: '275px'
};

export const TOOLTIP_4 = {
    title: <FormattedMessage id='tooltip.title2' />,
    texts: [
        <FormattedMessage id='tooltip.text4_1' />,
        <FormattedMessage id='tooltip.text4_2' />,
        <FormattedMessage id='tooltip.text4_3' />
    ],
    width: '228px'
};

export const TOOLTIP_5 = {
    title: <FormattedMessage id='tooltip.title1' />,
    texts: [
        <FormattedMessage id='tooltip.text5_1' />,
        <FormattedMessage id='tooltip.text5_2' />,
        <FormattedMessage id='tooltip.text5_3' />
    ],
    texts2: <FormattedMessage id='tooltip.text5_4' />,
    width: '212px'
};

export const TOOLTIP_6 = {
    title: <FormattedMessage id='tooltip.title2' />,
    texts: [
        <FormattedMessage id='tooltip.text6_1' />,
        <FormattedMessage id='tooltip.text6_2' />,
        <FormattedMessage id='tooltip.text6_3' />,
        <FormattedMessage id='tooltip.text6_4' />,
        <FormattedMessage id='tooltip.text6_5' />
    ],
    width: '280px'
};

export const TOOLTIP_7 = {
    title: <FormattedMessage id='tooltip.title1' />,
    texts: [
        <FormattedMessage id='tooltip.text7_2' />
    ],
    width: '131px'
};

export const TOOLTIP_8 = {
    title: <FormattedMessage id='tooltip.title1' />,
    texts2: <FormattedMessage id='tooltip.text7_1' />,
    width: '179px'
};

export const TOOLTIP_9 = {
    title: <FormattedMessage id='tooltip.title2' />,
    texts: [
        <FormattedMessage id='tooltip.text4_2' />,
        <FormattedMessage id='tooltip.text4_3' />
    ],
    width: '233px'
};
