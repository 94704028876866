import React from 'react';
import { useIntl } from 'react-intl';
// STYLES
import styles from './styles.module.scss';
// COMPONENTS
import SchemaIntro from '../../components/schemas/SchemaIntro';
import Schema3View1 from '../../components/schemas/schema3/Schema3View1';
import Schema3View2 from '../../components/schemas/schema3/Schema3View2';
import Schema3View3 from '../../components/schemas/schema3/Schema3View3';
import Schema3View4 from '../../components/schemas/schema3/Schema3View4';
import ArrowNavigation from '../../components/ArrowNavigation';

type Props = {
    handleSetView: (view: number) => () => void,
    forward: number,
    back: number,
    tab: number
};

const Schema3Page = ({ handleSetView, forward, back, tab }: Props) => {
    const intl = useIntl();

    const handleMoveForward = () => {
        handleSetView(forward)();
    };

    const handleMoveBackward = () => {
        handleSetView(back)();
    };

    const handleTabChange = (direction: 'right' | 'left') => {
        if (direction === 'right') {
            handleMoveForward();
        } else {
            handleMoveBackward();
        }
    };

    return (
        <div className={styles.root}>
            <ArrowNavigation onChange={handleTabChange}/>
            <div className={styles.container}>
                <div className={styles.infoWrapper}>
                    {
                        tab === 0 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema3.introTitle' })}
                            subTitle={intl.formatMessage({ id: 'schema3.introSubTitle' })}
                            section1Heading={intl.formatMessage({ id: 'schema3.intro1Section1Heading' })}
                            textBlockFull={<div>
                                <p>
                                    <b>{intl.formatMessage({ id: 'schema3.intro1TextBlock1' })}</b>{intl.formatMessage({ id: 'schema3.intro1TextBlock2' })}
                                    <br/>
                                    {intl.formatMessage({ id: 'schema3.intro1TextBlock3' })}
                                </p>
                                <p><b>{intl.formatMessage({ id: 'schema3.intro1TextBlock4' })}</b>{intl.formatMessage({ id: 'schema3.intro1TextBlock5' })}</p>
                                <p>
                                    <ol>
                                        <li>{intl.formatMessage({ id: 'schema3.intro1TextBlock6' })}</li>
                                        <li>{intl.formatMessage({ id: 'schema3.intro1TextBlock7' })}</li>
                                        <li>{intl.formatMessage({ id: 'schema3.intro1TextBlock8' })}</li>
                                    </ol>
                                </p>
                            </div>}
                            bottomTitleBlue={true}
                        />
                    }
                    {
                        tab === 1 && <SchemaIntro
                            title={intl.formatMessage({ id: 'schema3.introTitle2' })}
                            subTitle={intl.formatMessage({ id: 'schema3.introSubTitle2' })}
                            section1Heading={intl.formatMessage({ id: 'schema3.intro2Section1Heading' })}
                            textBlock1={intl.formatMessage({ id: 'schema3.intro2TextBlock1' })}
                            textBlock2={intl.formatMessage({ id: 'schema3.intro2TextBlock2' })}
                            redNoteTitle={intl.formatMessage({ id: 'schema3.intro2RedNoteTitle' })}
                            redNoteText={intl.formatMessage({ id: 'schema3.intro2RedNoteText' })}
                            blueNoteTitle={intl.formatMessage({ id: 'schema3.intro2BlueNoteTitle' })}
                            blueNoteText={intl.formatMessage({ id: 'schema3.intro2BlueNoteText' })}
                            footer={intl.formatMessage({ id: 'schema3.intro2Footer' })}
                            links={['Link', 'Link', 'Link']}
                        />
                    }
                    {
                        tab === 2 && <Schema3View1/>
                    }
                    {
                        tab === 3 && <Schema3View2/>
                    }
                    {
                        tab === 4 && <Schema3View3/>
                    }
                    {
                        tab === 5 && <Schema3View4/>
                    }
                </div>
            </div>
        </div>
    );
};

export default Schema3Page;
